import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const devFirebaseConfig = {
  apiKey: "AIzaSyDd2FkA-lyDtbEBEHVQ6k9cqUaQZUJtxeI",
  authDomain: "boop-customer-dev.firebaseapp.com",
  projectId: "boop-customer-dev",
  storageBucket: "boop-customer-dev.appspot.com",
  messagingSenderId: "602189353872",
  appId: "1:602189353872:web:7a1c79cf1c5dd439871915",
};

const prodFirebaseConfig = {
  apiKey: "AIzaSyCoFRbGqNL0M6S93ozcGKkedz5hWOZWRps",
  authDomain: "boop-customer-portal.firebaseapp.com",
  projectId: "boop-customer-portal",
  storageBucket: "boop-customer-portal.appspot.com",
  messagingSenderId: "610512011875",
  appId: "1:610512011875:web:710e8b6008dc6b96442e56",
};
const configurations =
  process.env.REACT_APP_ENV === "production"
    ? prodFirebaseConfig
    : devFirebaseConfig;
const app = initializeApp(configurations);

export const auth = getAuth(app);
