import React, { useEffect } from "react";
import { CancelOutlined } from "@mui/icons-material";
import img from "assets/img/BlogImg1.png";
import { Settings } from "lucide-react";
import config from "config/APIEndpoints";
import moment from "moment";
import toast from "react-hot-toast";
const NotificationModal = ({ closeModal }) => {
  const [tab, setTab] = React.useState("all");
  const [notify, setNotify] = React.useState([]);


  useEffect(() => {
    (async () => {
      try {
        const api = config.getEndpoint();
        const user = JSON.parse(localStorage.getItem("boop_customer_user"));
        const res = await fetch(`${api}/api/v1/notifications`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${user?.token}`
          }
        });
        const data = await res.json();
        setNotify(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);


  return (
    <>
      <div className="w-full ">
        <div
          className="flex justify-between items-center"
        >
          <span
            className="text-2xl font-poppins font-bold "
          >Notification</span>
          <span
            onClick={async () => {
              try {
                const api = config.getEndpoint();
                const user = JSON.parse(localStorage.getItem("boop_customer_user"));
                const res = await fetch(`${api}/api/v1/notifications/read`, {
                  method: "PUT",
                  headers: {
                    "Authorization": `Bearer ${user?.token}`,
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify({ id: notify?.map((n) => n?.id) })
                });
                await res.json();

                toast.success("All notifications are marked as read");

                setNotify(notify.map((not) => ({ ...not, isRead: true })));
              } catch (error) {
                console.log(error);
              }
            }}
            className="underline cursor-pointer"
          >Mark all as read</span>
        </div>
        {/* Tabs */}
        <div className="flex items-center justify-between">
          <div className="flex justify-start items-center w-max my-4 
           
          ">
            <button
              onClick={() => setTab("all")}
              className={`${tab === "all"
                ? "text-[#FF6B01] border-b-2 border-[#FF6B01]"
                : "text-[#666C7E]  border-b-2 border-[#8d929e]"
                } font-poppins font-normal text-[14px] px-4 py-1`}
            >
              All
            </button>
            <button
              onClick={() => setTab("new")}
              className={`${tab === "new"
                ? "text-[#FF6B01] border-b-2 border-[#FF6B01]"
                : "text-[#666C7E]  border-b-2 border-[#8d929e]"
                } font-poppins font-normal text-[14px] px-4 py-1`}
            >
              New
            </button>
            <button
              onClick={() => setTab("previous")}
              className={`${tab === "previous"
                ? "text-[#FF6B01] border-b-2 border-[#FF6B01]"
                : "text-[#666C7E]  border-b-2 border-[#8d929e]"
                } font-poppins font-normal text-[14px] px-4 py-1`}
            >
              Previous
            </button>

          </div>
          <span
            className="flex items-center justify-center cursor-pointer"
          >
            <Settings />
          </span>
        </div>
        <div className="flex-col flex gap-y-2  pb-8 justify-around   space-y-4">
          {notify.length > 0 && notify
            ?.filter((n) => tab === "all" ? n : tab === "new" ? !n?.isRead : n?.isRead)
            ?.map((n) => (
              <>
                <div className="flex flex-row items-center justify-between gap-4">
                  <img
                    className="w-auto h-6 self-center"
                    src="/notity.png" alt="notify-logo" />
                  <span className="w-full">
                    <h2 className={`font-bold ${n?.isRead ? `text-[#838383]` : "text-black"} `}>
                      {n?.title}
                    </h2>
                    <h3
                      className="flex items-center justify-start gap-2"
                    >
                      <p
                        className={`w-max ${!n?.isRead && `text-[#FF6B01]`}`}
                      >
                        {moment(n.createdAt).fromNow()}
                      </p>
                      <p>
                        | {n?.message}
                      </p>
                    </h3>
                  </span>
                  {!n?.isRead && <span
                    className="self-end "
                    onClick={async () => {
                      try {
                        const api = config.getEndpoint();
                        const user = JSON.parse(localStorage.getItem("boop_customer_user"));
                        const res = await fetch(`${api}/api/v1/notifications/read`, {
                          method: "PUT",
                          headers: {
                            "Authorization": `Bearer ${user?.token}`,
                            "Content-Type": "application/json"
                          },
                          body: JSON.stringify({ id: [n?.id] })
                        });
                        await res.json();

                        toast.success("Notification marked as read");

                        setNotify(notify.map((not) => {
                          if (not?.id === n?.id) {
                            return { ...not, isRead: true };
                          }
                          return not;
                        }));
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    <div className="w-6 h-6 cursor-pointer rounded-full bg-[#FF6B01]"></div>

                  </span>}
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  );
};

export default NotificationModal;
