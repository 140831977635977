import React from "react";
import { useNavigate } from "react-router-dom";

const ServiceCardV2 = ({ data }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => navigate(`/home/services/all?cat=${data.title}`)}
        className="bg-white w-fit  shadow-PetProfileCardShadow gap-3 rounded-xl flex flex-col justify-center items-center p-4  min-h-[108px] cursor-pointer ">
        <span className="bg-white rounded-full p-5 border-4 hover:border-borderColor ">
          <img className=" w-8 h-7 " src={data.img} alt="#" />
        </span>
        <p className="font-poppins text-xs text-[#95908B] hover:text-borderColor font-bold">
          {data.title}
        </p>
      </div>
    </>
  );
};

export default ServiceCardV2;
