import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableCellComponent from "components/PetManagement/Tables/TableCellComponent";
import TableHeadCellComponent from "components/PetManagement/Tables/TableHeadCellComponent";
import { useDeleteJournalMutation } from "store/HealthHub/healthHubSlice";
import moment from "moment";
import { IoEye } from "react-icons/io5";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useState } from "react";
import Confirm from "components/Common/Modal/Confirm";
export default function JournalRecordTable({ data, opentable }) {
  const [deleterecord, deletestate] = useDeleteJournalMutation();
  const [confirm, setconfirm] = useState({ show: false, record: null });
  return (
    <>
      <Confirm
        show={confirm.show}
        width={40}
        onClose={() => setconfirm({ show: false, record: null })}
      >
        <div className="flex flex-col w-full gap-4 items-center justify-center">
          <h2 className="w-full text-center">
            {" "}
            Are you Sure You want to delete?
          </h2>
          <div className="flex flex-row gap-5">
            <button
              onClick={() => setconfirm({ show: false, record: null })}
              className="bg-white text-black p-2 pl-5 pr-5 rounded-2xl border border-borderColor"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                deleterecord(confirm?.record);
                setconfirm({ show: false, record: null });
              }}
              className="bg-borderColor text-white  p-2 pl-5 pr-5 rounded-2xl"
            >
              Confirm
            </button>
          </div>
        </div>
      </Confirm>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableHeadCellComponent>Date</TableHeadCellComponent>
              <TableHeadCellComponent>Type Of Journal</TableHeadCellComponent>

              <TableHeadCellComponent>View</TableHeadCellComponent>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0 ? (
              data.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCellComponent>
                    {moment(
                      row.journal_type == "Allergy"
                        ? row?.allergy_date
                        : row.journal_type == "Vomitus"
                          ? row?.vomitus_date
                          : row.journal_type == "Feces"
                            ? row?.feces_date
                            : row.journal_type == "Heat"
                              ? row?.heat_end
                              : "-"
                    ).format("LL") || "-"}
                  </TableCellComponent>
                  <TableCellComponent>
                    {row.journal_type || "-"}
                  </TableCellComponent>

                  <TableCellComponent>
                    <div className="flex flex0row gap-5 items-center justify-center">
                      <IoEye
                        onClick={() => opentable(row)}
                        size={30}
                        className="text-borderColor cursor-pointer"
                      />
                      <MdOutlineDeleteOutline
                        onClick={() =>
                          setconfirm({ show: true, record: row?.id })
                        }
                        size={30}
                        className="text-borderColor cursor-pointer"
                      />
                    </div>
                  </TableCellComponent>
                </TableRow>
              ))
            ) : (
              <h1 className="text-center p-3">No Records Found</h1>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
