import React from "react";

function Confirm({ show, children, width, onClose }) {
  return (
    show && (
      <div
        className="fixed  top-0 left-0 w-screen h-screen z-10 
      "
      >
        <div
          onClick={onClose}
          className="absolute w-full h-full bg-black opacity-50 z-10 "
        ></div>
        <div
          style={{
            width: `${width}%`,
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          className="bg-white p-5  z-20 absolute rounded-2xl  max-h-[90%] overflow-y-scroll no-scrollbar"
        >
          {children}
        </div>
      </div>
    )
  );
}

export default Confirm;
