import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableCellComponent from "components/PetManagement/Tables/TableCellComponent";
import TableHeadCellComponent from "components/PetManagement/Tables/TableHeadCellComponent";
import ViewButtonComponent from "components/PetManagement/Tables/ViewButtonComponent";
import moment from "moment";
export default function DewormingRecordTable({ data }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeadCellComponent>Administered Date</TableHeadCellComponent>
            <TableHeadCellComponent>Name</TableHeadCellComponent>
            <TableHeadCellComponent>Next Dosage Date</TableHeadCellComponent>
            <TableHeadCellComponent>Document</TableHeadCellComponent>
            <TableHeadCellComponent>View</TableHeadCellComponent>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 ? (
            data?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCellComponent>
                  {" "}
                  {row.administered_date
                    ? moment(row.administered_date).format("LL")
                    : "-"}
                </TableCellComponent>
                <TableCellComponent>{row.vaccine_name}</TableCellComponent>
                <TableCellComponent>
                  {" "}
                  {row.next_dosage_due_date
                    ? moment(row.next_dosage_due_date).format("LL")
                    : "-"}
                </TableCellComponent>
                <TableCellComponent
                  customStyles={{
                    color: "#D99149",
                    textDecoration: "underline",
                  }}
                >
                  {row?.photo?.name ? (
                    <a target="_blank" href={row?.photo?.url}>
                      {row.photo.name}
                    </a>
                  ) : (
                    "-"
                  )}
                </TableCellComponent>
                <TableCellComponent>
                  <ViewButtonComponent
                    navigationPath={`/home/health-hub/deworming/${row.id}`}
                  />
                </TableCellComponent>
              </TableRow>
            ))
          ) : (
            <h1 className="w-full p-3 text-center">No Records Found</h1>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
