import React, { useEffect, useState } from "react";
import { StarIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import location from "assets/svg/location.svg";
import { GiDogHouse } from "react-icons/gi";

function findMinMaxCost(services) {
  if (services?.length === 0) {
    return null;
  }

  let minCost = Infinity;
  let maxCost = -Infinity;
  let minService = null;
  let maxService = null;

  services?.forEach((service) => {
    if (service.service_cost < minCost) {
      minCost = service.service_cost;
      minService = service;
    }
    if (service.service_cost > maxCost) {
      maxCost = service.service_cost;
      maxService = service;
    }
  });

  if (services?.length === 1) {
    minService = services[0];
    maxService = services[0];
  }

  return { minService, maxService };
}

const ServiceCard = ({ service }) => {
  const navigate = useNavigate();
  const [mimax, setminmax] = useState({ min: null, max: null });
  console.log(service, "-------");
  useEffect(() => {
    // const minmax = findMinMaxCost(service?.service_provideds);
    // if (minmax)
    //   setminmax({
    //     min: minmax?.minService?.service_cost,
    //     max: minmax?.maxService?.service_cost,
    //   });
  }, [service]);
  const handleNavigation = (id) => {
    navigate(`/home/services/all/${id}`);
  };

  return (
    <>
      <div className="bg-white flex  p-4 rounded-xl flex-row w-full gap-5">
        <div className="w-3/12 relative flex items-center justify-center">
          {service?.center_photo_id ? (
            <img
              src={service?.center_photo_id[0]?.url}
              alt="#"
              className=" rounded-xl  h-[14rem] w-[14rem]"
            />
          ) : (
            <GiDogHouse size={150} className="text-borderColor" />
          )}
          {service.topPick && (
            <div className="absolute right-3 top-3 flex rounded-xl justify-center items-center bg-cardBg py-2 px-4">
              <p className="text-xl font-poppins font-semibold text-black">
                Top Picks
              </p>
            </div>
          )}
        </div>
        <div className="w-3/12 gap-[18px]  flex flex-col">
          <div className="flex flex-col gap-2">
            <div className="justify-normal items-center gap-2 flex">
              <img src={location} alt="location" />
              <p className="text-[#888] font-poppins text-base font-normal">
                {service?.city}, {service?.state}
              </p>
            </div>
            {service?.distance && service?.distance != "NaN" && (
              <h1 className="text-[1rem] font-bold">{service?.distance}Km</h1>
            )}
            <p className="text-black text-[28px] font-semibold font-poppins">
              {service?.service_name}
            </p>
          </div>
          <div className="flex gap-2 w-fit justify-center items-center">
            <div className="bg-cardBg w-fit flex rounded-xl gap-2 justify-center items-center px-4 py-2">
              <p className="text-[17px] font-poppins font-semibold text-black">
                4.2
              </p>
              <StarIcon className="text-black" size={18} />
            </div>
            <p className="text-[#717171] font-poppins text-lg font-semibold">
              (5.4K Reviews)
            </p>
          </div>

          {/*   <div className="inline-flex justify-normal items-center gap-2">
            <p className="text-black font-poppins font-medium text-base">
              {mimax?.min}- {mimax?.max}
            </p>
            <p className="text-black font-poppins font-medium text-base">
              • {service.service_provideds[0]?.service_duration}
            </p>
          </div>*/}

          <div
            onClick={() => handleNavigation(service?.id)}
            className="flex cursor-pointer max-w-[146px] rounded-xl justify-center items-center bg-borderColor py-2 px-4"
          >
            <p className="text-base font-poppins font-normal text-white">
              View more
            </p>
          </div>
        </div>
        <div className="w-5/12">
          <div className="flex flex-col justify-start items-start">
            <div className="text-black font-poppins text-sm font-medium">
              Services Offered
            </div>
            <div className="h-[2px] w-4/12 bg-borderColor mt-1"></div>
          </div>

          <div className="w-full flex-wrap flex gap-2 justify-start items-center space-y-2 mt-4">
            {service?.services_provided?.map((service, index) => (
              <p
                key={index}
                className="text-sm bg-cardBg rounded-xl px-4 py-2 font-poppins font-light text-black"
              >
                {service?.service_name}
              </p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;