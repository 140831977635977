import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCreateVaccineRecordMutation } from "store/HealthHub/healthHubSlice";
import { AttachFile, CancelOutlined } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/FormComponents/Input";
import toast from "react-hot-toast";
const AddVaccincationRecord = ({ closeModal }) => {
  const selectedpet = useSelector((state) => state?.petsetting?.activepet);
  const [createrecord, createstate] = useCreateVaccineRecordMutation();

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      vaccine_name: "",
      administered_date: null,
      next_vaccine_due_date: null,
      consult_doctor: "",
      administration_site: "",
      additional_notes: "",
      pet_id: selectedpet.id,
    },
    validationSchema: Yup.object().shape({
      vaccine_name: Yup.string().required("Vaccine Name is required"),
      administration_type: Yup.string(),
      administered_date: Yup.date()
        .nullable()
        .required("Administered Date is required"),
      next_vaccine_due_date: Yup.date().nullable(),
      consult_doctor: Yup.string(),
    }),
    onSubmit: async (values) => {
      try {
        const formDataPayload = new FormData();
        for (const key in values) {
          if (values[key]) formDataPayload.append(key, values[key]);
        }

        await createrecord(formDataPayload);

        closeModal();
        toast.success("Profile updated successfully");
      } catch (error) {
        toast.error("Failed to update profile");
        console.error("Update profile error:", error);
      }
    },
  });
  const handlePhotoFileChange = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("file", file);
  };

  const handleDocFileChange = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("document", file);
  };

  return (
    <>
      <div className="w-full ">
        <div className="text-black inline-flex flex-col justify-center items-center min-w-full  font-poppins text-4xl font-semibold">
          Add Vaccination Record
          <div className="h-[1px] w-1/4 bg-borderColor mt-1 text-center"></div>{" "}
        </div>
        <div className="flex justify-end items-center gap-x-2 absolute top-6 right-6 w-full">
          <button
            onClick={closeModal}
            className="bg-faintBg  cursor-pointer  text-borderColor rounded-full"
          >
            <CancelOutlined />
          </button>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-between items-start my-5 ">
            <div className="w-full flex justify-center items-center flex-col gap-4 ">
              <div className="w-full justify-between items-start gap-y-2 gap-x-8 flex flex-wrap">
                <span className="w-5/12">
                  <Input
                    label="Vaccine Name"
                    type="option"
                    name="vaccine_name"
                    formik={formik}
                    options={[
                      { id: 1, val: "Option 1" },
                      { id: 2, val: "Option 2" },
                      { id: 3, val: "Option 3" },
                    ]}
                    placeholder="Select an option"
                  />
                </span>
                <span className="w-5/12">
                  <Input
                    label="Administered Date"
                    placeholder="Medicine Name"
                    type="date"
                    name="administered_date"
                    formik={formik}
                  />
                </span>
                <span className="w-5/12">
                  <Input
                    label="Next Vaccine Due "
                    placeholder="Next Vaccine Due"
                    type="date"
                    name="next_vaccine_due_date"
                    formik={formik}
                  />
                </span>
                <span className="w-5/12">
                  <Input
                    label="Consult Doctor"
                    type="text"
                    name="consult_doctor"
                    formik={formik}
                  />
                </span>
              </div>
              <div className="w-full justify-start items-start gap-y-4 gap-x-8 flex flex-wrap">
                <div className="text-black w-11/12 font-poppins text-lg font-semibold  px-0 ">
                  Optional fields
                  <div className="h-[1px] w-4/12 bg-borderColor mt-1"></div>{" "}
                </div>

                <div className="w-full justify-between items-start gap-y-4 gap-x-4 flex flex-wrap">
                  <Input
                    label="Administration Site"
                    type="text"
                    name="administration_site"
                    formik={formik}
                  />
                  <span className="w-6/12">
                    <p className="font-poppins font-bold text-xs ">Add photo</p>
                    <div className="flex items-center border-[2px] border-borderColor rounded-[2px] p-1">
                      <input
                        type="file"
                        id="photoInput"
                        className="hidden"
                        accept="image/*"
                        onChange={handlePhotoFileChange}
                      />
                      <label
                        htmlFor="photoInput"
                        className="font-poppins p-1 text-xs font-bold bg-[#fdeedc] text-black rounded-[2px] cursor-pointer"
                      >
                        <AttachFile style={{ height: "15px", width: "15px" }} />{" "}
                        Select file
                      </label>
                      <span
                        id="fileName"
                        className="ml-2 text-xs font-poppins text-[#b0b0b0]"
                      >
                        {formik?.values?.file?.name || "No file selected"}
                      </span>
                    </div>
                  </span>

                  <span className="w-6/12">
                    <p className="font-poppins font-bold text-xs ">
                      Add Document
                    </p>
                    <div className="flex items-center border-[2px] border-borderColor rounded-[2px] p-1">
                      <input
                        type="file"
                        id="docInput"
                        className="hidden"
                        accept=".pdf"
                        onChange={handleDocFileChange}
                      />
                      <label
                        htmlFor="docInput"
                        className="font-poppins p-1 text-xs font-bold bg-[#fdeedc] text-black rounded-[2px] cursor-pointer"
                      >
                        <AttachFile style={{ height: "15px", width: "15px" }} />{" "}
                        Select file (.pdf)
                      </label>
                      <span
                        id="docName"
                        className="ml-2 text-xs font-poppins text-[#b0b0b0]"
                      >
                        {formik?.values?.document?.name || "No file selected"}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full justify-end gap-x-5 items-center">
            <button
              type="submit"
              className="flex w-5/12 mt-auto  rounded-[6px]  justify-center items-center bg-borderColor p-2"
            >
              <p className="text-base font-poppins font-normal text-white">
                {createstate.isLoading ? "Saving..." : "Create"}
              </p>
            </button>

            <button
              onClick={closeModal}
              className="flex w-3/12 mt-auto rounded-[6px] justify-center items-center bg-faintBg py-2 px-4"
            >
              <p className="text-base font-poppins font-normal text-borderColor">
                Cancel
              </p>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddVaccincationRecord;
