import React, { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ResetModal from "components/Common/Modal/ResetModal";
import ModalWrapper from "components/Common/Modal/ModalWrapper";

const Account = () => {
  const [formData, setFormData] = useState({
    email: "Rocky",
    phoneNo: "Rocky",
    password: "Rocky",
    accountUpdates: false,
    newsletters: false,
  });

  const [resetOpen, setResetOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    console.log(formData);
  
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div>
          <div className="w-full">
            <div>
              <p className="text-black text-xl font-semibold font-poppins">
                Account
              </p>
              <p className="text-[#717171] font-poppins font-medium text-[15px] text-justify ">
                Manage your Account Settings
                <div className="h-[1px] w-4/12 bg-borderColor"></div>
              </p>
            </div>

            <div className="w-full flex justify-center items-center flex-col gap-4 mt-5">
              <div className="w-full flex justify-start items-center gap-4 ">
                <span className="w-6/12">
                  <p className="font-poppins font-bold text-xs ">Email</p>
                  <input
                    name="email"
                    type="text"
                    value={formData.email}
                    onChange={handleChange}
                    className=" font-poppins border-[1px] border-borderColor p-4 text-xs font-bold  w-full bg-white  h-7 rounded-[10px] focus:outline-none"
                    placeholder="Email*"
                    required
                  />
                </span>
              </div>
              <div className="w-full flex justify-start items-center gap-4 ">
                <span className="w-6/12">
                  <p className="font-poppins font-bold text-xs ">Phone No</p>
                  <input
                    name="phoneNo"
                    type="text"
                    value={formData.phoneNo}
                    onChange={handleChange}
                    className=" font-poppins border-[1px] border-borderColor p-4 text-xs font-bold  w-full bg-white  h-7 rounded-[10px] focus:outline-none"
                    placeholder="Phone No*"
                    required
                  />
                </span>
              </div>

              <div className="w-full flex justify-start items-center gap-4 ">
                <span className="w-6/12">
                  <p className="font-poppins font-bold text-xs ">Password</p>
                  <input
                    name="password"
                    type="text"
                    value={formData.password}
                    onChange={handleChange}
                    className=" font-poppins border-[1px] border-borderColor p-4 text-xs font-bold  w-full bg-white  h-7 rounded-[10px] focus:outline-none"
                    placeholder="Password*"
                    required
                  />
                </span>
              </div>
            </div>

            <div className="mt-5 space-y-2">
              <p className="text-black text-xl font-semibold font-poppins">
                Notification Preference
              </p>
              <p className="text-[#717171] font-poppins font-medium text-[15px] text-justify ">
                Manage your Notifications
                <div className="h-[1px] w-4/12 bg-borderColor"></div>
              </p>
              <p className="text-black text-sm font-semibold font-poppins">
                Emails Notifications
              </p>

              <FormGroup>
                <FormControlLabel
                  style={{ color: "#424856" }}
                  control={
                    <Switch
                      checked={formData.accountUpdates}
                      onChange={handleToggleChange}
                      name="accountUpdates"
                    />
                  }
                  label="Account updates"
                />
                <FormControlLabel
                  style={{ color: "#424856" }}
                  control={
                    <Switch
                      checked={formData.newsletters}
                      onChange={handleToggleChange}
                      name="newsletters"
                    />
                  }
                  label="Newsletters"
                />
              </FormGroup>
            </div>
            <div className="flex mt-5  gap-5 justify-start items-center">
              <button
                type="submit"
                className="flex w-fit bg-borderColor cursor-pointer  text-white  p-2 justify-center items-center flex-shrink-0 rounded-md  "
              >
                <p className="font-poppins font-medium text-justify text-sm">
                  Save Changes
                </p>
              </button>
              <button
                type="button"
                onClick={() => setResetOpen(true)}
                className="flex w-fit bg-[#CF3030] cursor-pointer text-white  p-2 justify-center items-center flex-shrink-0 rounded-md  "
              >
                <p className="font-poppins font-medium text-justify text-sm">
                  Reset Password
                </p>
              </button>
              <button
                type="button"
                className="flex w-fit hover:bg-borderColor  border-2 cursor-pointer border-borderColor  hover:text-white  p-2 justify-center items-center flex-shrink-0 rounded-md  "
              >
                <p className="font-poppins font-medium text-justify text-sm">
                  Cancel
                </p>
              </button>
            </div>
          </div>
        </div>
      </form>

      <ModalWrapper open={resetOpen} handleClose={() => setResetOpen(false)}>
        <ResetModal closeModal={() => setResetOpen(false)} />
      </ModalWrapper>
    </>
  );
};

export default Account;
