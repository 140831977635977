import { AttachFile, CancelOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import { useCreateMedicalRecordMutation } from "store/HealthHub/healthHubSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/FormComponents/Input";
import toast from "react-hot-toast";
const AddMedicalRecord = ({ closeModal }) => {
  const dispatch = useDispatch();
  const selectedpet = useSelector((state) => state?.petsetting?.activepet);
  const [createrecord, createstate] = useCreateMedicalRecordMutation();

  const formik = useFormik({
    initialValues: {
      medicine_name: "",
      medicine_type: "",
      administration_type: "",
      administered_date: null,
      next_dosage_due_date: null,
      consult_doctor: "",
      administration_site: "",
      additional_notes: "",
      pet_id: selectedpet.id,
    },
    validationSchema: Yup.object().shape({
      medicine_name: Yup.string().required("Medicine Name is required"),
      medicine_type: Yup.string(),
      administration_type: Yup.string(),
      administered_date: Yup.date()
        .nullable()
        .required("Administered Date is required"),
      next_dosage_due_date: Yup.date().nullable(),
      consult_doctor: Yup.string(),
    }),
    onSubmit: (values) => {
      try {
        const formDataPayload = new FormData();
        for (const key in values) {
          if (values[key]) formDataPayload.append(key, values[key]);
        }

        createrecord(formDataPayload);
        closeModal();
        toast.success("Profile updated successfully");
      } catch (error) {
        toast.error("Failed to update profile");
      }
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      formik.setFieldValue("file", file);
    }
  };

  return (
    <div className="w-full">
      <div className="text-black inline-flex flex-col justify-center items-center min-w-full font-poppins text-4xl font-semibold">
        Add Medical Record
        <div className="h-[1px] w-1/4 bg-borderColor mt-1 text-center"></div>
      </div>
      <div className="flex justify-end items-center gap-x-2 absolute top-6 right-6 w-full">
        <button
          onClick={closeModal}
          className="bg-faintBg cursor-pointer text-borderColor rounded-full"
        >
          <CancelOutlined />
        </button>
      </div>
      <div className="flex justify-between items-start my-5">
        <div className="w-full flex justify-center items-center flex-col gap-8">
          <div className="w-full justify-evenly items-start gap-y-4 gap-x-8 flex flex-wrap">
            <span className="w-5/12">
              <Input
                label="Medicine Name"
                placeholder="Medicine Name"
                type="text"
                name="medicine_name"
                required={true}
                formik={formik}
              />
            </span>
            <span className="w-5/12">
              <Input
                label="Medicine Type"
                type="option"
                name="medicine_type"
                formik={formik}
                options={[
                  { id: 1, val: "Option 1" },
                  { id: 2, val: "Option 2" },
                  { id: 3, val: "Option 3" },
                ]}
                placeholder="Select an option"
              />
            </span>
            <span className="w-5/12">
              <Input
                label="Administration Type"
                type="option"
                name="administration_type"
                formik={formik}
                options={[
                  { id: 1, val: "Option 1" },
                  { id: 2, val: "Option 2" },
                  { id: 3, val: "Option 3" },
                ]}
                placeholder="Select an option"
              />
            </span>
            <span className="w-5/12">
              <Input
                label="Administered Date"
                placeholder="Medicine Name"
                type="date"
                name="administered_date"
                formik={formik}
                required={true}
              />
            </span>
            <span className="w-5/12">
              <Input
                label="Next Dosage Due"
                placeholder="Next Dosage Due"
                type="date"
                name="next_dosage_due_date"
                formik={formik}
              />
            </span>
            <span className="w-5/12">
              <Input
                label="Consult Doctor"
                placeholder="Consult Doctor"
                type="text"
                name="consult_doctor"
                formik={formik}
              />
            </span>
          </div>
          <div className="w-full justify-evenly items-start gap-y-4 gap-x-8 flex flex-wrap">
            <div className="text-black w-11/12 font-poppins text-lg font-semibold px-0">
              Optional fields
              <div className="h-[1px] w-4/12 bg-borderColor mt-1"></div>
            </div>
            <span className="w-5/12">
              <Input
                label="Administration Site"
                type="text"
                name="administration_site"
                formik={formik}
              />
            </span>
            <span className="w-5/12">
              <p className="font-poppins font-bold text-xs">Add a photo</p>
              <div className="flex items-center border-[2px] border-borderColor rounded-[2px] p-1">
                <input
                  type="file"
                  id="photoInput"
                  className="hidden"
                  accept="image/*"
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="photoInput"
                  className="font-poppins p-1 text-xs font-bold bg-[#fdeedc] text-black rounded-[2px] cursor-pointer"
                >
                  <AttachFile style={{ height: "15px", width: "15px" }} />{" "}
                  Select file
                </label>
                <span className="ml-2 text-xs font-poppins text-[#b0b0b0]">
                  {formik?.values?.file?.name || "No file selected"}
                </span>
              </div>
            </span>
            {/* <span className="w-11/12">
              <p className="font-poppins font-bold text-xs">Additional Note</p>
              <Input
                label="Administration Site"
                placeholder="Administration Site"
                type="textarea"
                name="additional_notes"
                formik={formik}
              />
            </span> */}
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-x-5 items-center">
        <button
          onClick={formik.handleSubmit}
          className="flex w-4/12 mt-auto rounded-[6px] justify-center items-center bg-borderColor p-2"
        >
          <p className="text-base font-poppins font-normal text-white">
            Add Medical Record
          </p>
        </button>
        <button
          onClick={closeModal}
          className="flex w-3/12 mt-auto rounded-[6px] justify-center items-center bg-faintBg py-2 px-4"
        >
          <p className="text-base font-poppins font-normal text-borderColor">
            Cancel
          </p>
        </button>
      </div>
    </div>
  );
};

export default AddMedicalRecord;
