import React, { useState } from "react";
import ProfileSettings from "components/PetManagement/Settings/ProfileSettings";
import PetProfileSettings from "components/PetManagement/Settings/PetProfileSettings";
import Account from "components/PetManagement/Settings/Account";
import HelpSupport from "components/PetManagement/Settings/HelpSupport";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("profile");

  const renderContent = () => {
    switch (activeTab) {
      case "profile":
        return <ProfileSettings />;
      case "petProfile":
        return <PetProfileSettings />;
      case "account":
        return <Account />;
      case "support":
        return <HelpSupport />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="flex border-b border-gray-200">
        <div
          className={`px-4 py-2 cursor-pointer ${activeTab === "profile" ? "text-xl border-b-2 border-borderColor text-borderColor" : "border-[#565D6D] text-[#565D6D]"}`}
          onClick={() => setActiveTab("profile")}
        >
          Profile Settings
        </div>
        <div
          className={`px-4 py-2 cursor-pointer ${activeTab === "petProfile" ? "text-xl border-b-2 border-borderColor text-borderColor" : "border-[#565D6D] text-[#565D6D]"}`}
          onClick={() => setActiveTab("petProfile")}
        >
          Pet Profile Settings
        </div>

        <div
          className={`px-4 py-2 cursor-pointer ${activeTab === "support" ? "text-xl border-b-2 border-borderColor text-borderColor" : "border-[#565D6D] text-[#565D6D]"}`}
          onClick={() => setActiveTab("support")}
        >
          Help & Support
        </div>
      </div>
      <div className="p-4 ">{renderContent()}</div>
    </div>
  );
};

export default Tabs;
