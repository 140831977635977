import React, { useState, useRef, useEffect } from "react";
import Input from "components/FormComponents/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useGetParentProfileQuery,
  useUpdateParentProfileMutation,
} from "store/settings/profileSlice";
import { MdDeleteOutline } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import axios from "axios";
import toast from "react-hot-toast";
const ProfileSettings = () => {
  const { data: mydata } = useGetParentProfileQuery();
  const [pincodeData, setPincodeData] = useState("");

  const [updateprofile, updateprofilestate] = useUpdateParentProfileMutation();
  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string(),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    address_1: Yup.string().required("Address 1 is required"),
    address_2: Yup.string(),
    zipcode: Yup.string().required("Zip Code is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    phone: Yup.string().required("Mobile Number is required"),
    phone_emergency: Yup.string(),
  });
  const [profile, setprofile] = useState();
  const formik = useFormik({
    initialValues: {
      first_name: "kedar",
      last_name: "shelar",
      email: "pcbudgeofficial@gmail.com",
      address_1: "vempire house, somewhere in mumbai",
      address_2: "aroung the grave yard",
      zipcode: "421505",
      state: "test",
      city: "test",
      phone: "919096675560",
      phone_emergency: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const formDataPayload = new FormData();
        for (const key in values) {
          formDataPayload.append(key, values[key]);
        }

        await updateprofile(formDataPayload);
        toast.success("Profile updated successfully");
      } catch (error) {
        toast.error("Failed to update profile");
      }
    },
  });
  useEffect(() => {
    let data = mydata?.petParent;
    if (mydata) {
      setprofile(data?.file?.url);
      formik.setValues({
        first_name: data.first_name || "",
        last_name: data.last_name || "",
        email: data.email || "",
        address_1: data.address_1 || "",
        address_2: data.address_2 || "",
        zipcode: data.pincode || "",
        state: data.state || "",
        city: data.city || "",
        phone: data.phone || "",
        phone_emergency: data.phone_emergency || "",
      });
    }
  }, [mydata]);

  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      formik.setFieldValue("file", file);
      setprofile(URL.createObjectURL(file));
    }
  };

  const handleFileSelect = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  const handleZipcodeChange = async (e) => {
    const zipcode = e.target.value;
    formik.setFieldValue("zipcode", zipcode);

    axios.get(`https://api.postalpincode.in/pincode/${zipcode}`).then((res) => {
      formik.setFieldValue(
        "state",
        res?.data[0].Status == "Success"
          ? res?.data[0]?.PostOffice[0]?.State
          : ""
      );
      formik.setFieldValue(
        "city",
        res?.data[0].Status == "Success"
          ? res?.data[0]?.PostOffice[0]?.Name
          : ""
      );
    });
  };

  return (
    <form
      className="w-full flex justify-normal items-start p-4 gap-x-4"
      onSubmit={(e) => {
        formik.handleSubmit();
        e.preventDefault();
      }}
    >
      <div className="w-3/12 ">
        <div className="w-full inline-flex justify-center">
          <div
            className="flex justify-center overflow-hidden items-center w-full h-56 bg-white
            rounded-xl shadow-PetProfileCardShadow relative"
          >
            {" "}
            {profile ? (
              <>
                <img
                  src={profile}
                  className="w-full object-cover overflow-hidden"
                  alt="#"
                />
                <MdDeleteOutline
                  size={40}
                  onClick={() => {
                    formik.setFieldValue("file", true);
                    setprofile(false);
                  }}
                  className="z-10 absolute top-0 right-0 bg-white text-borderColor p-2 rounded-full m-2"
                />
              </>
            ) : (
              <CgProfile size={150} className="text-borderColor" />
            )}
          </div>
        </div>
        <div className="flex mt-5 gap-5 justify-center items-center">
          <button
            type="button"
            className="flex w-fit hover:bg-borderColor border-2 hover:cursor-pointer border-borderColor hover:text-white p-2 justify-center items-center flex-shrink-0 rounded-md"
            onClick={handleFileSelect}
          >
            <p className="font-poppins font-medium text-justify text-sm">
              + Add New
            </p>
          </button>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/png, image/jpeg"
          onChange={handleFileChange}
        />
      </div>
      <div className="w-9/12">
        <div>
          <div>
            <p className="text-black text-xl font-semibold font-poppins">
              Profile Settings
            </p>

            <p className="text-[#717171] font-poppins font-medium text-[15px] text-justify ">
              Manage your Profile Settings
              <div className="h-[1px] w-4/12 bg-borderColor"></div>
            </p>
          </div>
        </div>
        <div className="w-full flex justify-center items-center flex-col gap-8 mt-5">
          <div className="w-full justify-normal items-start gap-y-6 gap-x-8 flex flex-wrap">
            <span className="w-full flex justify-center items-center gap-4 ">
              <span className="w-6/12">
                <Input
                  label={"First Name"}
                  name={"first_name"}
                  type={"text"}
                  formik={formik}
                  placeholder="First Name"
                  required={true}
                />
              </span>

              <span className="w-6/12">
                <Input
                  label={"Last Name"}
                  name={"last_name"}
                  type={"text"}
                  formik={formik}
                  placeholder="Last Name"
                />
              </span>
            </span>
            <span className="w-full">
              <Input
                label={"Email"}
                name={"email"}
                type={"email"}
                formik={formik}
                placeholder="Email"
                required={true}
              />
            </span>

            <span className="w-full">
              <Input
                label={"Address 1"}
                name={"address_1"}
                type={"textarea"}
                formik={formik}
                placeholder="Address 1"
                required={true}
              />
            </span>

            <span className="w-full">
              <Input
                label={"Address 2"}
                name={"address_2"}
                type={"textarea"}
                formik={formik}
                placeholder="Address 2"
              />
            </span>

            <div className="flex gap-[10px] w-full justify-evenly">
              <span className="w-full">
                <Input
                  label={"Zip Code"}
                  name={"zipcode"}
                  type={"text"}
                  formik={formik}
                  onChange={handleZipcodeChange}
                  placeholder="Zip Code"
                  required={true}
                />
              </span>
              <span className="w-full">
                <Input
                  label={"State"}
                  name={"state"}
                  type={"text"}
                  formik={formik}
                  placeholder="State"
                  required={true}
                />
              </span>

              <span className="w-full">
                <Input
                  label={"City"}
                  name={"city"}
                  type={"text"}
                  formik={formik}
                  placeholder="City"
                  required={true}
                />
              </span>
            </div>

            <div className="w-full flex justify-center items-center gap-4 ">
              <span className="w-6/12">
                <Input
                  label={"Mobile No"}
                  name={"phone"}
                  type={"tel"}
                  formik={formik}
                  placeholder="Mobile No"
                  required={true}
                />
              </span>
              <span className="w-6/12">
                <Input
                  label={"Emrgency No"}
                  name={"phone_emergency"}
                  type={"tel"}
                  formik={formik}
                  placeholder="Emergency No"
                />
              </span>
            </div>
          </div>
        </div>

        <div className="flex mt-5 gap-5 justify-end items-center">
          <button
            disabled={updateprofilestate?.isLoading}
            type="submit"
            className="flex w-fit bg-borderColor border-2 hover:cursor-pointer border-borderColor text-white p-2 justify-center items-center flex-shrink-0 rounded-md"
          >
            <p className="font-poppins font-medium text-justify text-sm">
              {updateprofilestate?.isLoading ? "Saving..." : " Save Changes"}
            </p>
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProfileSettings;
