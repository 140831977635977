import React, { useState, useEffect } from "react";
import ReplayIcon from "@mui/icons-material/Replay";

const FilterComponent = ({ data, filterFields, onFilter }) => {
  const initialFilters = filterFields.reduce((acc, field) => {
    acc[field.name] = "";
    return acc;
  }, {});

  const [filters, setFilters] = useState(initialFilters);
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
    applyFilters(newFilters);
  };

  const applyFilters = (filtersToApply) => {
    let result = data;
    filterFields.forEach((field) => {
      if (filtersToApply[field.name]) {
        result = result.filter(
          (item) => item[field.name] === filtersToApply[field.name]
        );
      }
    });
    setFilteredData(result);
    onFilter(result);
  };

  const resetFilters = () => {
    setFilters(initialFilters);
    setFilteredData(data);
    onFilter(data);
  };

  return (
    <div>
      <div className="flex justify-evenly items-center gap-[1rem] text-center w-[35rem] bg-white rounded-lg px-2">
        {filterFields.map((field, ind) => (
          <div
            className="flex justify-evenly items-center py-2 font-lato text-xs lg:text-base font-normal text-black bg-white"
            key={ind}
          >
            <select
              className="bg-white w-full border-none outline-none"
              name={field.name}
              value={filters[field.name]}
              onChange={handleFilterChange}
            >
              <option value="">{field.label}</option>
              {Array.from(new Set(field.options)).map((option) => (
                <option className="text-black" key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2"
              height="30"
              viewBox="0 0 2 56"
              fill="none"
            >
              <path
                opacity="0.686151"
                d="M1.02587 55V1"
                stroke="#979797"
                strokeWidth="0.3"
                strokeLinecap="square"
              />
            </svg>
          </div>
        ))}
        <ReplayIcon
          className="text-borderColor cursor-pointer"
          // onClick={resetFilters}
        />
      </div>
    </div>
  );
};

export default FilterComponent;
