import { configureStore } from "@reduxjs/toolkit";
import toasterReducer from "./toaster/index";
import HealthHubReducer from "./HealthHub/index";
import authSlice from "./auth/authSlice";
import { apiSlice } from "./apiSlice";
import cartreducer from "./StateSlice/CartSlice";
import settingreducer from "./StateSlice/settingSlice";
const store = configureStore({
  reducer: {
    toaster: toasterReducer,
    HealthHub: HealthHubReducer,
    auth: authSlice,
    ...settingreducer,
    ...cartreducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;
