import React, { useState, useEffect } from "react";
import LoginLogo from "assets/img/LoginLogo.png";
import Video from "assets/video/Video.mp4";
import { Checkbox } from "@mui/material";
import checkImg from "assets/img/checkImg.png";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import OtpInput from "otp-input-react";
import "react-phone-input-2/lib/style.css";
import {
  useSignUpMutation,
  useSendOtpMutation,
  useCheckUserMutation,
} from "store/auth/authApiSlice";
import { auth } from "config/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const LaptopViewSignUp = () => {
  const [activeButton, setActiveButton] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const paramsValue = new URLSearchParams(location.search).get("source");
  const [phoneOtpLoading, setPhoneOtpLoading] = useState(false);

  const [
    sendOtp,
    {
      isLoading: otpLoading,
      isError: otpError,
      isSuccess: otpSuccess,
      error: otpErroe,
    },
  ] = useSendOtpMutation();
  const [signUp, { isLoading, isError, isSuccess }] = useSignUpMutation();
  const [
    checkUser,
    {
      isError: isCheckUserError,
      isSuccess: checkUserSucess,
      error: checkUserError,
    },
  ] = useCheckUserMutation();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    address_1: "",
    address_2: "",
    countryCode: "+91",
    phone: "",
    email: "",
    pincode: "",
    state: "",
    city: "",
    otp: "",
    termsChecked: false,
    legalTermsChecked: false,
    phone_emergency: "",
  });
  const [zipCodeError, setZipCodeError] = useState(null);

  const { token } = useSelector((state) => state?.auth);

  useEffect(() => {
    if (checkUserSucess) {
      onSignup();
    }

    if (isCheckUserError) {
      toast.error(checkUserError?.data?.message || "User already exists!");
    }
  }, [isCheckUserError, checkUserSucess, checkUserSucess]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "pincode") {
      fetchCityAndState(value);
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const fetchCityAndState = async (zipCode) => {
    if (!zipCode) return;
    console.log({ zipCode });
    try {
      const response = await fetch(
        `https://api.postalpincode.in/pincode/${zipCode}`
      );
      const data = await response.json();

      if (data && data.length > 0 && data[0].Status === "Success") {
        const city = data[0].PostOffice[0]?.Name;
        const state = data[0].PostOffice[0].State;

        setFormData((prevData) => ({
          ...prevData,
          city,
          state,
        }));
      } else {
        setZipCodeError("Invalid zip code");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setZipCodeError("Error fetching data");
    } finally {
    }
  };

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log(response);
            //onSignup();
          },
          "expired-callback": () => { },
        }
      );
    }
  }

  function onSignup() {
    //setLoading(true);
    setPhoneOtpLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + formData.phone;
    console.log({ formatPh });
    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setPhoneOtpLoading(false);
        toast.success("OTP sended successfully!");
      })
      .catch((error) => {
        console.log(error);
        setPhoneOtpLoading(false);
      });
  }

  const onOTPVerify = async (otp) => {
    //setLoading(true);
    try {
      const result = await window.confirmationResult.confirm(otp);

      return result;
    } catch (error) {
      toast.error("OTP verification failed!");
    }
  };

  useEffect(() => {
    if (otpSuccess) {
      toast.success("OTP sent successfully!");
    }

    if (otpError) {
      toast.error("Failed to send OTP!");
    }
    console.log(otpSuccess, otpError, otpErroe);
  }, [otpSuccess, otpError, otpErroe]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Sign up successful!");
      navigate("/login");
    }

    if (isError) {
      toast.error("Failed to sign up!");
    }
    console.log(isError, isSuccess);
  }, [isError, isSuccess, navigate]);

  const handleActiveButtonChange = (value) => {
    setActiveButton(value);

    if (value === "Email") {
      const { countryCode, legalTermsChecked, termsChecked, otp, ...rest } =
        formData;
      sendOtp(rest);
    } else {
      // phone otp will go here.
      checkUser(new URLSearchParams({ phone: formData.phone }));
    }
  };

  const handleSubmit = async () => {
    if (!formData.termsChecked || !formData.legalTermsChecked) {
      alert("Please agree to terms and conditions.");
      return;
    }
    // first_name: "",
    // last_name: "",
    // address_1: "",
    // address_2: "",
    // countryCode: "+91",
    // phone: "",
    // email: "",
    // pincode: "",
    // state: "",
    // city: "",
    // otp: "",
    // termsChecked: false,
    // legalTermsChecked: false,
    // phone_emergency: "",
    if (!formData.first_name) {
      toast.error("First Name is required");
    }
    if (!formData.last_name) {
      toast.error("Last Name is required");
    }
    if (!formData.address_1) {
      toast.error("Address 1  is required");
    }
    if (!formData.email) {
      toast.error("Email is required");
    }
    if (!formData.phone) {
      toast.error("Phone Number is required");
    }
    if (!formData.phone_emergency) {
      toast.error("Emergency Phone Number is required");
    }
    if (!formData.pincode) {
      toast.error("Zipcode Number is required");
    }
    if (!formData.city) {
      toast.error("City Number is required");
    }
    if (!formData.state) {
      toast.error("State Number is required");
    }
    const { countryCode, legalTermsChecked, termsChecked, phone, ...rest } =
      formData;
    if (activeButton === "Mobile") {
      // mobile otp verification
      onOTPVerify(formData.otp).then(() => {
        signUp({ rest, phone });
      });
    } else {
      console.log(rest);
      console.log(formData);
      signUp({ ...rest, phone });
    }
  };

  useEffect(() => {
    setZipCodeError(null);
  }, [formData.zipCode]);

  // const handleZipcodeChange = async (e) => {
  //   const zipcode = e.target.value;
  //   formik.setFieldValue("zipcode", zipcode);

  //   axios.get(`https://api.postalpincode.in/pincode/${zipcode}`).then((res) => {
  //     formik.setFieldValue(
  //       "state",
  //       res?.data[0].Status == "Success"
  //         ? res?.data[0]?.PostOffice[0]?.State
  //         : ""
  //     );
  //     formik.setFieldValue(
  //       "city",
  //       res?.data[0].Status == "Success"
  //         ? res?.data[0]?.PostOffice[0]?.Name
  //         : ""
  //     );
  //   });
  // };

  return (
    <div className="flex justify-center items-center  relative">
      <div className="absolute inset-0 flex items-center justify-center">
        <video
          className="videoTag w-full h-full object-cover"
          autoPlay
          loop
          muted
        >
          <source src={Video} type="video/mp4" />
        </video>
      </div>
      <div id="recaptcha-container"></div>
      <div className="flex flex-col my-16 max-w-[60%] px-16 py-4 rounded-3xl border-2 border-borderColor backdrop-blur-LoginBlur relative z-10 bg-LoginCard">
        <div className="justify-center flex">
          <img
            src={LoginLogo}
            className="w-[160px] h-[40px] shrink-0"
            alt="Name Icon"
          />
        </div>
        {/* Signup page */}
        <div className="flex flex-col gap-4">
          <div className="text-center">
            <p className=" font-poppins text-2xl font-semibold leading-8 tracking-wide">
              Create your account
            </p>
            <p className="text-black font-poppins text-[14px] font-medium leading-8 tracking-tight">
              Manage your pet service with us
            </p>
          </div>
          <div className="flex flex-col w-full gap-2 justify-evenly items-center ">
            <div className="flex gap-4 w-full ">
              <span className="w-2/4">
                <p className="font-poppins font-bold text-[14px] ">
                  First Name
                </p>
                <input
                  onChange={handleChange}
                  name="first_name"
                  value={formData.first_name}
                  type="text"
                  className="border-borderColor w-full font-poppins rounded-[9px] border-t border-b border-l  bg-white h-[40px] px-3 text-xs focus:outline-none"
                  placeholder="Enter First name"
                  required
                />
              </span>

              <span className="w-2/4">
                <p className="font-poppins font-bold text-[14px] ">Last Name</p>
                <input
                  onChange={handleChange}
                  name="last_name"
                  value={formData.last_name}
                  type="text"
                  className="border-borderColor w-full font-poppins rounded-[9px] border-t border-b border-l  bg-white h-[40px] px-3 text-xs focus:outline-none"
                  placeholder="Enter Last name"
                  required
                />
              </span>
            </div>

            <div className="flex gap-4 w-full "></div>
            <span className="w-full">
              <p className="font-poppins font-bold text-[14px] ">Email</p>
              <input
                onChange={handleChange}
                name="email"
                value={formData.email}
                type="email"
                className="border-borderColor w-full font-poppins rounded-[9px] border-t border-b border-l  bg-white h-[40px] px-3 text-xs focus:outline-none"
                placeholder="Enter email address*"
                required
              />
            </span>
          </div>

          <div className="flex gap-4 w-full ">
            <span className="w-2/4">
              <p className="font-poppins font-bold text-[14px] ">Address 1</p>
              <input
                onChange={handleChange}
                name="address_1"
                value={formData.address_1}
                type="text"
                className="border-borderColor w-full font-poppins rounded-[9px] border-t border-b border-l  bg-white h-[40px] px-3 text-xs focus:outline-none"
                placeholder="Address 1*"
                required
              />
            </span>
            <span className="w-2/4">
              <p className="font-poppins font-bold text-[14px] ">Address 2</p>
              <input
                onChange={handleChange}
                name="address_2"
                value={formData.address_2}
                type="text"
                className="border-borderColor w-full font-poppins rounded-[9px] border-t border-b border-l  bg-white h-[40px] px-3 text-xs focus:outline-none"
                placeholder="Address 2*"
                required
              />
            </span>
          </div>

          <div className="flex gap-[10px] w-full justify-evenly">
            <span className="w-4/12">
              <p className="font-poppins font-bold text-[14px] ">Zip Code</p>
              <input
                onChange={handleChange}
                name="pincode"
                value={formData.pincode}
                type="text"
                className="border w-full h-[40px] font-poppins border-borderColor rounded-[9px] border-t border-b border-l bg-white px-3 text-xs focus:outline-none"
                placeholder="Zip Code*"
                required
              />
            </span>

            <span className="w-4/12">
              <p className="font-poppins font-bold text-[14px] ">State</p>
              <input
                name="state"
                value={formData.state}
                type="text"
                className="border w-full  h-[40px] font-poppins cursor-no-drop border-borderColor rounded-xl bg-gray-200 px-4 text-xs focus:outline-none"
                placeholder="State"
                disabled={!formData.pincode}
              />
            </span>

            <span className="w-4/12">
              <p className="font-poppins font-bold text-[14px] ">City</p>
              <input
                name="city"
                value={formData.city}
                type="text"
                className="border w-full  h-[40px] font-poppins cursor-no-drop border-borderColor rounded-xl bg-gray-200  px-4 text-xs focus:outline-none"
                placeholder="City"
                disabled={!formData.pincode}
              />
            </span>
          </div>

          <div className="flex gap-4 w-full ">
            <div className="flex flex-col justify-start items-start w-2/4">
              <p className="font-poppins font-bold text-[14px] ">Mobile No</p>
              <PhoneInput
                country={"in"}
                placeholder="Phone number"
                name="phone"
                value={formData.phone}
                onChange={(val) =>
                  setFormData((prev) => ({ ...prev, phone: val }))
                }
              />
            </div>

            <div className="flex flex-col justify-start items-start w-2/4">
              <p className="font-poppins font-bold text-[14px] ">
                Emergency No
              </p>
              <PhoneInput
                country={"in"}
                placeholder="Emergency Phone number"
                name="phone_emergency"
                value={formData.phone_emergency}
                onChange={(val) =>
                  setFormData((prev) => ({ ...prev, phone_emergency: val }))
                }
              />
            </div>
          </div>

          <div className="flex gap-4 w-full ">
            <div className="flex flex-col justify-start items-start w-2/4">
              <p className="font-poppins font-bold text-[14px] ">
                Receive OTP via
              </p>
              <div className="flex bg-white p-1 w-full rounded-[9px]">
                <button
                  className={`flex w-2/4 ${activeButton === "Mobile" ? "bg-borderColor text-white" : "bg-white text-black"} cursor-pointer  p-2 justify-center items-center flex-shrink-0 rounded-md`}
                  onClick={() => handleActiveButtonChange("Mobile")}
                >
                  <p className="font-poppins font-medium text-justify text-sm">
                    {activeButton === "Mobile" && phoneOtpLoading
                      ? "Sending..."
                      : "Mobile"}
                  </p>
                </button>
                <button
                  className={`flex w-2/4 ${activeButton === "Email" ? "bg-borderColor text-white" : "bg-white text-black"} cursor-pointer p-2 justify-center items-center flex-shrink-0 rounded-md`}
                  onClick={() => handleActiveButtonChange("Email")}
                >
                  <p className="font-poppins font-medium text-justify text-sm">
                    {activeButton === "Email" && otpLoading
                      ? "Sending..."
                      : "Email"}
                  </p>
                </button>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start w-2/4">
              <p className="font-poppins font-bold text-[14px] ">OTP</p>
              <OtpInput
                otpType="number"
                value={formData.otp}
                onChange={(value) => {
                  if (value.toString().length == 6) {
                  }
                  setFormData((prev) => ({ ...prev, otp: value }));
                }}
                OTPLength={6}
                inputStyles={{
                  width: "36px",
                  height: "36px",
                  border: "none",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  padding: "6px",
                  marginRight: "8px",
                  fontSize: "12px",
                  color: "#000",
                }}
                separator={<span style={{ width: "5px" }}></span>}
              />
            </div>
          </div>
          <span>
            <div className="flex items-center gap-2">
              <Checkbox
                onChange={handleCheckboxChange}
                name="termsChecked"
                value={formData.termsChecked}
                sx={{
                  color: "#DB9048",
                  "&.Mui-checked": {
                    color: "#DB9048",
                  },
                }}
              />
              <p className="text-gray-900 font-poppins text-base font-normal leading-6 tracking-wide">
                Agree to{" "}
                <span className="font-medium font-poppins underline">
                  Terms and Conditions
                </span>
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                onChange={handleCheckboxChange}
                name="legalTermsChecked"
                value={formData.legalTermsChecked}
                sx={{
                  color: "#DB9048",
                  "&.Mui-checked": {
                    color: "#DB9048",
                  },
                }}
              />
              <p className="text-gray-900 font-poppins text-base font-normal leading-6 tracking-wide">
                Accept{" "}
                <span className="font-medium font-poppins underline">
                  Legal Terms
                </span>
              </p>
            </div>
          </span>
          <button
            className="bg-borderColor h-[52px] font-poppins hover:bg-opacity-75 text-white font-bold text-xl py-2 px-4 rounded-[9px]"
            onClick={handleSubmit}
          >
            {isLoading ? "Loading..." : "Sign Up"}
          </button>

          <p className="text-center  text-gray-900 font-poppins text-xl font-medium leading-6 tracking-wide">
            Already a user?{" "}
            <span
              onClick={() => navigate("/login")}
              className="cursor-pointer font-bold underline font-poppins"
            >
              Sign in
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LaptopViewSignUp;
