import React from "react";
import PetProfileIcon from "assets/img/PetProfileIcon.png";

import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { Facebook, Instagram, LinkedIn } from "@mui/icons-material";
import { Twitter } from "@mui/icons-material";

const FooterV2 = () => {
  return (
    <>
      <div className="flex flex-col p-8 bg-white justify-center items-center">
        <div class="h-[1px] w-8/12 bg-borderColor mt-1"></div>{" "}
        <div className="flex justify-center items-center gap-4 w-full mt-5 ">
          <div className="w-2/4 flex justify-center items-center">
            <img src={PetProfileIcon} className=" h-10 w-36 " alt="logo" />
          </div>

          <div className="w-2/4 flex flex-col justify-center items-center">
            <div
              className="flex flex-col
           justify-start items-start gap-4"
            >
              <p className="text-sm font-poppins font-normal ">
                <PhoneIcon className="hover:cursor-pointer text-borderColor" />{" "}
                7972398765{" "}
              </p>

              <p className="text-sm font-poppins font-normal ">
                <LocationOnIcon className="hover:cursor-pointer text-borderColor" />
                Hyderabad, Telangana
              </p>
              <p className="text-sm font-poppins font-normal ">
                <ApartmentIcon className="hover:cursor-pointer text-borderColor" />
                (+20) 42424 234234
              </p>

              <div className="flex gap-2 justify-center">
                <Instagram className="hover:cursor-pointer text-[#fa7e1e]" />
                <Facebook className="hover:cursor-pointer text-[#0866ff]" />
                <Twitter className="hover:cursor-pointer text-[#1DA1F2]" />
                <LinkedIn className="hover:cursor-pointer text-[#0a66c2]" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-10/12 mt-4 flex-row flex-wrap items-center justify-between gap-y-6 gap-x-12 border-t border-blue-gray-50 py-6 text-center md:justify-between">
          <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
            <li>
              <div
                as="a"
                href="#"
                color="blue-gray"
                className="text-black font-poppins text-xs font-normal uppercase"
              >
                About Us
              </div>
            </li>
            <li>
              <div
                as="a"
                href="#"
                color="blue-gray"
                className="text-black font-poppins text-xs font-normal uppercase"
              >
                License
              </div>
            </li>
            <li>
              <div
                as="a"
                href="#"
                color="blue-gray"
                className="text-black font-poppins text-xs font-normal uppercase"
              >
                Contribute
              </div>
            </li>
            <li>
              <div
                as="a"
                href="#"
                color="blue-gray"
                className="text-black font-poppins text-xs font-normal uppercase"
              >
                Contact Us
              </div>
            </li>
          </ul>

          <div
            color="blue-gray"
            className="text-black font-poppins text-xs font-normal uppercase opacity-50"
          >
            copyright &copy; 2024 Boop
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterV2;
