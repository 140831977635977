import React, { useState } from "react";
import nameIcon from "assets/img/nameIcon.png";
import Video from "assets/video/Video.mp4";
import { Checkbox } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ChevronLeft } from "@mui/icons-material";
import stopwatch from "assets/img/stopwatch.png";
import ProfileCreation from "components/Common/Modal/ProfileCreation";
import Verify1 from "assets/svg/Verify1.svg";
import Verify2 from "assets/svg/Verify2.svg";
import Verify3 from "assets/svg/Verify3.svg";
import timerSvg from "assets/svg/timerSvg.svg";

const MobileViewSignUp = () => {
  const [step, setStep] = useState(1); // To keep track of sign-up steps

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    zipCode: "",
    state: "",
    city: "",
    countryCode: "+91",
    phoneNumber: "",
    emergencyPhoneNumber: "",
    email: "",
    otp: "",
    termsChecked: false,
    legalTermsChecked: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleSubmit = () => {
    // Perform form validation here before sending data to the API
    if (!formData.termsChecked || !formData.legalTermsChecked) {
      alert("Please agree to terms and conditions.");
      return;
    }

    // Send formData to API endpoint for profile creation
    // Example fetch request:
    fetch("https://example.com/api/create-profile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle success response from API
        console.log("Profile created successfully:", data);
        // Optionally, you can navigate to the next page or show a success message
      })
      .catch((error) => {
        // Handle error response from API
        console.error("Error creating profile:", error);
        // Optionally, show an error message to the user
      });
  };

  return (
    <div className="overflow-hidden flex justify-center items-center h-screen relative">
      <div className="absolute inset-0 flex items-center justify-center">
        <video
          className="videoTag w-full h-full object-cover"
          autoPlay
          loop
          muted
        >
          <source src={Video} type="video/mp4" />
        </video>
      </div>

      <div className=" w-full m-4 py-12 px-4 rounded-3xl border-2 border-borderColor backdrop-blur-2xl relative z-10">
        <div className="justify-center flex">
          <img src={nameIcon} className="w-48 h-28" alt="Name Icon" />
        </div>

        <div className="flex flex-col gap-4">
          <div className="text-center">
            <p className="text-gray-800 font-poppins text-4xl font-medium leading-8 tracking-wide">
              Create your account
            </p>
            {step === 1 && (
              <span className="flex justify-center items-center flex-col gap-1">
                {" "}
                <p className="text-black font-poppins text-xl font-normal leading-8 tracking-tight">
                  Step 1 : Enter your name and email
                </p>
                <img src={Verify1} />
              </span>
            )}
            {step === 2 && (
              <span className="flex justify-center items-center flex-col gap-1">
                <p className="text-black font-poppins text-xl font-normal leading-8 tracking-tight">
                  Step 2 : Enter your Address and details
                </p>
                <img src={Verify2} />
              </span>
            )}
            {step === 3 && (
              <span className="flex justify-center items-center flex-col gap-1">
                <p className="text-black font-poppins text-xl font-normal leading-8 tracking-tight">
                  Step 3 : Verification
                </p>
                <img src={Verify3} />
              </span>
            )}
          </div>

          {step === 1 && (
            <div className="flex flex-col gap-4">
              <div className="flex justify-between items-center gap-2">
                <input
                  onChange={handleChange}
                  name="firstName"
                  value={formData.firstName}
                  type="text"
                  className="w-6/12 h-[39px] border border-borderColor rounded-xl bg-white px-4 text-xs focus:outline-none"
                  placeholder="First Name"
                />
                <input
                  onChange={handleChange}
                  name="lastName"
                  value={formData.lastName}
                  type="text"
                  className="w-6/12 h-[39px] border border-borderColor rounded-xl bg-white px-4 text-xs focus:outline-none"
                  placeholder="Last Name"
                />
              </div>
              <input
                onChange={handleChange}
                name="email"
                value={formData.email}
                type="text"
                className=" h-[38px] border border-borderColor rounded-xl bg-white px-4 text-xs focus:outline-none"
                placeholder="Email"
              />
              <button
                className="bg-borderColor h-[52px] hover:bg-opacity-75 text-black font-bold text-xl py-2 px-4 rounded-xl"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          )}

          {step === 2 && (
            <div className="flex flex-col gap-4">
              <div className="flex justify-between items-center gap-2">
                <input
                  onChange={handleChange}
                  name="address1"
                  value={formData.address1}
                  type="text"
                  className="w-6/12 h-[39px] border border-borderColor rounded-xl bg-white px-4 text-xs focus:outline-none"
                  placeholder="Address 1"
                />
                <input
                  onChange={handleChange}
                  name="address2"
                  value={formData.address2}
                  type="text"
                  className="w-6/12 h-[39px] border border-borderColor rounded-xl bg-white px-4 text-xs focus:outline-none"
                  placeholder="Address 2"
                />
              </div>

              <div className="flex justify-between items-center gap-2">
                <input
                  onChange={handleChange}
                  name="zipCode"
                  value={formData.zipCode}
                  type="text"
                  className="border w-6/12 h-[39px] border-borderColor rounded-xl bg-white px-4 text-xs focus:outline-none"
                  placeholder="Zip Code"
                />
                <input
                  onChange={handleChange}
                  name="state"
                  value={formData.state}
                  type="text"
                  className="border w-6/12 h-[39px] border-borderColor rounded-xl bg-white px-4 text-xs focus:outline-none"
                  placeholder="State"
                />
              </div>
              <input
                onChange={handleChange}
                name="city"
                value={formData.city}
                type="text"
                className="border  h-[39px] border-borderColor rounded-xl bg-white px-4 text-xs focus:outline-none"
                placeholder="City"
              />

              <div className="flex justify-between items-center gap-2">
                <input
                  onChange={handleChange}
                  name="countryCode"
                  value={formData.countryCode}
                  type="text"
                  className="w-2/12 h-[39px]  border border-borderColor rounded-xl bg-white  px-2 text-center text-xs focus:outline-none"
                  placeholder="+91"
                />
                <input
                  onChange={handleChange}
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  type="text"
                  className="w-10/12 h-[39px]  border border-borderColor rounded-xl bg-white px-4 pr-40 text-xs focus:outline-none"
                  placeholder="Phone Number"
                />
              </div>
              <div className="flex justify-between items-center gap-2">
                <button
                  className="bg-white w-2/12 h-[50px] hover:bg-opacity-75 text-black font-bold text-xl py-2 px-4 rounded-xl"
                  onClick={handlePrevious}
                >
                  <ChevronLeftIcon />
                </button>
                <button
                  className="bg-borderColor w-10/12 h-[50px] hover:bg-opacity-75 text-black font-bold text-xl py-2 px-4 rounded-xl"
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="flex flex-col gap-4">
              <input
                onChange={handleChange}
                name="email"
                value={formData.email}
                type="text"
                className=" h-[38px] border border-borderColor rounded-xl bg-white px-4 text-xs focus:outline-none"
                placeholder="Email"
              />
              <div className="flex justify-between">
                <div className="pr-2 flex border border-borderColor rounded-xl w-fit items-center gap-1 focus:outline-none">
                  <input
                    onChange={handleChange}
                    name="otp"
                    value={formData.otp}
                    type="text"
                    className=" w-[83px] h-[38px] border border-borderColor rounded-xl bg-white  px-4 text-lg focus:outline-none"
                    placeholder="OTP"
                  />

                  <p>Resend OTP ?</p>
                </div>
                <div className="relative w-[58px] h-[58px]">
                  <img src={timerSvg} />
                  <img
                    src={stopwatch}
                    className="w-[50px] h-[50px] top-5 absolute"
                  />
                </div>
              </div>

              <div className="flex items-center h-[15px] gap-2">
                <Checkbox
                  onChange={handleCheckboxChange}
                  name="termsChecked"
                  value={formData.termsChecked}
                  sx={{
                    color: "#DB9048",
                    "&.Mui-checked": {
                      color: "#DB9048",
                    },
                  }}
                />
                <p className="text-gray-900 font-poppins text-base font-normal leading-6 tracking-wide">
                  Agree to{" "}
                  <span className="font-medium underline">
                    Terms and Conditions
                  </span>
                </p>
              </div>
              <div className="flex items-center h-[15px] gap-2">
                <Checkbox
                  onChange={handleCheckboxChange}
                  name="legalTermsChecked"
                  value={formData.legalTermsChecked}
                  sx={{
                    color: "#DB9048",
                    "&.Mui-checked": {
                      color: "#DB9048",
                    },
                  }}
                />
                <p className="text-gray-900 font-poppins text-base font-normal leading-6 tracking-wide">
                  Accept{" "}
                  <span className="font-medium underline">Legal Terms</span>
                </p>
              </div>

              <div className="flex justify-between items-center gap-2">
                <button
                  className="bg-white w-2/12 h-[50px] hover:bg-opacity-75 text-black font-bold text-xl py-2 px-4 rounded-xl"
                  onClick={handlePrevious}
                >
                  <ChevronLeft />
                </button>
                <button
                  className="bg-borderColor w-10/12 h-[50px] hover:bg-opacity-75 text-black font-bold text-xl py-2 px-4 rounded-xl"
                  onClick={handleSubmit}
                >
                  Sign Up
                </button>
              </div>
            </div>
          )}

          <p className="text-center text-gray-900 font-poppins text-xl font-medium leading-6 tracking-wide">
            Already a user?{" "}
            <span className="font-medium underline">Sign in</span>
          </p>
        </div>
      </div>

      {/* <div className="w-11/12 sm:w-6/12 m-4 sm:py-12 py-4 px-4 rounded-3xl border-2 border-borderColor backdrop-blur-2xl relative z-10">
      <ProfileCreation />
      </div> */}
    </div>
  );
};

export default MobileViewSignUp;
