import DashboardFilter from "components/Common/DashboardFilter";
import { Search } from "lucide-react";
import React, { useEffect, useState } from "react";
import ServiceCard from "./ServiceCard";
import BlogImg from "assets/img/BlogImg1.png";
import { useGetAllServicesQuery } from "store/Services/serviceApiSlice";
import { useLocation, useSearchParams } from "react-router-dom";

const servicesData = [
  {
    id: 1,
    location: "Kukatpally, Hyderabad, Telangana",
    name: "Pet Cleaning Servicing",
    rating: 4.2,
    reviews: "5.4K Reviews",
    price: "₹399.00 - ₹699.00",
    duration: "30 minutes",
    image: BlogImg,
    services: [
      "Service 1",
      "Service 2",
      "Service 3",
      "Service 4",
      "Service 5",
      "Service 6",
    ],
    topPick: true,
  },
];

function Skeleton() {
  return (
    <>
      <div className="bg-white flex p-4 rounded-xl flex-row w-full gap-5 animate-pulse opacity-50">
        <div className="w-3/12 relative flex items-center justify-center">
          <div className="bg-borderColor rounded-xl h-[14rem] w-[14rem]"></div>
          <div className="absolute right-3 top-3 flex rounded-xl justify-center items-cente py-2 px-4"></div>
        </div>
        <div className="w-4/12 space-y-3">
          <div className="justify-normal items-center gap-2 flex">
            <div className="bg-borderColor h-5 w-16"></div>
          </div>
          <div className="text-black text-2xl font-semibold font-poppins">
            <div className="bg-borderColor h-8 w-60"></div>
          </div>
          <div className="flex gap-2 w-fit justify-center items-center">
            <div className="bg-borderColor h-12 w-24 flex rounded-xl gap-2 justify-center items-center px-4 py-2"></div>
            <div className="bg-borderColor h-8 w-16"></div>
          </div>
          <div className="inline-flex justify-normal items-center gap-2">
            <div className="bg-borderColor h-6 w-20"></div>
            <div className="bg-borderColor h-6 w-40"></div>
          </div>
          <div className="flex max-w-[146px] rounded-xl justify-center items-center bg-gray-400 py-2 px-4 cursor-not-allowed"></div>
        </div>
        <div className="w-5/12">
          <div className="flex flex-col justify-start items-start">
            <div className="text-black font-poppins text-sm font-medium">
              <div className="bg-borderColor h-5 w-40"></div>
            </div>
            <div className="h-[2px] w-4/12 bg-gray-300 mt-1"></div>
          </div>

          <div className="w-full flex-wrap flex gap-2 justify-start items-center space-y-2 mt-4">
            <div className="bg-borderColor h-8 w-36 rounded-xl px-4 py-2 font-poppins font-light"></div>
            <div className="bg-borderColor h-8 w-36 rounded-xl px-4 py-2 font-poppins font-light"></div>
          </div>
        </div>
      </div>
    </>
  );
}

const serviceFilterFields = [
  {
    name: "location",
    label: "Location",
    options: ["Kukatpally", "Banjara Hills", "Madhapur"],
  },
  { name: "rating", label: "Rating", options: [4.5, 4.0, 3.5, 3.0] },
  {
    name: "name",
    label: "Service Type",
    options: ["Inbound Service", "Outbout Service"],
  },
];
function createServiceProviderRecords(services) {
  if (!services) return;
  const serviceProviderMap = new Map();
  // console.log({ services });
  services.forEach((service) => {
    const { service_user, distance, ...serviceDetails } = service;
    const serviceProviderId = service_user.id;

    if (!serviceProviderMap.has(serviceProviderId)) {
      serviceProviderMap.set(serviceProviderId, {
        ...service_user,
        distance,
        services_provided: [],
      });
    }

    serviceProviderMap
      .get(serviceProviderId)
      .services_provided.push(serviceDetails);
  });

  return Array.from(serviceProviderMap.values());
}
function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}
function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371;
  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(lat1)) *
    Math.cos(degreesToRadians(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
}


const ExploreServiceList = () => {
  const [filteredData, setFilteredData] = useState(servicesData);
  const location = useLocation();
  // need query from url
  const paramValue = new URLSearchParams(location.search).get("query");
  const catValue = new URLSearchParams(location.search).get("cat");

  console.log();
  const [filters, setFilters] = useState({
    search: "",
    serviceType: "",
    serviceCategory: "",
    outdoor_inddor: "",
    acnonac: "",
    singhle_group: "",
    standard_medical_special: "",
  });
  useEffect(() => {
    if (paramValue) {
      setFilters({
        ...filters, search: paramValue
      })
    }

    if (catValue) {
      setFilters({
        ...filters, serviceCategory: catValue
      })
    }
  }, [paramValue, catValue]);
  const [searchParams] = useSearchParams();
  const [serviceresult, setserviceresult] = useState();
  const lat = searchParams.get("lat");
  const lng = searchParams.get("lng");
  const distance = calculateDistance(lat, lng, lat, lng);

  const { data: allservice, isLoading } = useGetAllServicesQuery(
    // paramValue
    //   ? {
    //       city: paramValue,
    //     }
    //   :
    undefined
  );

  useEffect(() => {
    setserviceresult(
      allservice?.data?.map((rec) => {
        let langlat = JSON.parse(rec?.service_user?.longitude_latitude);

        return {
          ...rec,
          distance: calculateDistance(
            parseFloat(lat),
            parseFloat(lng),
            langlat?.latitude,
            langlat?.longitude
          ).toFixed(2),
        };
      })
    );
  }, [allservice]);
  const [servicetype, setservicetype] = useState();
  // console.log(paramValue);
  const handleFilteredData = (data) => {
    setFilteredData(data);
  };


  return (
    <>
      <div className="p-8 flex flex-col justify-normal items-start gap-4">
        <div className="flex flex-col justify-start items-start">
          <div className="text-black font-poppins text-4xl font-semibold px-0">
            Explore Services
          </div>
          <div className="h-1 w-8/12 bg-borderColor mt-1"></div>
        </div>
        <div className="flex justify-center gap-4 items-start mt-5 w-full">
          <div className="inline-flex justify-start items-center gap-4 ">
            <div className="relative">
              <input
                type="text"
                className="flex py-2 px-4 items-start rounded-lg bg-white w-96 shadow-base border-none outline-none"
                onChange={(e) => {
                  setFilters({ ...filters, search: e.target.value });
                }}
                value={filters.search}
                placeholder="Search..."
              />
              <Search className="absolute right-3 top-3 text-gray-500 pointer-events-none" />
            </div>
          </div>
          <button className="flex w-[150px] h-[45px] bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md">
            <p className="font-poppins font-medium text-justify text-xl">
              Search
            </p>
          </button>
        </div>

        {serviceresult?.length == 0 ? (
          <h1 className="w-full text-orange-500 flex itmes-center justify-center">
            No Services Found
          </h1>
        ) : (
          <>
            {" "}
            <div className="flex flex-col justify-center items-center w-full gap-3">
              {/* <p className="font-poppins text-start text-xs underline font-semibold cursor-pointer">
                Filter by
              </p> */}
              <div className="w-full flex justify-center items-center flex-row gap-5">
                <div className="flex justify-evenly items-center py-2 font-lato text-xs lg:text-base font-normal text-black bg-white rounded-xl text-bold">
                  <select
                    className="bg-white w-full border-none outline-none rounded-lg pl-5 pr-5"
                    value={filters.serviceType}
                    onChange={(e) => {
                      setFilters({ ...filters, serviceType: e.target.value });
                    }}
                  >
                    <option value="">Select Service Type</option>
                    <option className="text-black" value={"Inbound"}>
                      Inbound
                    </option>
                    <option className="text-black" value={"Outbound"}>
                      Outbound
                    </option>
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="30"
                    viewBox="0 0 2 56"
                    fill="none"
                  >
                    <path
                      opacity="0.686151"
                      d="M1.02587 55V1"
                      stroke="#979797"
                      strokeWidth="0.3"
                      strokeLinecap="square"
                    />
                  </svg>
                </div>
                <div className="flex justify-evenly items-center py-2 font-lato text-xs lg:text-base font-normal text-black bg-white rounded-xl text-bold">
                  <select
                    className="bg-white w-full border-none outline-none rounded-lg pl-5 pr-5"
                    value={filters.serviceCategory}
                    onChange={(e) => {
                      setFilters({ ...filters, serviceCategory: e.target.value });
                    }}
                  >
                    <option value="">Select Service Category</option>
                    <option className="text-black" value={"Boarding"}>
                      Boarding
                    </option>
                    <option className="text-black" value={"Grooming"}>
                      Grooming
                    </option>
                    <option className="text-black" value={"Training"}>
                      Training
                    </option>
                    <option className="text-black" value={"Day Care"}>
                      Day Care
                    </option>
                    <option className="text-black" value={"Pet Taxi"}>
                      Pet Taxi
                    </option>
                    <option className="text-black" value={"Vets"}>
                      Vets
                    </option>
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="30"
                    viewBox="0 0 2 56"
                    fill="none"
                  >
                    <path
                      opacity="0.686151"
                      d="M1.02587 55V1"
                      stroke="#979797"
                      strokeWidth="0.3"
                      strokeLinecap="square"
                    />
                  </svg>
                </div>

                {filters.serviceCategory === "Boarding" && (
                  <>
                    <div className="flex justify-evenly items-center py-2 font-lato text-xs lg:text-base font-normal text-black bg-white rounded-xl text-bold">
                      <select
                        className="bg-white w-full border-none outline-none rounded-lg pl-5 pr-5"
                        value={filters.outdoor_inddor}
                        onChange={(e) => {
                          setFilters({ ...filters, outdoor_inddor: e.target.value });
                        }}
                      >
                        <option value="">Select Outdoor/Indoor</option>
                        {["Outdoor",
                          "Indoor",
                        ].map((room) => (
                          <option key={room} className="text-black" value={room}>
                            {room}
                          </option>
                        ))}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2"
                        height="30"
                        viewBox="0 0 2 56"
                        fill="none"
                      >
                        <path
                          opacity="0.686151"
                          d="M1.02587 55V1"
                          stroke="#979797"
                          strokeWidth="0.3"
                          strokeLinecap="square"
                        />
                      </svg>
                    </div>
                    <div className="flex justify-evenly items-center py-2 font-lato text-xs lg:text-base font-normal text-black bg-white rounded-xl text-bold">
                      <select
                        className="bg-white w-full border-none outline-none rounded-lg pl-5 pr-5"
                        value={filters.acnonac}
                        onChange={(e) => {
                          setFilters({ ...filters, acnonac: e.target.value });
                        }}
                      >
                        <option value="">Select Ac/Non-Ac</option>
                        {[
                          "Ac",
                          "NonAc",
                        ].map((room) => (
                          <option key={room} className="text-black" value={room}>
                            {room}
                          </option>
                        ))}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2"
                        height="30"
                        viewBox="0 0 2 56"
                        fill="none"
                      >
                        <path
                          opacity="0.686151"
                          d="M1.02587 55V1"
                          stroke="#979797"
                          strokeWidth="0.3"
                          strokeLinecap="square"
                        />
                      </svg>
                    </div>
                    <div className="flex justify-evenly items-center py-2 font-lato text-xs lg:text-base font-normal text-black bg-white rounded-xl text-bold">
                      <select
                        className="bg-white w-full border-none outline-none rounded-lg pl-5 pr-5"
                        value={filters.singhle_group}
                        onChange={(e) => {
                          setFilters({ ...filters, singhle_group: e.target.value });
                        }}
                      >
                        <option value="">Select Single/Group</option>
                        {[
                          "Single",
                          "group",
                        ].map((room) => (
                          <option key={room} className="text-black" value={room}>
                            {room}
                          </option>
                        ))}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2"
                        height="30"
                        viewBox="0 0 2 56"
                        fill="none"
                      >
                        <path
                          opacity="0.686151"
                          d="M1.02587 55V1"
                          stroke="#979797"
                          strokeWidth="0.3"
                          strokeLinecap="square"
                        />
                      </svg>
                    </div>
                    <div className="flex justify-evenly items-center py-2 font-lato text-xs lg:text-base font-normal text-black bg-white rounded-xl text-bold">
                      <select
                        className="bg-white w-full border-none outline-none rounded-lg pl-5 pr-5"
                        value={filters.standard_medical_special}
                        onChange={(e) => {
                          setFilters({ ...filters, standard_medical_special: e.target.value });
                        }}
                      >
                        <option value="">Select Standard/Medical/Special</option>
                        {[
                          "Standard",
                          "Medical",
                          "Special"].map((room) => (
                            <option key={room} className="text-black" value={room}>
                              {room}
                            </option>
                          ))}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2"
                        height="30"
                        viewBox="0 0 2 56"
                        fill="none"
                      >
                        <path
                          opacity="0.686151"
                          d="M1.02587 55V1"
                          stroke="#979797"
                          strokeWidth="0.3"
                          strokeLinecap="square"
                        />
                      </svg>
                    </div>
                  </>
                )}
                <div
                  onClick={() => {
                    // reset all the filters
                    setFilters({ search: "", serviceType: "", serviceCategory: "" });
                  }}
                  className="flex cursor-pointer justify-evenly items-center p-4 font-lato text-sm lg:text-base font-normal text-black bg-white rounded-xl text-bold">

                  <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.07963 2.61109V0.158691L2.01457 3.22419L5.07963 6.28969V3.83729C7.1087 3.83729 8.75771 5.48653 8.75771 7.51589C8.75771 9.54525 7.1087 11.1945 5.07963 11.1945C3.05056 11.1945 1.40156 9.54525 1.40156 7.51589H0.175537C0.175537 10.2258 2.37012 12.4207 5.07963 12.4207C7.78915 12.4207 9.98373 10.2258 9.98373 7.51589C9.98373 4.80599 7.78915 2.61109 5.07963 2.61109Z" fill="#EA0234" />
                  </svg>

                </div>
              </div>
            </div>
            <div className="flex flex-1 flex-col gap-4 justify-normal items-center w-full">
              {isLoading && [1, 2, 3, 4].map((rec) => <Skeleton />)}
              {createServiceProviderRecords(serviceresult)
                ?.sort((a, b) => a.distance - b.distance)
                .filter((service) => {
                  return filters.serviceType ? service.services_provided.some(
                    (serv) => serv.inbound_outbound === filters.serviceType
                  )
                    : true;
                })
                .filter((service) => {
                  return filters.search ? service.service_name?.toLowerCase().includes(filters.search.toLowerCase())
                    : true;
                })
                .filter((service) => {
                  return filters.serviceCategory ? service?.services_provided?.some(
                    (serv) => serv?.service_type.toLowerCase() === filters.serviceCategory.toLowerCase()
                  )
                    : true;
                })
                .filter((service) => { // Indoor/Outdoor
                  console.log(service);
                  return filters.outdoor_inddor ? service?.services_provided?.some(
                    (serv) => serv?.service_accomodation?.outdoor_or_indoor.toLowerCase() === filters.outdoor_inddor.toLowerCase()
                  )
                    : true;
                })
                .filter((service) => { // Ac/Non-Ac
                  return filters.acnonac ? service?.services_provided?.some(
                    (serv) => serv?.service_accomodation?.ac_or_nonAc.toLowerCase() === filters.acnonac.toLowerCase()
                  )
                    : true;
                })
                .filter((service) => { // Single/Group
                  return filters.singhle_group ? service?.services_provided?.some(
                    (serv) => serv?.service_accomodation?.single_or_group.toLowerCase() === filters.singhle_group.toLowerCase()
                  )
                    : true;
                })
                .filter((service) => { // Standard/Medical/Special
                  return filters.standard_medical_special ? service?.services_provided?.some(
                    (serv) => serv?.service_accomodation?.special_medical_care.toLowerCase() === filters.standard_medical_special.toLowerCase()
                  )
                    : true;
                })
                .map((service) => {
                  return <ServiceCard key={service.id} service={service} />;
                })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ExploreServiceList;
