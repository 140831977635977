import React from "react";
import Pet from "assets/img/Pet.png";
import { CancelOutlined } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { ProfileCard } from "components/PetManagement/Settings/PetProfileSettings";
import { setactive } from "store/StateSlice/settingSlice";

const SwitchPetModal = ({ closeModal }) => {
  const selectedpet = useSelector((state) => state?.petsetting);
  const dispatch = useDispatch();
  return (
    <>
      <div className="w-full ">
        <div className="text-black inline-flex flex-col justify-center items-center min-w-full  font-poppins text-4xl font-semibold">
          Switch Pet
          <div class="h-[1px] w-1/4 bg-borderColor mt-1 text-center"></div>{" "}
        </div>
        <div className="flex justify-end items-center gap-x-2 absolute top-6 right-6 w-full">
          <button
            onClick={closeModal}
            className="bg-faintBg  cursor-pointer  text-borderColor rounded-full"
          >
            <CancelOutlined />
          </button>
        </div>

        <div className="flex-wrap flex gap-4 justify-around mt-4">
          {selectedpet?.allpets.map((rec) => (
            <ProfileCard
              selected={rec?.id == selectedpet?.activepet?.id}
              name={rec?.name}
              profilepic={rec?.file}
              activepet={() => {
                dispatch(setactive(rec));
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SwitchPetModal;
