import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableCellComponent from "components/PetManagement/Tables/TableCellComponent";
import TableHeadCellComponent from "components/PetManagement/Tables/TableHeadCellComponent";
import ViewButtonComponent from "components/PetManagement/Tables/ViewButtonComponent";
import moment from "moment";

export default function MedicalReportTable({ data }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeadCellComponent>Administered Date</TableHeadCellComponent>
            <TableHeadCellComponent>Medicine Name</TableHeadCellComponent>
            <TableHeadCellComponent>Medicine Type</TableHeadCellComponent>
            <TableHeadCellComponent>Next Due Date</TableHeadCellComponent>
            <TableHeadCellComponent>Consult Doctor</TableHeadCellComponent>
            <TableHeadCellComponent>Medicine Type</TableHeadCellComponent>
            <TableHeadCellComponent>View</TableHeadCellComponent>
          </TableRow>
        </TableHead>
        {data?.length > 0 ? (
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCellComponent>
                  {row.administered_date
                    ? moment(row.administered_date).format("LL")
                    : "-"}
                </TableCellComponent>
                <TableCellComponent>
                  {row.medicine_name || "-"}
                </TableCellComponent>
                <TableCellComponent>
                  {row.medicine_type || "-"}
                </TableCellComponent>
                <TableCellComponent>
                  {row.next_dosage_due_date
                    ? moment(row.next_dosage_due_date).format("LL")
                    : "-"}
                </TableCellComponent>
                <TableCellComponent>
                  {row.consult_doctor || "-"}
                </TableCellComponent>
                <TableCellComponent>
                  {row.medicine_type || "-"}
                </TableCellComponent>
                <TableCellComponent>
                  <ViewButtonComponent
                    navigationPath={`/home/health-hub/medical-records/${row.id}`}
                  />
                </TableCellComponent>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <h1 className="w-full text-center p-2">No Records Found</h1>
        )}
      </Table>
    </TableContainer>
  );
}
