import React, { useState } from "react";
import location from "assets/svg/location.svg";
import { StarIcon } from "lucide-react";
import BlogImg from "assets/img/BlogImg1.png";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import { emptycart, removeFromCart } from "store/StateSlice/CartSlice";
import { useDispatch } from "react-redux";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import OrderSummary from "components/Common/Modal/OrderSummary";
import AddAdress from "components/Common/Modal/AddNewAddress";
import ModalWrapper from "components/Common/Modal/ModalWrapper";
import { useCreateBookingMutation } from "store/Services/serviceApiSlice";
import { useGetParentProfileQuery } from "store/settings/profileSlice";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};
function gettotal(cart) {
  let total = 0;
  cart.forEach((element) => {
    total += element?.cost;
  });
  return total;
}
const Cart = () => {
  const [createbooking, bookingstate] = useCreateBookingMutation();
  const selectedpet = useSelector((state) => state?.petsetting?.activepet);
  const allpets = useSelector((state) => state?.petsetting?.allpets);

  const { data: mydata } = useGetParentProfileQuery();

  const [modalOpen, setModalOpen] = useState(false);
  const [addressModalOpen, setaddressModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const cartstate = useSelector((state) => state?.cart);
  const openModal = () => {
    setModalOpen(true);
  };
  const totalcost = gettotal(cartstate?.cartitems);
  const closeModal = () => {
    setModalOpen(false);
  };
  const handleBook = async () => {
    if (!cartstate?.cartitems || cartstate?.cartitems.length === 0) {
      toast.error("No items in the cart.");
      return;
    }

    if (!mydata?.petParent?.phone || !mydata?.petParent?.email) {
      toast.error("Pet parent's phone or email is missing.");
      return;
    }

    if (!selectedpet?.type || !selectedpet?.id) {
      toast.error("Selected pet's type or ID is missing.");
      return;
    }

    try {
      await createbooking({
        parent_id: mydata?.petParent?.id,
        total_cost: totalcost,
        services: [
          cartstate?.cartitems?.map((rec) => ({
            pet_ids: rec?.pet_ids,
            service_user_id: rec?.service_user_id,
            service_id: rec?.service_id,
            pricing_model_id: rec?.pricing_model_id,
            status: "UPCOMING",
            start_date: rec?.start_date,
            end_date: rec?.end_date,
            start_time: rec?.start_time,
            end_time: rec?.end_time,
            cost: rec?.cost,
          })),
        ].flat(),
      }).then((resp) => {
        if (resp?.data?.status == "201") {
          toast.success("Booked Appointment Successfull");
          dispatch(emptycart());
        }
      });
      // toast.success("Booking created successfully.");
    } catch (err) {
      console.log(err);
      toast.error("Failed to create booking:", err);
    }
  };
  {
    console.log({ cartstate, allpets });
  }
  return (
    <>
      <div className="space-y-12 flex flex-col p-8 justify-normal">
        <div className="text-black font-poppins gap-2 text-3xl flex justify-normal items-center font-medium">
          <span className="w-[56px] h-[56px] bg-white flex justify-center items-center rounded-xl">
            <ShoppingCartOutlinedIcon className="text-borderColor  " />
          </span>
          <span>
            <p> Your Cart</p>
            <div class="h-1 w-12/12 bg-borderColor mt-1"></div>
          </span>
        </div>

        <div className="flex justify-normal items-start w-full gap-x-4">
          {cartstate?.cartitems[0]?.privoder?.services?.service_name && <div className="w-3/12 flex justify-normal items-start gap-4  flex-col ">
            <div className="flex flex-col justify-normal bg-white rounded-xl p-4 gap-y-2 items-start w-full">
              <div className="justify-normal items-center gap-2 flex">
                <img src={location} alt="location" />
                <p className="text-black font-poppins text-base font-semibold">
                  {cartstate?.cartitems[0]?.services?.location_address},{" "}
                  {cartstate?.cartitems[0]?.services?.city}{" "}
                  {cartstate?.cartitems[0]?.services?.state}
                </p>
              </div>
              <p className="text-borderColor text-base font-semibold font-poppins">
                {cartstate?.cartitems[0]?.privoder?.services?.service_name}
              </p>

              <div className="flex gap-2 w-fit justify-center items-center">
                <div className="bg-cardBg w-fit flex rounded-xl gap-2  justify-center items-center p-2">
                  <p className="text-xl font-poppins font-semibold text-black">
                    4.2
                  </p>
                  <StarIcon className="text-black" />
                </div>
                <p className="text-[#717171] font-poppins text-lg font-semibold">
                  (5.4K Reviews)
                </p>
              </div>
              <p className="text-[#878787] font-poppins text-sm font-medium">
                {cartstate?.cart?.cart[0]?.privoder?.services?.location_address}
              </p>
            </div>

            {/* <div className="flex justify-center bg-white rounded-xl p-4 items-center w-full">
              <p
                onClick={() => setaddressModalOpen(true)}
                className="text-lg text-borderColor font-poppins font-semibold cursor-pointer "
              >
                + Add New Address
              </p>
            </div> */}
          </div>}

          <div className="w-5/12 flex justify-normal items-start gap-4 flex-col">
            <div className="text-black font-poppins text-xl font-semibold  px-0 ">
              Cart Services
              <div class="h-1 w-4/12 bg-borderColor mt-1"></div>
            </div>
            {cartstate?.cartitems?.length > 0 ? (
              cartstate?.cartitems?.map((rec) => (
                <>
                  <div className="bg-white flex justify-center  p-4 rounded-xl gap-x-4 relative">
                    <button
                      className="absolute top-0 right-0 m-5 text-borderColor z-10"
                      onClick={() => dispatch(removeFromCart(rec))}
                    >
                      <MdOutlineRemoveShoppingCart />
                    </button>
                    <div className="w-5/12 relative">
                      <img
                        src={rec?.service?.file?.url || BlogImg}
                        alt="#"
                        className="w-full object-cover   rounded-xl "
                      />
                    </div>
                    <div className="w-7/12 space-y-3 relative">
                      <div className="inline-flex gap-2">
                        <p className="text-black text-lg font-semibold font-poppins">
                          {rec?.serviceuser?.service_name}
                        </p>
                      </div>

                      <div className="">
                        <p className="text-[#323743] font-medium font-poppins text-sm">
                          <EventAvailableOutlinedIcon
                            style={{ height: "20px", width: "20px" }}
                          />{" "}
                          {rec?.start_date}
                        </p>
                        <p className="text-[#323743] font-medium font-poppins text-sm">
                          <AccessTimeOutlinedIcon
                            style={{ height: "20px", width: "20px" }}
                          />{" "}
                          {rec?.start_time}
                        </p>
                      </div>

                      {/* <div className=" bg-faintBg w-fit text-borderColor rounded-[10px] py-1 px-4 flex items-center space-x-4">
                      <button
                        onClick={() => setCount(count + 1)}
                        className="text-xl w-fit  font-poppins font-normal"
                      >
                        +
                      </button>
                      <p className="text-xl w-fit font-poppins font-normal">
                        {count}
                      </p>
                      <button
                        onClick={() => setCount(count - 1)}
                        className="text-xl w-fit  font-poppins font-normal"
                      >
                        -
                      </button>
                    </div> */}
                      <span className="text-black absolute bottom-0 left-0 font-poppins font-semibold text-[0.7rem] gap-2 flex flex-wrap">
                        {rec?.pet_ids?.map((petid) => {
                          return (
                            <span className="bg-orange-100 p-2 rounded-full">
                              {
                                allpets?.filter((pet) => pet.id == petid)[0]
                                  ?.name
                              }{" "}
                            </span>
                          );
                        })}
                      </span>
                      <p className="text-black absolute bottom-0 right-0 font-poppins font-semibold text-base">
                        ₹{rec?.cost}
                      </p>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <h1>Cart is Empty</h1>
            )}
          </div>

          <div className="w-4/12 flex justify-normal items-start gap-4 flex-col">
            <div className="flex w-full flex-col justify-normal bg-white rounded-xl p-4 gap-y-2 items-start">
              <p className="text-lg font-semibold font-poppins">
                Order Summary
              </p>

              <div className="space-y-2 w-full">
                {cartstate?.cartitems?.map((rec) => (
                  <>
                    <div className="w-full">
                      <div className="w-full flex justify-between text-[16px] items-center text-[#878787] font-medium font-poppins ">
                        <p> {rec?.serviceuser?.service_name}</p>
                      </div>
                      <ul className="w-full m-0 list-disc flex flex-col justify-between text-[14px] items-center text-[#878787] font-medium font-poppins">
                        <li className="w-full flex justify-between list-disc">
                          <p className="ml-6">
                            {" "}
                            • {rec?.serviceuser?.service_name}
                          </p>
                          <p>
                            {" "}
                            - ₹ {rec?.cost}
                            .00
                          </p>
                        </li>
                      </ul>
                    </div>
                  </>
                ))}
              </div>

              <div className="text-[#35A55B] text-base font-semibold w-full flex justify-between  items-center font-poppins">
                <p>Order Total</p> <p>₹{totalcost}.00</p>
              </div>

              <div class="h-[1px] w-full bg-[#878787] mt-1"></div>

              <div className="w-full  text-lg  text-[#878787] font-medium font-poppins ">
                You can pay on arrival*
              </div>
              <button
                onClick={handleBook}
                className="flex w-full mt-auto rounded-xl justify-center items-center bg-borderColor py-2 px-4"
              >
                <p className="text-base font-poppins font-normal text-white">
                  Book Now
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, ["width"]: "90%" }}>
          <OrderSummary data={""} closeModal={closeModal} />
        </Box>
      </Modal>

      <ModalWrapper
        open={addressModalOpen}
        handleClose={() => setaddressModalOpen(false)}
      >
        <AddAdress closeModal={() => setaddressModalOpen(false)} />
      </ModalWrapper>
    </>
  );
};

export default Cart;
