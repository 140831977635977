import React from "react";
import Pet from "assets/img/Pet.png";
import Vaccination from "assets/svg/Vaccination.svg";
import MedicalRecords from "assets/svg/MedicalRecords.svg";
import Journal from "assets/svg/Journal.svg";
import Deworming from "assets/svg/Deworming.svg";
import BloodDonor from "assets/svg/BloodDonor.svg";
import HealthHubCard from "./cards/HealthHubCard";
import SwitchPetModal from "components/Common/Modal/SwitchPetModal";
import BloodDonorModal from "components/Common/Modal/BloodDonorModal";
import AddJournalModal from "components/Common/Modal/AddJournalModal";
import AddMedicalRecord from "components/Common/Modal/AddMedicalRecord";
import AddDewormingRecord from "components/Common/Modal/DewormingModal";
import AddVaccinationRecord from "components/Common/Modal/AddVacinationRecord";
import ModalWrapper from "components/Common/Modal/ModalWrapper";
import { useSelector } from "react-redux";
import { SiDatadog } from "react-icons/si";

const HealthHub = () => {
  const [openSwitchPetModal, setOpenSwitchPetModal] = React.useState(false);
  const [openBloodDonorModal, setOpenBloodDonorModal] = React.useState(false);
  const [openJournalModal, setOpenJournalModal] = React.useState(false);
  const [openMedicalRecordModal, setOpenMedicalRecordModal] =
    React.useState(false);
  const [openDewormingRecordModal, setOpenDewormingRecordModal] =
    React.useState(false);
  const [openVaccinationRecordModal, setOpenVaccinationRecordModal] =
    React.useState(false);
  const selectedpet = useSelector((state) => state?.petsetting?.activepet);
  const HealthHubData = [
    {
      img: MedicalRecords,
      title: "Medical Records",
      link: "/home/health-hub/medical-records",
    },
    { img: Vaccination, title: "Journal", link: "/home/health-hub/journal" },
    {
      img: Journal,
      title: "Vaccination",
      link: "/home/health-hub/vaccination-records",
    },
    {
      img: Deworming,
      title: "Deworming/Tick",
      link: "/home/health-hub/deworming",
    },
    // {
    //   img: BloodDonor,
    //   title: "Blood Donor",
    //   link: "/home/health-hub/blood-donor",
    // },
  ];

  const openModalMap = {
    "Medical Records": () => setOpenMedicalRecordModal(true),
    Journal: () => setOpenJournalModal(true),
    Vaccination: () => setOpenVaccinationRecordModal(true),
    "Deworming/Tick": () => setOpenDewormingRecordModal(true),
    "Blood Donor": () => setOpenBloodDonorModal(true),
  };

  return (
    <>
      <div className="flex justify-normal gap-4 p-8">
        <div className="w-3/12 flex flex-col gap-4 justify-between items-center">
          <div className="flex w-fit justify-center overflow-hidden items-baseline bg-borderColor px-5 pt-8 rounded-t-full rounded-b-full ">
            {selectedpet?.file ? (
              <img
                src={selectedpet?.file?.url}
                alt="Pet"
                className="rounded-t-full rounded-b-full w-44"
              />
            ) : (
              <SiDatadog size={150} />
            )}
          </div>
        </div>
        <div className="w-9/12 flex flex-col gap-4">
          <div className="inline-flex justify-normal items-center gap-2">
            <p className="text-black font-poppins text-6xl font-bold leading-100 capitalize">
              Hey
            </p>
            <p className="text-black font-poppins text-6xl font-bold leading-100 capitalize">
              <span className="text-borderColor">{selectedpet?.name}</span>
            </p>
            <button
              onClick={() => setOpenSwitchPetModal(true)}
              className="flex w-[112px] text-borderColor bg-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-l-full rounded-r-full"
            >
              <p className="font-poppins font-bold text-justify text-sm">
                Switch Pet
              </p>
            </button>
          </div>
          <p className="text-black font-poppins text-2xl font-medium leading-100">
            Welcome to your <b className="font-bold">health</b> hub
          </p>

          <div className="w-full inline-flex flex-wrap gap-4">
            {HealthHubData.map((data) => (
              <HealthHubCard
                key={data.title}
                openModal={openModalMap[data.title]}
                openBloodDonarModal={() => setOpenBloodDonorModal(true)}
                data={data}
              />
            ))}
          </div>
        </div>
      </div>

      <ModalWrapper
        open={openSwitchPetModal}
        handleClose={() => setOpenSwitchPetModal(false)}
      >
        <SwitchPetModal closeModal={() => setOpenSwitchPetModal(false)} />
      </ModalWrapper>

      <ModalWrapper
        open={openBloodDonorModal}
        handleClose={() => setOpenBloodDonorModal(false)}
      >
        <BloodDonorModal closeModal={() => setOpenBloodDonorModal(false)} />
      </ModalWrapper>

      <ModalWrapper
        open={openJournalModal}
        handleClose={() => setOpenJournalModal(false)}
      >
        <AddJournalModal closeModal={() => setOpenJournalModal(false)} />
      </ModalWrapper>

      <ModalWrapper
        open={openMedicalRecordModal}
        handleClose={() => setOpenMedicalRecordModal(false)}
      >
        <AddMedicalRecord closeModal={() => setOpenMedicalRecordModal(false)} />
      </ModalWrapper>

      <ModalWrapper
        open={openDewormingRecordModal}
        handleClose={() => setOpenDewormingRecordModal(false)}
      >
        <AddDewormingRecord
          closeModal={() => setOpenDewormingRecordModal(false)}
        />
      </ModalWrapper>

      <ModalWrapper
        open={openVaccinationRecordModal}
        handleClose={() => setOpenVaccinationRecordModal(false)}
      >
        <AddVaccinationRecord
          closeModal={() => setOpenVaccinationRecordModal(false)}
        />
      </ModalWrapper>
    </>
  );
};

export default HealthHub;
