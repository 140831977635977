import { apiSlice } from "../apiSlice";

export const petParentApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["petsetting", "PetParent"],

  endpoints: (builder) => ({
    getAllPets: builder.query({
      query: () => ({
        url: "/api/v1/petparent/pets",
        method: "GET",
      }),
      providesTags: ["petsetting"],
    }),
    registerPet: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/pets",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["petsetting"],
    }),

    getPetDetails: builder.query({
      query: (id) => ({
        url: `/api/v1/petparent/pets/fetchpet/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Pets", id }],
    }),
    updatePet: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/petparent/pets/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["petsetting"],
    }),
    deletePet: builder.mutation({
      query: (id) => ({
        url: `/api/v1/petparent/pets/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["petsetting"],
    }),
    addParentToPet: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/pets/addparenttopet",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Pets"],
    }),
    removePetFromParent: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/pets/removeparentfrompet",
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Pets"],
    }),
  }),
});

export const {
  useRegisterPetMutation,
  useGetAllPetsQuery,
  useGetPetDetailsQuery,
  useUpdatePetMutation,
  useDeletePetMutation,
  useAddParentToPetMutation,
  useRemovePetFromParentMutation,
} = petParentApiSlice;
