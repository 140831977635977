// FormComponents.js
import React from "react";
import { AttachFile } from "@mui/icons-material";
export const TextInput = ({
  label,
  name,
  value,
  onChange,
  type = "text",
  placeholder = "",
  required = false,
}) => (
  <div className="flex flex-col justify-start items-start mb-4 w-7/12">
    <label className="font-poppins font-bold text-xs">{label}</label>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      className="font-poppins p-4 text-xs font-bold w-full bg-white h-7 rounded-[10px] focus:outline-none"
      placeholder={placeholder}
      required={required}
    />
  </div>
);

export const NumberInput = ({
  label,
  name,
  value,
  defaultval,
  widthclass = null,
  onChange,
  type = "text",
  placeholder = "",
  required = false,
  max,
  min,
}) => (
  <div
    className={`flex flex-col justify-start items-start ${widthclass ? widthclass : "w-7/12 mb-4 "}`}
  >
    <label className="font-poppins font-bold text-xs">
      {label} {required && <span className="text-red-500"> *</span>}
    </label>{" "}
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      defaultValue={defaultval}
      max={max || null}
      min={min || null}
      className="font-poppins p-4 text-xs font-bold w-full bg-white h-7 rounded-[10px] focus:outline-none border border-gray-400"
      placeholder={placeholder}
      required={required}
    />
  </div>
);
export const SelectInput = ({ label, name, value, onChange, options }) => (
  <div className="w-1/2 mb-4">
    <label className="font-poppins font-bold text-xs">{label}</label>
    <select
      name={name}
      value={value}
      onChange={onChange}
      className="font-poppins p-[4px] text-black text-xs font-bold w-full bg-white h-7 rounded-[10px] focus:outline-none"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export const FileInput = ({ label, fileInputRef, onChange, fileName }) => (
  <div className="mb-4">
    <label className="font-poppins font-bold text-xs">{label}</label>
    <div className="flex items-center border-[1px] border-borderColor rounded-[4px] p-1">
      <input
        type="file"
        id="photoInput"
        className="hidden"
        accept="image/*"
        ref={fileInputRef}
        onChange={onChange}
      />
      <label
        htmlFor="photoInput"
        className="font-poppins p-1 text-xs font-bold bg-[#fdeedc] text-black rounded-[2px] cursor-pointer"
      >
        <AttachFile style={{ height: "15px", width: "15px" }} /> Select file
      </label>
      <span id="fileName" className="ml-2 text-xs font-poppins text-[#b0b0b0]">
        {fileName ? fileName : "No file selected"}
      </span>
    </div>
  </div>
);