import React from 'react';
import TableCell from "@mui/material/TableCell";

const TableCellComponent = ({ children, align = "center", customStyles = {} }) => {
  return (
    <TableCell
      align={align}
      style={{
        fontFamily: "inherit",
        fontWeight: 500,
        fontSize: "13px",
        ...customStyles,
      }}
    >
      {children}
    </TableCell>
  );
};

export default TableCellComponent;
