import React from 'react';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

const ViewButtonComponent = ({navigationPath}) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(navigationPath);
  };

  return (
    <div
      onClick={handleNavigation}
      className="p-1 h-10 cursor-pointer w-10 flex justify-center items-center rounded-full bg-faintBg"
    >
      <VisibilityIcon className="text-borderColor" />
    </div>
  );
};

export default ViewButtonComponent;
