import { CancelOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Input from "components/FormComponents/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "store/StateSlice/CartSlice";
import { Ri24HoursLine } from "react-icons/ri";
import { TbClockHour3Filled } from "react-icons/tb";
import { TbCalendarTime } from "react-icons/tb";
import Calendar from "react-calendar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GiConsoleController } from "react-icons/gi";
import Select from "react-select";

const getDayNumber = (dayName) => {
  const dayMap = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  return dayMap[dayName] !== undefined ? dayMap[dayName] : null;
};
const timeStringToDate = (timeString) => {
  const [hours, minutes] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0); // Set the hours and minutes, with seconds as 0
  return date;
};
function calculateEndTime(startTime, duration) {
  let [startHour, startMinute] = startTime.split(":").map(Number);
  let durationMinutes = parseInt(duration.replace("min", ""), 10);
  let totalMinutes = startHour * 60 + startMinute + durationMinutes;

  let endHour = Math.floor(totalMinutes / 60) % 24;
  let endMinute = totalMinutes % 60;

  endHour = String(endHour).padStart(2, "0");
  endMinute = String(endMinute).padStart(2, "0");

  return `${endHour}:${endMinute}`;
}

function PriceModelSymblol({ pricemodel, size }) {
  if (pricemodel == "24h") {
    return <Ri24HoursLine size={size} />;
  }
  if (pricemodel == "Hourly") {
    return <TbClockHour3Filled size={size} />;
  }
  if (pricemodel == "Checkin-Checkout") {
    return <TbCalendarTime size={size} />;
  }
}
function convertTo12HourFormat(time) {
  // Split the input time into hours, minutes, and seconds
  let [hours, minutes, seconds] = time.split(":");

  // Convert hours to a number
  hours = parseInt(hours, 10);

  // Determine AM or PM
  let period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Return the formatted time
  return `${hours}:${minutes} ${period}`;
}

const BookingModal = ({ closeModal, servicedata, services }) => {
  const [pricingmodel, setpricingmodel] = useState(
    servicedata?.service_pricings?.length == 1
      ? servicedata?.service_pricings[0]
      : null
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [allpricing, setallpricing] = useState([]);
  const selectedpet = useSelector((state) => state?.petsetting);
  const [extra, setextra] = useState();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      start_time: "",
      pricing_model_id:
        servicedata?.service_pricings?.length == 1
          ? servicedata?.service_pricings[0].id
          : null,
      price_modal: "",
      status: "UPCOMING",
      service_id: servicedata?.id,
      service_user_id: servicedata?.service_user_id,
      pet_ids: selectedpet?.allpets?.map((rec) => rec?.id),
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
      cost: 0,
    },
    validationSchema: Yup.object().shape({
      start_date: Yup.date().required("Start date is required"),
      start_time: Yup.string().when("pricing_model", {
        is: (value) => value !== "Checkin-Checkout",
        then: (schema) => schema.required("Start time is required"),
        otherwise: (schema) => schema.nullable(),
      }),
      end_date: Yup.date()
        .nullable()
        .when("pricing_model", {
          is: (value) => value === "24h" || value === "Hourly",
          then: () => Yup.string().required("End Date is required"),
        }),
      end_time: Yup.string()
        .nullable()
        .when("pricing_model", {
          is: (value) => value === "24h" || value === "Hourly",
          then: () => Yup.string().required("End time is required"),
        }),
      pet_ids: Yup.array().min(1, "At least one pet must be selected"),
    }),
    onSubmit: (values) => {
      try {
        const senddata = values;
        senddata.serviceuser = servicedata;
        senddata.services = services;
        dispatch(addToCart(senddata));
        closeModal();
        toast.success("Profile updated successfully");
      } catch (error) {
        console.error(error);
        toast.error("Failed to update cart");
      }
    },
  });
  const isDateDisabled = (date) => {
    const day = date.getDay();
    const availdays = servicedata?.service_availibilities?.map((rec) =>
      getDayNumber(rec?.day)
    );
    return availdays?.includes(day);
  };
  const calculateHoursDifference = (start, end) => {
    if (start && end) {
      const diff = (end - start) / (1000 * 60 * 60);
      return diff;
    }
    return 0;
  };
  const calculateDaysDifference = (start, end) => {
    if (start && end) {
      const oneDay = 1000 * 60 * 60 * 24;
      const diff = Math.ceil((end - start) / oneDay);
      return diff;
    }
    return 0;
  };
  useEffect(() => {
    let hours = calculateHoursDifference(startDate, endDate);
    let days = calculateDaysDifference(startDate, endDate);
    setallpricing([]);
    servicedata?.service_pricings?.forEach((element) => {
      if (pricingmodel?.price_modal != "Checkin-Checkout") {
        hours = calculateHoursDifference(startDate, endDate);
        days = calculateDaysDifference(startDate, endDate);
        // console.log({
        //   date,
        //   tempenddate,
        //   startDate,
        //   hours,
        //   minutes,
        //   seconds,
        // });
      }

      if (element?.price_modal == "Hourly") {
        setallpricing((prev) => [
          ...prev,
          {
            model: element?.price_modal,
            cost: parseInt(
              hours * element?.price * formik.values.pet_ids.length
            ),
          },
        ]);
      }
      if (element?.price_modal == "24h") {
        setallpricing((prev) => [
          ...prev,
          {
            model: element?.price_modal,
            cost: parseInt(
              days * element?.price * formik.values.pet_ids.length
            ),
          },
        ]);
      }
      if (
        element?.price_modal == "Checkin-Checkout" &&
        formik?.values?.start_date == formik?.values?.end_date
      ) {
        setallpricing((prev) => [
          ...prev,
          {
            model: element?.price_modal,
            cost: parseInt(element?.price) * formik.values.pet_ids.length,
          },
        ]);
      }
      if (element?.price_modal == "Fixed") {
        setallpricing((prev) => [
          ...prev,
          {
            model: element?.price_modal,
            cost: parseInt(element?.price) * formik.values.pet_ids.length,
          },
        ]);
      }
    });
    if (pricingmodel?.price_modal == "Hourly") {
      formik.setFieldValue(
        "cost",
        parseInt(hours * pricingmodel?.price * formik.values.pet_ids.length)
      );
      setextra({ TotalHours: parseInt(hours) });
    }
    if (pricingmodel?.price_modal == "24h") {
      formik.setFieldValue(
        "cost",
        parseInt(days * pricingmodel?.price * formik.values.pet_ids.length)
      );
      setextra({ Days: days, TotalHours: parseInt(hours) });
    }
    if (pricingmodel?.price_modal == "Checkin-Checkout") {
      formik.setFieldValue(
        "cost",
        parseInt(pricingmodel?.price) * formik.values.pet_ids.length
      );
      setextra({
        CheckIn: ` ${convertTo12HourFormat(pricingmodel?.checkin_time)} `,
        CheckOut: ` ${convertTo12HourFormat(pricingmodel?.checkout_time)} `,
      });
    }
    if (pricingmodel?.price_modal == "Fixed") {
      formik.setFieldValue(
        "cost",
        parseInt(pricingmodel?.price) * formik.values.pet_ids.length
      );
    }
  }, [startDate, endDate, formik.values]);
  return (
    <>
      {pricingmodel ? (
        <div className="w-full space-y-4">
          <button
            onClick={closeModal}
            className="bg-faintBg absolute top-4 right-4 cursor-pointer  text-borderColor rounded-full"
          >
            <CancelOutlined />
          </button>
          <div className="text-black inline-flex flex-col justify-center items-center min-w-full  font-poppins text-4xl font-semibold">
            Select Details
            <div className="h-[1px] w-1/4 bg-borderColor mt-1 text-center"></div>
          </div>

          <div className="w-full space-y-4 flex justify-normal items-start flex-wrap">
            {(pricingmodel?.price_modal == "24h" ||
              pricingmodel?.price_modal == "Hourly") && (
                <div className="w-full gap-2 flex  items-center flex-row justify-around">
                  <div className="flex flex-col  ">
                    <label
                      style={{
                        fontSize: `1rem`,
                      }}
                      className="block text-sm font-bold leading-6 text-gray-900 max-md:hidden"
                    >
                      Select Start Date and Time
                    </label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        const datePart = date.toISOString().split("T")[0];
                        const timePart = date
                          .toISOString()
                          .split("T")[1]
                          .split(".")[0];
                        setStartDate(date);
                        formik.setFieldValue("start_date", datePart);
                        formik.setFieldValue("start_time", timePart);

                        if (endDate && date > endDate) {
                          setEndDate(null);
                          formik.setFieldValue("end_date", null);
                        }
                      }}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={60}
                      minTime={timeStringToDate(
                        servicedata?.service_availibilities[0]?.start_time
                      )}
                      maxTime={timeStringToDate(
                        servicedata?.service_availibilities[0]?.end_time
                      )}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      placeholderText="Select date and time"
                      filterDate={isDateDisabled}
                    />
                    <div className="text-red-500">
                      {formik?.errors?.start_date}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label
                      style={{
                        fontSize: `1rem`,
                      }}
                      className="block text-sm font-bold leading-6 text-gray-900 max-md:hidden"
                    >
                      Select End Date and Time
                    </label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => {
                        const datePart = date.toISOString().split("T")[0];
                        const timePart = date
                          .toISOString()
                          .split("T")[1]
                          .split(".")[0];
                        setEndDate(date);
                        formik.setFieldValue("end_date", datePart);
                        formik.setFieldValue("end_time", timePart);
                      }}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={60}
                      minDate={startDate} // Set min date to start date
                      minTime={timeStringToDate(
                        servicedata?.service_availibilities[0]?.start_time
                      )}
                      maxTime={timeStringToDate(
                        servicedata?.service_availibilities[0]?.end_time
                      )}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      placeholderText="Select date and time"
                      filterDate={isDateDisabled}
                    />
                    <div className="text-red-500">
                      {formik?.errors?.end_date}{" "}
                    </div>
                  </div>
                </div>
              )}
            {pricingmodel?.price_modal == "Fixed" && (
              <div className="w-full gap-2 flex  items-start flex-row justify-start">
                <div className="flex flex-col  ">
                  <label
                    style={{
                      fontSize: `1rem`,
                    }}
                    className="block text-sm font-bold leading-6 text-gray-900 max-md:hidden"
                  >
                    Select Start Date and Time
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);

                      const datePart = date.toISOString().split("T")[0];
                      const timePart = date
                        .toISOString()
                        .split("T")[1]
                        .split(".")[0];
                      formik.setFieldValue("start_date", datePart);
                      formik.setFieldValue("start_time", timePart);
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={60}
                    minTime={timeStringToDate(
                      servicedata?.service_availibilities[0]?.start_time
                    )}
                    maxTime={timeStringToDate(
                      servicedata?.service_availibilities[0]?.end_time
                    )}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    placeholderText="Select date and time"
                    filterDate={isDateDisabled}
                  />
                  <div className="text-red-500">
                    {formik?.errors?.start_date}{" "}
                  </div>
                </div>
              </div>
            )}
            {pricingmodel?.price_modal == "Checkin-Checkout" && (
              <div className="w-full gap-2 flex  items-center flex-row justify-start">
                <div className="flex flex-col  w-[50%]">
                  <label
                    style={{
                      fontSize: `1rem`,
                    }}
                    className="block text-sm font-bold leading-6 text-gray-900 max-md:hidden"
                  >
                    Select Start Date and Time
                  </label>
                  <DatePicker
                    className="w-full"
                    selected={startDate}
                    onChange={(date) => {
                      console.log(date);
                      setStartDate(date);
                      const tempenddate = new Date(date.getTime());

                      const newTime = pricingmodel?.checkout_time;
                      const [hours, minutes, seconds] = newTime
                        .split(":")
                        .map(Number);

                      tempenddate.setHours(hours);
                      tempenddate.setMinutes(minutes);
                      tempenddate.setSeconds(seconds);

                      tempenddate.setHours(hours);
                      tempenddate.setMinutes(minutes);
                      tempenddate.setSeconds(seconds);
                      setEndDate(tempenddate);

                      const datePart = date.toISOString().split("T")[0];
                      const timePart = date
                        .toISOString()
                        .split("T")[1]
                        .split(".")[0];
                      formik.setFieldValue("start_date", datePart);
                      formik.setFieldValue("start_time", timePart);
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={60}
                    minTime={timeStringToDate(pricingmodel?.checkin_time)}
                    maxTime={timeStringToDate(pricingmodel?.checkout_time)}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    placeholderText="Select date and time"
                    filterDate={isDateDisabled}
                  />
                  <div className="text-red-500">
                    {formik?.errors?.start_date}{" "}
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-col w-full ">
              <label
                style={{
                  fontSize: `1rem`,
                }}
                className="block text-sm font-bold leading-6 text-gray-900 max-md:hidden"
              >
                Select Pets
              </label>
              <Select
                className="mt-2 w-full"
                placeholder={"select Pets"}
                isMulti={true}
                // value={selectedOption}
                defaultValue={selectedpet?.allpets?.map((rec) => ({
                  value: rec?.id,
                  label: rec?.name,
                }))}
                onChange={(val) => {
                  formik.setFieldValue(
                    "pet_ids",
                    val?.map((rec) => rec?.value)
                  );
                  // setValues({ ...values, services_provided: currentvalues });
                }}
                options={selectedpet?.allpets?.map((rec) => ({
                  value: rec?.id,
                  label: rec?.name,
                }))}
              />
              <div className="text-red-500">{formik?.errors?.pet_ids} </div>
            </div>
            <div className="flex flex-row gap-5 text-orange-500">
              <h1 className="text-[1rem] font-bold text-black bg-orange-100 p-2 rounded-lg">
                Cost:
                <span className="text-orange-500">{formik.values.cost}₹</span>
              </h1>
              {extra &&
                Object?.entries(extra)?.map(([key, value]) => (
                  <h1 className="text-[1rem] font-bold text-black bg-orange-100 p-2 rounded-lg">
                    {key}:<span className="text-orange-500">{value}</span>
                  </h1>
                ))}
            </div>
          </div>
          <div className="flex justify-center gap-x-5 items-center">
            {/* <button
              onClick={formik.handleSubmit}
              type="submit"
              className="flex w-3/12 mt-auto rounded-xl justify-center items-center bg-borderColor py-2 px-4"
            >
              <p className="text-base font-poppins font-normal text-white">
                Book Now
              </p>
            </button> */}

            <button
              onClick={formik.handleSubmit}
              className="flex w-3/12 mt-auto rounded-xl justify-center items-center bg-faintBg py-2 px-4"
            >
              <p className="text-base font-poppins font-normal text-borderColor">
                Add to Cart
              </p>
            </button>
          </div>
          {console.log(
            extra,
            formik.values.cost,
            allpricing,
            allpricing?.filter((rec) => rec?.cost < formik.values.cost),
            allpricing
              ?.filter((rec) => rec?.cost < formik.values.cost)
              .sort((a, b) => a.cost - b.cost)
          )}
          {allpricing?.length > 1 &&
            allpricing?.filter((rec) => rec?.cost < formik.values.cost).length >
            0 &&
            allpricing
              ?.filter((rec) => rec?.cost < formik.values.cost)
              .sort((a, b) => a.cost - b.cost)?.[0]?.model !=
            pricingmodel?.price_modal ? (
            <div className="bg-orange-100 opacity-50 p-2 rounded-lg">
              Note:{" "}
              {`${allpricing?.filter((rec) => rec?.cost < formik.values.cost).sort((a, b) => a.cost - b.cost)[0].model} will cost you 
              ${formik.values.cost - allpricing?.filter((rec) => rec?.cost < formik.values.cost).sort((a, b) => a.cost - b.cost)[0].cost}₹ less `}
            </div>
          ) : (
            ""
          )}
          {servicedata?.exceed_time_charge ? (
            <div className="bg-orange-100 opacity-50 p-2 rounded-lg">
              Note:Exceeding selected time will have extra hour charges(
              {servicedata?.exceed_time_charge}₹ per hour per pet).
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="w-full space-y-4">
          <button
            onClick={closeModal}
            className="bg-faintBg absolute top-4 right-4 cursor-pointer  text-borderColor rounded-full"
          >
            <CancelOutlined />
          </button>
          <div className="text-black inline-flex flex-col justify-center items-center min-w-full  font-poppins text-4xl font-semibold">
            Preferred Booking Method
            <div className="h-[1px] w-1/4 bg-borderColor mt-1 text-center"></div>
          </div>
          <div className="flex flex-wrap items-center justify-around">
            {servicedata?.service_pricings?.map((rec) => (
              <button
                onClick={() => {
                  setpricingmodel(rec);
                  formik.setFieldValue("pricing_model_id", rec?.id);
                  formik.setFieldValue("pricing_model", rec?.price_modal);
                }}
                className="flex flex-col items-center justify-center p-3 hover:text-orange-500 cursor-pointer transition ease-in-out"
              >
                <PriceModelSymblol pricemodel={rec?.price_modal} size={50} />
                <span className="text-[1.2rem] font-bold">
                  {rec?.price_modal}
                </span>
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default BookingModal;