import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CheckBox } from "@mui/icons-material";

const BloodDonorModal = ({ closeModal }) => {
  return (
    <>
      <div className="w-full space-y-8">
        <div className="text-black inline-flex flex-col justify-center items-center min-w-full  font-poppins text-2xl font-bold">
          Sign up as Blood Donor
          <div class="h-[1px] w-1/4 bg-borderColor mt-1 text-center"></div>{" "}
        </div>

        <div className="w-full flex justify-center items-center gap-4 flex-wrap">
          <div className="w-5/12">
            <p className="font-poppins font-bold text-xs ">Last Donated Date</p>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  style={{ border: "none" }}
                  className=" font-poppins px-4 py-0 text-xs font-bold  w-full bg-[#F3F4F6]  rounded-[4px] focus:outline-none"
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className="w-6/12">
            <p className="font-poppins font-bold text-xs ">Blood Type</p>
            <input
              name="firstName"
              type="text"
              className=" font-poppins p-4 text-xs font-normal border-2 border-borderColor  w-full bg-[#F3F4F6]  h-7 rounded-[4px] focus:outline-none"
              placeholder="Enter Blood Type"
              required
            />
          </div>

          <div className="w-6/12">
            <p className="font-poppins font-bold text-xs ">Parvo Survivor</p>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="Yes"
                control={
                  <CheckBox
                    sx={{
                      marginLeft: "10px",
                      color: "#DA9145",
                      "&.Mui-checked": {
                        color: "#DA9145",
                      },
                    }}
                  />
                }
                label="Yes"
              />
              {/* <FormControlLabel
                value="No"
                control={
                  <Radio
                    sx={{
                      color: "#DA9145",
                      "&.Mui-checked": {
                        color: "#DA9145",
                      },
                    }}
                  />
                }
                label="No"
              /> */}
            </RadioGroup>
          </div>

          <div className="w-5/12"></div>
        </div>
        <div className="flex justify-evenly gap-2 items-center">
          <button className="flex w-4/12 bg-borderColor text-white p-2 justify-center items-center flex-shrink-0 rounded-md  ">
            <p className="font-poppins font-medium text-justify text-sm">
              Sign up as Blood Donor
            </p>
          </button>
          <button
            onClick={closeModal}
            className="flex w-4/12 bg-[#FFEEDE] text-borderColor p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md  "
          >
            <p className="font-poppins font-medium text-justify text-sm">
              Cancel
            </p>
          </button>
        </div>
      </div>
    </>
  );
};

export default BloodDonorModal;
