import React from "react";
import BlogImg from "assets/img/BlogImg1.png";
import { ChevronRightIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

const ExploreCards = ({ data }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(data.link);
  };

  return (
    <div className="w-[45%]">
      <img className="w-full rounded-t-xl" src={BlogImg} alt="#img" />
      <div 
        onClick={handleNavigation} 
        className="flex justify-evenly cursor-pointer items-center p-2 bg-white rounded-b-xl"
      >
        <img alt="#" className="h-7 w-7" src={data.icon} />
        <p className="text-center font-poppins text-base font-medium text-borderColor">
          {data.title}
        </p>
        <ChevronRightIcon className="text-borderColor" />
      </div>
    </div>
  );
};

export default ExploreCards;
