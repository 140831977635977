import BoardingIcon from "assets/svg/BoardingIcon.svg";
import VetsIcon from "assets/svg/VetsIcon.svg";
import TrainingIcon from "assets/svg/TrainingIcon.svg";
import PetTaxiIcon from "assets/svg/PetTaxiIcon.svg";
import GroomingIcon from "assets/svg/GroomingIcon.svg";
import DayCareIcon from "assets/svg/DayCareIcon.svg";


const serviceData = [
    { title: "Grooming", img: BoardingIcon },
    { title: "Boarding", img: GroomingIcon },
    { title: "Training", img: TrainingIcon },
    { title: "Day Care", img: DayCareIcon },
    { title: "Pet Taxi", img: PetTaxiIcon },
    { title: "Vets", img: VetsIcon },
  ];

  export { serviceData };