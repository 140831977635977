import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCreateVaccineRecordMutation } from "store/HealthHub/healthHubSlice";
import { AttachFile, CancelOutlined } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/FormComponents/Input";
import toast from "react-hot-toast";
import {
  useGeDewormingDetailsQuery,
  useUpdateDewormingMutation,
  useDeleteDewormingMutation,
} from "store/HealthHub/healthHubSlice";
import Confirm from "components/Common/Modal/Confirm";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
const IndividualDewormingRecord = ({ closeModal }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const selectedpet = useSelector((state) => state?.petsetting?.activepet);
  const [createrecord, createstate] = useUpdateDewormingMutation();
  const [deleterecrod, deletestate] = useDeleteDewormingMutation();

  const { data: dewormingRecord } = useGeDewormingDetailsQuery(id);
  const [myfiles, setfiles] = useState({ doc: null, photo: null });
  const [confirm, setconfirm] = useState(false);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      vaccine_name: "",
      administered_date: null,
      next_dosage_due_date: null,
      consult_doctor: "",
      administration_site: "",
      additional_notes: "",
      pet_id: selectedpet.id,
    },
    validationSchema: Yup.object().shape({
      vaccine_name: Yup.string().required("Vaccine Name is required"),
      administration_type: Yup.string(),
      administered_date: Yup.date()
        .nullable()
        .required("Administered Date is required"),
      next_dosage_due_date: Yup.date().nullable(),
      consult_doctor: Yup.string(),
    }),
    onSubmit: async (values) => {
      try {
        const formDataPayload = new FormData();
        for (const key in values) {
          if (values[key]) formDataPayload.append(key, values[key]);
        }

        await createrecord({
          id: dewormingRecord?.dewormingRecord?.id,
          data: formDataPayload,
        });

        toast.success("Profile updated successfully");
      } catch (error) {
        toast.error("Failed to update profile");
        console.error("Update profile error:", error);
      }
    },
  });
  useEffect(() => {
    formik.setValues({
      vaccine_name: dewormingRecord?.dewormingRecord?.vaccine_name || "",
      administered_date: dewormingRecord?.dewormingRecord?.administered_date
        ? moment(dewormingRecord?.dewormingRecord?.administered_date).format(
            "YYYY-MM-DD"
          )
        : null,

      next_dosage_due_date: dewormingRecord?.dewormingRecord
        ?.next_dosage_due_date
        ? moment(
            dewormingRecord?.dewormingRecord?.next_vaccine_due_date
          ).format("YYYY-MM-DD")
        : null,
      consult_doctor: dewormingRecord?.dewormingRecord?.consult_doctor || "",
      administration_site:
        dewormingRecord?.dewormingRecord?.administration_site || "",
      additional_notes:
        dewormingRecord?.dewormingRecord?.additional_notes || "",
    });
    if (dewormingRecord?.dewormingRecord?.photo_id) {
      setfiles((prev) => {
        return {
          ...prev,
          photo: dewormingRecord?.dewormingRecord?.photo?.url,
        };
      });
    }
    if (dewormingRecord?.dewormingRecord?.document_id) {
      setfiles((prev) => {
        return {
          ...prev,
          doc: dewormingRecord?.dewormingRecord?.document?.url,
        };
      });
    }
  }, [dewormingRecord]);
  const handlePhotoFileChange = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("file", file);
  };

  const handleDocFileChange = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("document", file);
  };
  const handleDelete = () => {
    deleterecrod(id);
    navigate("/home/health-hub/deworming");
  };
  return (
    <>
      <Confirm show={confirm} width={40} onClose={() => setconfirm(false)}>
        <div className="flex flex-col w-full gap-4 items-center justify-center">
          <h2 className="w-full text-center">
            {" "}
            Are you Sure You want to delete?
          </h2>
          <div className="flex flex-row gap-5">
            <button
              onClick={() => setconfirm(false)}
              className="bg-white text-black p-2 pl-5 pr-5 rounded-2xl border border-borderColor"
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="bg-borderColor text-white  p-2 pl-5 pr-5 rounded-2xl"
            >
              Confirm
            </button>
          </div>
        </div>
      </Confirm>
      <div className="w-full  p-5">
        <div
          className="text-black inline-flex flex-col justify-start items-start min-w-full
          font-poppins text-4xl font-semibold"
        >
          Deworming Record
          <div className="h-[1px] w-1/4 bg-borderColor mt-1 text-center"></div>{" "}
        </div>
        <div className="flex justify-end items-center gap-x-2 absolute top-6 right-6 w-full"></div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-between items-start my-5 ">
            <div className="w-full flex justify-center items-center flex-col gap-4 ">
              <div className="w-full justify-between items-start gap-y-2 gap-x-8 flex flex-wrap">
                <span className="w-5/12">
                  <Input
                    label="Vaccine Name"
                    type="option"
                    name="vaccine_name"
                    formik={formik}
                    options={[
                      { id: 1, val: "Option 1" },
                      { id: 2, val: "Option 2" },
                      { id: 3, val: "Option 3" },
                    ]}
                    placeholder="Select an option"
                  />
                </span>
                <span className="w-5/12">
                  <Input
                    label="Administered Date"
                    placeholder="Medicine Name"
                    type="date"
                    name="administered_date"
                    formik={formik}
                  />
                </span>
                <span className="w-5/12">
                  <Input
                    label="Next Vaccine Due "
                    placeholder="Next Vaccine Due"
                    type="date"
                    name="next_dosage_due_date"
                    formik={formik}
                  />
                </span>
                <span className="w-5/12">
                  <Input
                    label="Consult Doctor"
                    type="text"
                    name="consult_doctor"
                    formik={formik}
                  />
                </span>
              </div>
              <div className="w-full justify-start items-start gap-y-4 gap-x-8 flex flex-wrap">
                <div className="text-black w-11/12 font-poppins text-lg font-semibold  px-0 ">
                  Optional fields
                  <div className="h-[1px] w-4/12 bg-borderColor mt-1"></div>{" "}
                </div>

                <div className="w-full justify-between items-start gap-y-4 gap-x-4 flex flex-wrap">
                  <Input
                    label="Administration Site"
                    type="text"
                    name="administration_site"
                    formik={formik}
                  />
                  <span className="w-6/12">
                    <p className="font-poppins font-bold text-xs ">Add photo</p>
                    <div className="flex items-center border-[2px] border-borderColor rounded-[2px] p-1">
                      <input
                        type="file"
                        id="photoInput"
                        className="hidden"
                        accept="image/*"
                        onChange={handlePhotoFileChange}
                      />
                      {myfiles?.photo && (
                        <img
                          src={myfiles?.photo}
                          className="h-[5rem] w-[5rem]"
                        />
                      )}
                      <label
                        htmlFor="photoInput"
                        className="font-poppins p-1 text-xs font-bold bg-[#fdeedc] text-black rounded-[2px] cursor-pointer"
                      >
                        <AttachFile style={{ height: "15px", width: "15px" }} />{" "}
                        Select file
                      </label>
                      <span
                        id="fileName"
                        className="ml-2 text-xs font-poppins text-[#b0b0b0]"
                      >
                        {formik?.values?.file?.name || "No file selected"}
                      </span>
                    </div>
                  </span>

                  <span className="w-6/12">
                    <p className="font-poppins font-bold text-xs ">
                      Add Document
                    </p>
                    <div className="flex items-center border-[2px] border-borderColor rounded-[2px] p-1">
                      <input
                        type="file"
                        id="docInput"
                        className="hidden"
                        accept=".pdf"
                        onChange={handleDocFileChange}
                      />{" "}
                      {myfiles?.doc && (
                        <a
                          target="_blank"
                          href={myfiles?.doc}
                          className="underline bg-blue-100 rounded-lg p-2"
                        >
                          document
                        </a>
                      )}
                      <label
                        htmlFor="docInput"
                        className="font-poppins p-1 text-xs font-bold bg-[#fdeedc] text-black rounded-[2px] cursor-pointer"
                      >
                        <AttachFile style={{ height: "15px", width: "15px" }} />{" "}
                        Select file (.pdf)
                      </label>
                      <span
                        id="docName"
                        className="ml-2 text-xs font-poppins text-[#b0b0b0]"
                      >
                        {formik?.values?.document?.name || "No file selected"}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full justify-start gap-x-5 items-center">
            <button
              type="submit"
              className="flex w-3/12 mt-auto  rounded-[6px]  justify-center items-center bg-borderColor p-2"
            >
              <p className="text-base font-poppins font-normal text-white">
                {createstate.isLoading ? "Saving..." : "Save"}
              </p>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setconfirm(true);
              }}
              className="flex w-3/12 mt-auto rounded-[6px] justify-center items-center bg-red-100 py-2 px-4"
            >
              <p className="text-base font-poppins font-normal text-red-600">
                Delete
              </p>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default IndividualDewormingRecord;
