import React, { useState } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { MdOutlineDashboard } from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import { AiOutlineUser } from "react-icons/ai";
import { FiMessageSquare } from "react-icons/fi";
import { Link, Outlet, useNavigate } from "react-router-dom";
import profileImg from "assets/img/PetImg.jpg";
import PetProfileIcon from "assets/img/PetProfileIcon.png";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import CallCenter from "assets/svg/CallCenter.svg";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FooterV2 from "components/Common/FooterV2";
import Notification from "components/Common/Modal/NotificationModal";
import ModalWrapper from "components/Common/Modal/ModalWrapper";
import { AiOutlineLogout } from "react-icons/ai";
import { LogOut } from "lucide-react";
import { emptycart } from "store/StateSlice/CartSlice";
import { emptypet } from "store/StateSlice/settingSlice";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "store/auth/authSlice";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const SideBar = () => {
  const [notificationModalOpen, setnotificationModalOpen] =
    React.useState(false);
  const handleNotificationModalClose = () => setnotificationModalOpen(false);
  const handleNotificationModalOpen = () => setnotificationModalOpen(true);
  const dispatch = useDispatch();
  const [cartModalOpen, setCartModalOpen] = React.useState(false);
  const handleCartModalClose = () => setCartModalOpen(false);
  const handleCartModalOpen = () => setCartModalOpen(true);
  const menus = [
    { name: "dashboard", link: "/home/dashboard", icon: MdOutlineDashboard },
    { name: "Services", link: "/home/services", icon: AiOutlineUser },
    { name: "Health Hub", link: "/home/health-hub", icon: FiMessageSquare },
    { name: "Setting", link: "/home/settings", icon: RiSettings4Line },
  ];
  const cartstate = useSelector((state) => state?.cart);
  const [open, setOpen] = useState(true);

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/home/cart");
  };
  const handleLogout = () => {
    dispatch(emptycart());
    dispatch(emptypet());
    dispatch(logout());
    setTimeout(() => {
      navigate("/login");
    }, 0);
  };
  return (
    <>
      <section className="flex gap-3 min-h-screen">
        <div
          className={`bg-white min-h-screen ${open ? "w-72" : "w-16"
            }  text-[#4F5B67] px-4`}
        >
          <div className="py-6 flex justify-end">
            <HiMenuAlt3
              size={26}
              className="cursor-pointer"
              onClick={() => setOpen(!open)}
            />
          </div>
          <div className="mt-4 flex flex-col gap-4 relative">
            {menus?.map((menu, i) => (
              <Link
                to={menu?.link}
                key={i}
                className={` ${menu?.margin && "mt-5"
                  } group flex items-center text-sm  gap-3.5 font-medium p-2 hover:bg-borderColor hover:text-white rounded-md`}
              >
                <div>
                  <menu.icon className="w-[20px] h-[20px]" />
                </div>
                <h2
                  className={`whitespace-pre  font-poppins text-lg font-light ${!open && " overflow-hidden"
                    }`}
                >
                  {menu?.name}
                </h2>
                <h2
                  className={`${open && "hidden"
                    } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14  group-hover:w-fit  `}
                >
                  {menu?.name}
                </h2>
              </Link>
            ))}
            <Link
              className={`mt-5 group flex items-center text-sm  gap-3.5 font-medium p-2 hover:bg-borderColor hover:text-white rounded-md`}
            >
              <div>
                <img
                  src={profileImg}
                  className="w-[20px] h-[20px] rounded-full hover:border-white hover:border-2 min-w-5 "
                />
              </div>
              <h2
                className={`whitespace-pre  font-poppins text-lg font-light ${!open && " overflow-hidden"
                  }`}
              >
                Profile
              </h2>
              <h2
                className={`${open && "hidden"
                  } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14  group-hover:w-fit  `}
              >
                Profile
              </h2>
            </Link>
          </div>
        </div>
        <div className="w-full text-xl text-gray-900 bg-faintBg font-semibold">
          <div className="flex justify-between items-center px-8 py-4 gap-4">
            <img src={PetProfileIcon} className=" h-10 w-36 " alt="logo" />
            <div className="flex justify-normal items-center gap-4">
              <div
                onClick={handleNotificationModalOpen}
                className="w-[56px] h-[56px] bg-white flex justify-center cursor-pointer items-center rounded-xl"
              >
                <NotificationsNoneOutlinedIcon className="text-borderColor  " />
              </div>
              <div
                onClick={handleNavigation}
                className="w-[56px] cursor-pointer h-[56px] bg-white flex justify-center items-center rounded-xl relative"
              >
                <ShoppingCartOutlinedIcon className="text-borderColor  " />
                {cartstate?.cartitems?.length > 0 && (
                  <span className="absolute top-0 right-0 text-white bg-borderColor rounded-full p-1 text-[1rem] w-[1.5rem] h-[1.5rem] text-center flex items-center justify-center">
                    {cartstate?.cartitems?.length}
                  </span>
                )}
              </div>
              {/* <div className="w-[56px] h-[56px]  bg-white flex justify-center items-center rounded-xl">
                <img src={CallCenter} alt="call-center" />
              </div> */}
              <button
                onClick={() => handleLogout()}
                className="w-[56px] h-[56px]  bg-white flex justify-center items-center rounded-xl cursor-pointer"
              >
                <AiOutlineLogout className="text-red-500" size={25} />
              </button>
            </div>
          </div>
          <div>
            <Outlet />
          </div>
        </div>

        <ModalWrapper
          p={2}
          width={600}
          open={notificationModalOpen}
          handleClose={() => setnotificationModalOpen(false)}
        >
          <Notification closeModal={() => setnotificationModalOpen(false)} />
        </ModalWrapper>
      </section>
      <FooterV2 />
    </>
  );
};

export default SideBar;
