import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginLogo from "assets/img/LoginLogo.png";
import google from "assets/img/google.png";
import facebook from "assets/img/facebook.png";
import apple from "assets/img/apple.png";
import Video from "assets/video/Video.mp4";
import OtpInput from "otp-input-react";
import { auth } from "config/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import timerSvg from "assets/svg/timerSvg.svg";
import stopwatch from "assets/svg/stopwatch.svg";
import { useFormik } from "formik";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "store/toaster";
// import { userUpdate } from "redux/user";
import {
  useSendLoginOtpMutation,
  useLoginMutation,
  useCheckUserMutation,
} from "store/auth/authApiSlice";

import { setCredentials } from "store/auth/authSlice";
import toast from "react-hot-toast";

const buttonStates = {
  init: {
    text: "Submit",
    disabled: false,
  },
  verifyNumber: {
    text: "Verifying Number",
    disabled: true,
  },
  sendOtp: {
    text: "Sending OTP",
    disabled: true,
  },
  askOtp: {
    text: "Login",
    disabled: false,
  },
  verifyOtp: {
    text: "Verifying OTP",
    disabled: true,
  },
  successOtp: {
    text: "Verified, redirecting shortly",
    disabled: false,
  },
};

const initialState = {
  emailOrPhone: "",
  otp: "",
};

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneNo, setPhoneNo] = useState("");
  const [otp, setOtp] = useState("");
  const [buttonState, setButtonState] = useState(buttonStates["init"]);
  const [resendTimer, setResendTimer] = useState(0);
  const [showOtp, setShowOtp] = useState(false);
  const [phoneOtpLoading, setPhoneOtpLoading] = useState(false);

  const [sendOtp, { isLoading, isError, isSuccess }] =
    useSendLoginOtpMutation();
  const [
    login,
    {
      isLoading: loginLoading,
      isError: isLoginErr,
      isSuccess: isLoginSuccess,
      data: loginData,
    },
  ] = useLoginMutation();

  const [
    checkUser,
    {
      isSuccess: checkUserSuccess,
      isError: isCheckUserError,
      error: checkUserError,
    },
  ] = useCheckUserMutation();

  const { token } = useSelector((state) => state?.auth);

  useEffect(() => {
    if (isCheckUserError) {
      toast.error(checkUserError?.data?.message || "User not found!");
    }

    if (checkUserSuccess) {
      onSignup();
    }
  }, [isCheckUserError, checkUserError, checkUserSuccess]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("OTP sent successfully");
      setShowOtp(true);
    }

    if (isError) {
      toast.error("Error sending OTP");
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (isLoginSuccess) {
      dispatch(
        setCredentials({ token: loginData.token, user: loginData.petParent })
      );
      toast.success("Login successfull!");
      navigate("/profile-creation");
    }

    if (isLoginErr) {
      toast.error("Login Failed!");
    }
  }, [isLoginSuccess, isLoginErr, loginData, dispatch, navigate]);

  useEffect(() => {
    if (token) navigate("/profile-creation");
  }, [token, navigate]);

  const { handleChange, values, handleSubmit } = useFormik({
    initialValues: initialState,
    onSubmit: (values) => {
      if (values.emailOrPhone.includes("@")) {
        login({ email: values.emailOrPhone, otp: otp });
      } else {
        onOTPVerify(values.otp).then(() => {
          login({ phone: `91${values.emailOrPhone}`, otp: otp });
        });
      }
    },
  });

  const handleResendOtp = async () => {
    setButtonState(buttonStates["sendOtp"]);
    setResendTimer(60);
    try {
      await signInWithPhoneNumber(
        auth,
        "+91" + phoneNo,
        window.recaptchaVerifier
      );
      dispatch(
        showToast({
          message: "OTP resent successfully!",
        })
      );
    } catch (error) {
      console.error("Error resending OTP:", error);
      dispatch(
        showToast({
          message: "Error resending OTP",
          type: "error",
        })
      );
    }
  };

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log(response);
            //onSignup();
          },
          "expired-callback": () => { },
        }
      );
    }
  }

  function onSignup() {
    //setLoading(true);
    setPhoneOtpLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+91" + values.emailOrPhone;
    console.log("formatPh", formatPh);

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setPhoneOtpLoading(false);
        toast.success("OTP sended successfully!");
        setShowOtp(true);
      })
      .catch((error) => {
        console.log(error);
        setPhoneOtpLoading(false);
      });
  }

  const onOTPVerify = async (otp) => {
    //setLoading(true);
    try {
      const result = await window.confirmationResult.confirm(otp);

      return result;
    } catch (error) {
      console.log(error);
      toast.error("Invalid OTP");
    }
  };

  return (
    <div className="flex justify-normal items-center h-screen relative">
      <div className="absolute inset-0 flex items-center justify-center">
        <video
          className="videoTag w-full h-full object-cover xl:h-max"
          autoPlay
          loop
          muted
        >
          <source src={Video} type="video/mp4" />
        </video>
      </div>
      <div id="recaptcha-container"></div>
      <div className="flex space-y-5 flex-col ml-[15%] max-w-[370px] px-4 py-4 rounded-3xl border-2 border-borderColor backdrop-blur-LoginBlur relative z-10 bg-LoginCard">
        <div className="justify-center flex">
          <img
            id="ref"
            src={LoginLogo}
            className="w-[160px] h-[40px] shrink-0"
          />
        </div>
        <div className="flex flex-col gap-[10px]">
          <div>
            <p className=" font-poppins text-[26px] font-bold leading-8 tracking-wide">
              Welcome Back
            </p>
            <p className="text-black font-poppins text-xs font-medium leading-8 tracking-tight">
              Enter the details below to Log In
            </p>
            <div class="h-[1px] w-2/4 bg-borderColor"></div>
          </div>
          <form onSubmit={handleSubmit} className="flex flex-col space-y-3">
            <div className="flex flex-col ">
              <p className="font-poppins font-bold text-[14px] ">
                Email/Phone Number
              </p>
              <input
                type="text"
                className="border-borderColor font-poppins rounded-[9px] border-t border-b border-l  bg-white h-[40px] px-3 text-xs focus:outline-none"
                placeholder="Enter Your Email/Phone Number"
                value={values.emailOrPhone}
                onChange={handleChange}
                name="emailOrPhone"
              />
            </div>
            {!showOtp && (
              <button
                id="send-otp-button"
                className={`bg-borderColor font-poppins flex justify-center items-center py-[10px]  w-auto rounded-[9px] text-white font-medium text-[18px] leading-40 tracking-wider ${buttonState.disabled
                    ? "cursor-not-allowed"
                    : "hover:cursor-pointer"
                  }`}
                onClick={() => {
                  const { emailOrPhone } = values;
                  if (emailOrPhone.includes("@")) {
                    // Email
                    sendOtp({ email: emailOrPhone });
                  } else {
                    // phone otp
                    checkUser(new URLSearchParams({ phone: emailOrPhone }));
                    //onSignup();
                  }
                }}
                type="button"
              >
                {isLoading || phoneOtpLoading ? "Sending..." : "Send OTP"}
              </button>
            )}

            {showOtp && (
              <div className="flex flex-col gap-5 ">
                <div className="flex justify-between items-baseline">
                  <OtpInput
                    otpType="number"
                    value={otp}
                    onChange={(value) => {
                      if (value.toString().length == 6) {
                      }
                      setOtp(value);
                    }}
                    OTPLength={6}
                    inputStyles={{
                      width: "36px",
                      height: "36px",
                      border: "none",
                      backgroundColor: "#fff",
                      borderRadius: "4px",
                      padding: "6px",
                      marginRight: "8px",
                      fontSize: "16px",
                    }}
                    separator={<span style={{ width: "5px" }}></span>}
                  />
                  {resendTimer > 0 && (
                    <div className="relative">
                      <img
                        src={timerSvg}
                        style={{ width: "60px", height: "50px" }}
                      />
                      <img
                        src={stopwatch}
                        className="absolute top-5"
                        style={{ width: "60px", height: "50px" }}
                      />
                      <span className="absolute top-[85%] left-1/2  transform -translate-x-1/2 -translate-y-1/2">
                        {resendTimer}
                      </span>
                    </div>
                  )}
                </div>
                <div
                  onClick={handleResendOtp}
                  className="text-gray-900 cursor-pointer font-poppins text-base font-normal leading-40 tracking-wider underline"
                >
                  Resend OTP?
                </div>

                <button
                  id="send-otp-button"
                  className={`bg-borderColor font-poppins hover:cursor-pointer flex justify-center items-center py-[10px]  w-auto rounded-[9px] text-white font-medium text-[18px] leading-40 tracking-wider`}
                  type="submit"
                >
                  {loginLoading ? "Submitting..." : "Login"}
                </button>
              </div>
            )}
          </form>

          <div className="flex justify-evenly items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="134"
              height="2"
              viewBox="0 0 134 2"
              fill="none"
            >
              <path d="M0 1H133.5" stroke="white" />
            </svg>
            <span className="font-bold">OR</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="134"
              height="2"
              viewBox="0 0 134 2"
              fill="none"
            >
              <path d="M0 1H133.5" stroke="white" />
            </svg>
          </div>

          <div className="flex justify-evenly items-center">
            <img
              className="w-[43px] h-[43px] shrink-0 cursor-pointer bg-[#FFEEDE] rounded-[14px] p-2"
              src={google}
            />
            <img
              className="w-[43px] h-[43px] shrink-0 cursor-pointer bg-[#FFEEDE] rounded-[14px] p-2"
              src={apple}
            />
            <img
              className="w-[43px] h-[43px] shrink-0 cursor-pointer bg-[#FFEEDE] rounded-[14px] p-2"
              src={facebook}
            />
          </div>
          <p className="text-center text-gray-900 font-poppins text-lg font-medium leading-10 tracking-wider">
            <span className="font-medium font-poppins">New here?</span>{" "}
            <span
              onClick={() => navigate("/sign-up")}
              className="cursor-pointer font-poppins text-[#FFB163] font-bold underline"
            >
              Register Now
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
