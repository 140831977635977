import React from "react";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import {
  useUploadFileMutation,
  useDeleteFileMutation,
} from "store/features/files_upload/filesUploadApiSlice";
import CloseIcon from "@mui/icons-material/Close";
import { TiAttachment } from "react-icons/ti";
import { AiOutlineDelete } from "react-icons/ai";
function AddNote({ rec, index, formik }) {
  const [uploadFiles, { isError, isSuccess, isLoading, data }] =
    useUploadFileMutation();

  const [
    deleteFile,
    {
      isError: deleteError,
      isSuccess: deleteSuccess,
      isLoading: deleteLoading,
      data: deleteData,
    },
  ] = useDeleteFileMutation();

  const [temp, settemp] = useState(
    formik.values.notes.filter((note, tempindex) => {
      if (tempindex == index) {
        return note;
      }
    })[0]
  );
  useEffect(() => {
    settemp(
      formik.values.notes.filter((note, tempindex) => {
        if (tempindex == index) {
          return note;
        }
      })[0]
    );
  }, [temp]);
  const handleFileSelect = async (event, myindex) => {
    // setCurrentTitle(title);

    const files = event?.target?.files;
    const formData = new FormData();
    Array.from(files)?.forEach((file) => formData.append("files", file));
    await uploadFiles(formData)
      .then((resp) => {
        formik.setFieldValue(
          "notes",
          formik.values.notes.map((note, tempindex) => {
            if (tempindex === myindex) {
              return {
                ...note,
                note_pic: resp?.data?.data[0]?.id,
                link: resp?.data?.data[0]?.url,
              };
            }
            return note;
          })
        );
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      });
  };

  return (
    <div className="flex flex-row gap-5 w-full justify-between items-start">
      {" "}
      <div className={`flex flex-col w-full`}>
        <label className="text-gray-700 font-bold">Add Photo</label>
        <div className="flex items-center justify-start gap-8 w-[90%] md:w-full p-1 mt-1 rounded border border-orange-400">
          <label
            htmlFor={`fileInput-${index}`}
            className="w-32 h-8 px-4 py-4 bg-orange-100 flex items-center justify-center rounded cursor-pointer"
          >
            <TiAttachment className="" />
            <span className="text-black font-semibold text-xs">
              Select file
            </span>
          </label>
          <input
            type="file"
            id={`fileInput-${index}`}
            className="hidden"
            onChange={(event) => handleFileSelect(event, index)}
          />
        </div>
        <div className="flex flex-wrap gap-2 mt-2">
          {rec?.note_pic ? (
            <div className="flex flex-row justify-between items-center px-1 py-[4px] rounded-lg text-sm bg-orangeBg/10 text-orangeBg">
              <a
                href={rec?.link}
                className="underline"
                target="_blank"
                rel="noreferrer"
              >
                {rec?.note_pic}
              </a>
              <CloseIcon
                style={{ color: "red", height: 15, width: 15 }}
                className="cursor-pointer"
                onClick={async () => {
                  await deleteFile(new URLSearchParams({ id: rec?.note_pic }))
                    .then((resp) => {
                      if (resp.data?.success) {
                        toast.success("removed file");
                        formik.setFieldValue(
                          "notes",
                          formik.values.notes.map((note, tempindex) => {
                            if (tempindex === index) {
                              return {
                                ...note,
                                note_pic: null,
                                link: null,
                              };
                            }
                            return note;
                          })
                        );
                      }
                    })
                    .catch((error) => {
                      console.error("Error uploading files:", error);
                    });
                }}
              />
            </div>
          ) : (
            <div className="text-gray-400 text-sm">No files uploaded</div>
          )}
        </div>
      </div>
      <span className="w-full">
        <label className="text-gray-700 font-bold">Additional Note</label>
        <textarea
          value={
            formik.values.notes.filter((note, tempindex) => {
              if (tempindex == index) {
                return note;
              }
            })[0].content
          }
          onChange={(e) => {
            formik.setFieldValue(
              "notes",
              formik.values.notes.map((note, tempindex) => {
                if (tempindex === index) {
                  return { ...note, content: e.target.value };
                }
                return note;
              })
            );
          }}
          placeholder="Additional Info"
          className={`block w-full rounded-md border-0 py-1.5 p-2 text-gray-900 
                shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none
                focus:ring-1 focus:ring-inset focus:ring-borderColor sm:text-sm sm:leading-6
               `}
        />
      </span>{" "}
      <div className=" flex items-center h-[7rem]">
        <AiOutlineDelete
          size={20}
          className="text-red-500"
          onClick={() =>
            formik.setFieldValue(
              "notes",
              formik.values.notes.filter((note, tempindex) => {
                if (tempindex !== index) {
                  return note;
                }
              })
            )
          }
        />
      </div>
    </div>
  );
}
function AddMoreNote({ formik }) {
  const notes = formik?.values?.notes;
  const lastNote = notes[notes?.length - 1];
  const lastPhotoId = lastNote?.note_pic;
  const lastAdditionalNote = lastNote?.content;
  const noNotes = notes?.length === 0;

  return (
    <>
      {lastPhotoId || lastAdditionalNote || noNotes ? (
        <button
          onClick={(e) => {
            e.preventDefault();
            formik.setFieldValue("notes", [
              ...notes,
              { photoid: null, content: null },
            ]);
          }}
          className="flex bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md"
        >
          <p className="font-poppins font-medium text-justify text-xs">
            Add More Notes
          </p>
        </button>
      ) : null}
    </>
  );
}

export { AddNote, AddMoreNote };
