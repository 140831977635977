import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
function convertTo12HourFormat(time) {
  // Split the input time into hours, minutes, and seconds
  if (!time) return;
  let [hours, minutes, seconds] = time?.split(":");

  // Convert hours to a number
  hours = parseInt(hours, 10);

  // Determine AM or PM
  let period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Return the formatted time
  return `${hours}:${minutes} ${period}`;
}
const AppointmentsTable = ({ appointment }) => {
  let data = appointment?.data;
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/home/appointments/individual");
  };
  console.log({ appointment });
  const TableCellCommonStyle = {
    fontFamily: "inherit",
    fontWeight: "bold",
    fontSize: "13px",
  };

  const TableRowCellStyle = {
    fontFamily: "inherit",
    fontWeight: 500,
    fontSize: "13px",
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={TableCellCommonStyle}>
              Date
            </TableCell>
            <TableCell align="center" style={TableCellCommonStyle}>
              Pet Name
            </TableCell>
            <TableCell align="center" style={TableCellCommonStyle}>
              Service
            </TableCell>
            <TableCell align="center" style={TableCellCommonStyle}>
              Provider
            </TableCell>
            <TableCell align="center" style={TableCellCommonStyle}>
              Time
            </TableCell>
            <TableCell align="center" style={TableCellCommonStyle}>
              Location
            </TableCell>
            <TableCell align="center" style={TableCellCommonStyle}>
              Status
            </TableCell>
            {/* <TableCell align="center" style={TableCellCommonStyle}>
              View
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((rec) =>
            rec?.service_appointment_bookings?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" style={TableRowCellStyle}>
                  <p
                    className="bg-faintBg rounded-xl font-bold text-borderColor text-xs flex justify-evenly items-center"
                    style={{
                      borderRadius: "5px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    {new Date(row?.start_date).toLocaleDateString("en-GB")}
                    <span className="bg-faintBg cursor-pointer  text-borderColor rounded-full flex items-center justify-center">
                      <BorderColorOutlinedIcon style={{ fontSize: "1rem" }} />
                    </span>
                  </p>
                </TableCell>
                <TableCell align="center" style={TableRowCellStyle}>
                  {row.service_appointment_pets?.map(
                    (pet) => `${pet?.pet?.name},`
                  )}
                </TableCell>
                <TableCell align="center" style={TableRowCellStyle}>
                  {row?.service?.service_name}
                </TableCell>
                <TableCell align="center" style={TableRowCellStyle}>
                  {row?.service_user?.service_name}
                </TableCell>
                <TableCell align="center" style={TableRowCellStyle}>
                  <p
                    className="bg-faintBg rounded-xl font-bold text-borderColor text-xs flex justify-evenly items-center"
                    style={{
                      borderRadius: "5px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    {convertTo12HourFormat(row?.start_date)}
                    <span className="bg-faintBg cursor-pointer  text-borderColor rounded-full flex items-center justify-center">
                      <BorderColorOutlinedIcon style={{ fontSize: "1rem" }} />
                    </span>
                  </p>
                </TableCell>
                <TableCell align="center" style={TableRowCellStyle}>
                  {row?.service_user?.city}
                </TableCell>
                <TableCell align="center" style={TableRowCellStyle}>
                  <p
                    className={` ${row.status === "Processing" ? "bg-status-processing text-status-processing" : row.status === "Completed" ? "bg-status-completed text-status-completed" : "bg-status-rejected text-status-rejected"}`}
                    style={{
                      borderRadius: "5px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    {row.status}
                  </p>
                </TableCell>
                <TableCell align="center" style={TableRowCellStyle}>
                  {/* <span className="inline-flex justify-start items-center gap-x-2">
                    <span
                      // onClick={handleNavigation}
                      style={{ width: "30px", height: "30px" }}
                      className="bg-faintBg cursor-pointer text-borderColor rounded-full flex items-center justify-center"
                    >
                      <VisibilityIcon />
                    </span>
                    <span
                      style={{ width: "30px", height: "30px" }}
                      className="bg-faintBg cursor-pointer text-borderColor rounded-full flex items-center justify-center"
                    >
                      <DeleteOutlinedIcon />
                    </span>
                  </span> */}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AppointmentsTable;
