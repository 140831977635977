import React, { useState } from "react";
import checkImg from "assets/img/checkImg.png";
import BlogImg from "assets/img/BlogImg1.png";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import { CancelOutlined } from "@mui/icons-material";

const OrderSummary = ({ closeModal }) => {
  const [count, setCount] = useState(0);

  return (
    <div className="flex  justify-normal gap-x-5 items-center">
      <button
        onClick={closeModal}
        className="bg-faintBg absolute top-2 right-2 cursor-pointer  text-borderColor rounded-full">
        <CancelOutlined />
      </button>

      <div className="w-5/12 flex flex-col justify-center items-center gap-[20px]">
        <div>
          <img src={checkImg} alt="img" className="w-[60px] h-[60px]" />
        </div>
        <div className="flex-col  gap-[20px] flex justify-center text-center">
          <div className="font-poppins font-semibold text-xl text-center">
            Thank You for booking
          </div>
          <div className="font-poppins w-full font-medium text-sm text-[#878787] text-center">
            "Thank you for choosing us! Your booking is confirmed, and we're
            excited to host you."
          </div>
        </div>
        <div className="flex justify-center gap-5 w-full">
          <button className="flex w-fit mt-auto rounded-[8px] justify-center items-center bg-borderColor px-4 py-2">
            <p className="text-sm font-poppins font-normal text-white">
              Explore More Services
            </p>
          </button>
          <button className="flex w-fit mt-auto rounded-[8px] justify-center items-center bg-faintBg px-4 py-2">
            <p className="text-sm font-poppins font-normal text-borderColor">
              View Booking
            </p>
          </button>
        </div>
      </div>

      <div className="w-7/12 flex flex-col justify-normal gap-4 items-start p-4 border-[2px] rounded-xl border-borderColor">
        <span className="flex gap-5">
          <div className="w-2/4 flex justify-normal items-start flex-col">
            <div className="text-black font-poppins text-xl font-semibold  px-0 ">
              Pet Cleaning Services
              <div class="h-1 w-4/12 bg-borderColor mt-1"></div>
            </div>
            {[1].map(() => (
              <>
                <div className="bg-white flex justify-center  py-4 rounded-xl gap-x-4">
                  <div className="w-5/12 relative">
                    <img
                      src={BlogImg}
                      alt="#"
                      className="w-full object-cover h-full  rounded-xl"
                    />
                  </div>
                  <div className="w-7/12 space-y-3 relative">
                    <div className="inline-flex gap-2">
                      <p className="text-black text-lg font-semibold font-poppins">
                        Grooming
                      </p>
                      <span className=" cursor-pointer h-4 w-4 font-medium text-borderColor">
                        <BorderColorOutlinedIcon />
                      </span>
                    </div>

                    <div className="">
                      <p className="text-[#323743] font-medium font-poppins text-sm">
                        <AccessTimeOutlinedIcon
                          style={{ height: "20px", width: "20px" }}
                        />{" "}
                        10:00 - 11:00 AM
                      </p>
                      <p className="text-[#323743] font-medium font-poppins text-sm">
                        <EventAvailableOutlinedIcon
                          style={{ height: "20px", width: "20px" }}
                        />{" "}
                        2 April - 4 July
                      </p>
                    </div>

                    <div className="flex flex-wrap justify-start gap-1 items-start">
                      {[1, 2].map(() => (
                        <>
                          <p className="text-sm w-fit bg-faintBg text-borderColor rounded-xl py-1 px-2 font-poppins font-normal">
                            Bath
                          </p>
                        </>
                      ))}
                    </div>

                    <div className=" bg-faintBg w-fit text-borderColor rounded-[10px] py-1 px-4 flex items-center space-x-4">
                      <button
                        onClick={() => setCount(count + 1)}
                        className="text-xl w-fit  font-poppins font-normal"
                      >
                        +
                      </button>
                      <p className="text-xl w-fit font-poppins font-normal">
                        {count}
                      </p>
                      <button
                        onClick={() => setCount(count - 1)}
                        className="text-xl w-fit  font-poppins font-normal"
                      >
                        -
                      </button>
                    </div>

                    <p className="text-black absolute bottom-0 right-0 font-poppins font-semibold text-base">
                      ₹399
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="w-2/4 flex justify-normal items-start flex-col">
            <div className="text-black font-poppins text-xl font-semibold  px-0 ">
              Service Provider
              <div class="h-1 w-4/12 bg-borderColor mt-1"></div>
            </div>
            {[1].map(() => (
              <>
                <div className="bg-white flex justify-center  py-4 rounded-xl gap-x-4">
                  <div className="w-5/12 relative">
                    <img
                      src={BlogImg}
                      alt="#"
                      className="w-full object-cover h-full  rounded-xl"
                    />
                  </div>
                  <div className="w-7/12 space-y-3 relative">
                    <div className="inline-flex gap-2">
                      <p className="text-black text-lg font-semibold font-poppins">
                        Grooming
                      </p>
                      <span className=" cursor-pointer h-4 w-4 font-medium text-borderColor">
                        <BorderColorOutlinedIcon />
                      </span>
                    </div>

                    <div className="">
                      <p className="text-[#323743] font-medium font-poppins text-sm">
                        <AccessTimeOutlinedIcon
                          style={{ height: "20px", width: "20px" }}
                        />{" "}
                        12:00 - 04:00 AM
                      </p>
                      <p className="text-[#323743] font-medium font-poppins text-sm">
                        <EventAvailableOutlinedIcon
                          style={{ height: "20px", width: "20px" }}
                        />{" "}
                        2 Dec - 4 Jan
                      </p>

                      <p className="text-[#323743] font-medium font-poppins text-sm">
                        <ChairOutlinedIcon
                          style={{ height: "20px", width: "20px" }}
                        />{" "}
                        AC Room
                      </p>
                    </div>

                    <div className=" bg-faintBg w-fit text-borderColor rounded-[10px] py-1 px-4 flex items-center space-x-4">
                      <button
                        onClick={() => setCount(count + 1)}
                        className="text-xl w-fit  font-poppins font-normal"
                      >
                        +
                      </button>
                      <p className="text-xl w-fit font-poppins font-normal">
                        {count}
                      </p>
                      <button
                        onClick={() => setCount(count - 1)}
                        className="text-xl w-fit  font-poppins font-normal"
                      >
                        -
                      </button>
                    </div>

                    <p className="text-black absolute bottom-0 right-0 font-poppins font-semibold text-base">
                      ₹999
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </span>
        <div className="text-[#35A55B] text-base font-semibold w-full flex justify-between  items-center font-poppins">
          <p>Order Total</p> <p>₹301</p>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
