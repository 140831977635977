import Tabs from "components/Common/Tabs";
import React from "react";

const Settings = () => {
  return (
    <div className="w-full">
      <div className="w-full bg-cardBg xl:py-10 space-y-5 py-10 px-8 overflow-hidden">
        <div className="px-8 xl:px-0 flex flex-col justify-start items-start">
          <div className="text-black font-poppins text-4xl font-semibold  px-0 ">
            Settings
          </div>
        </div>
        <Tabs />
      </div>
    </div>
  );
};

export default Settings;
