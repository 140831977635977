import React, { useState } from "react";
import Pet from "assets/img/Pet.png";
import bone from "assets/img/bone.png";
import { Tilt } from "react-tilt";
import BlogCard from "components/cards/BlogCard";
import ExploreCards from "./cards/ExploreCards";
import HealthLogo from "assets/svg/HealthLogo1.svg";
import Serviceicon from "assets/svg/Serviceicon.svg";
import AppointmentCard from "./cards/AppointmentCard";
import Appointement from "assets/svg/Appointement.svg";
import Explore from "assets/svg/Explore.svg";
import SwitchPetModal from "components/Common/Modal/SwitchPetModal";
import EditProfilePicModal from "components/Common/Modal/EditProfilePicModal";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { serviceData } from "helper/constants/ServiceData";
import ServiceCardV2 from "./cards/ServiceCardV2";
import AddTags from "components/Common/Modal/AddTags";
import { useNavigate } from "react-router-dom";
import ModalWrapper from "components/Common/Modal/ModalWrapper";
import { useSelector, useDispatch } from "react-redux";
import { SiDatadog } from "react-icons/si";
import { useGetpetAppointmentsQuery } from "store/Services/serviceApiSlice";
const option = [
  { icon: HealthLogo, title: "Your Booking", link: "/home/appointements" },
  { icon: HealthLogo, title: "Health Hub", link: "/home/health-hub" },
  { icon: Serviceicon, title: "Services", link: "/home/services" },
];

const defaultOptions = {
  reverse: false, // reverse the tilt direction
  max: 35, // max tilt rotation (degrees)
  perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
  scale: 1.0, // 2 = 200%, 1.5 = 150%, etc..
  speed: 800, // Speed of the enter/exit transition
  transition: true, // Set a transition on enter/exit.
  axis: null, // What axis should be disabled. Can be X or Y.
  reset: true, // If the tilt effect has to be reset on exit.
  easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [AddTagModal, setAddTagModal] = useState(false);
  const [openEdit, setopenEdit] = React.useState(false);

  const handleNavigation = () => {
    navigate("/home/appointements");
  };

  const selectedpet = useSelector((state) => state?.petsetting);
  const { data: appintmentdata } = useGetpetAppointmentsQuery();
  console.log(selectedpet, appintmentdata, "appintmentdata");
  const [emptyPetProfile, setemptyPetProfile] = useState(
    selectedpet?.activepet ? true : false
  );
  console.log({ appintmentdata });
  return (
    <>
      <div
        style={{ alignItems: "center" }}
        className="flex gap-x-8 justify-normal p-8"
      >
        <div className="w-[25%] h-full">
          <div className="w-full flex flex-col gap-8  justify-between items-center">
            {emptyPetProfile ? (
              <span className="relative">
                <div className="flex w-fit justify-center overflow-hidden items-center bg-borderColor px-5 pt-8 rounded-t-full rounded-b-full ">
                  {selectedpet?.activepet?.file ? (
                    <img
                      src={selectedpet?.activepet?.file?.url || Pet}
                      alt="Pet"
                      className="rounded-t-full rounded-b-full w-44"
                    />
                  ) : (
                    <SiDatadog size={150} />
                  )}
                </div>
              </span>
            ) : (
              <>
                <div className="flex border-4 border-dashed border-borderColor w-60 h-80 justify-center overflow-hidden items-center px-5 pt-8 rounded-t-full rounded-b-full ">
                  <button
                    onClick={() => navigate("/add-pet")}
                    className="flex w-[150px] h-[45px] bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md  "
                  >
                    <p className="font-poppins font-medium text-justify text-xl">
                      + Add a Pet
                    </p>
                  </button>
                </div>
              </>
            )}

            {emptyPetProfile ? (
              <div className="flex flex-col relative justify-start items-start shadow-PetProfileCardShadow gap-4 p-5 bg-white rounded-2xl w-full">
                <span
                  onClick={() => setAddTagModal(true)}
                  className="bg-white h-10 w-10  cursor-pointer absolute top-[-20px] right-[-20px] text-borderColor rounded-full flex items-center justify-center"
                >
                  <BorderColorOutlinedIcon style={{ fontSize: "1rem" }} />
                </span>
                <div className="flex gap-4 justify-start items-start">
                  <p className="font-poppins text-sm font-normal  ">
                    Energy Level
                  </p>
                  {[1, 2].map((data, i) => (
                    <img src={bone} key={i} alt="level" className="w-4 h-4" />
                  ))}
                </div>
                <div className="flex gap-4 justify-start items-start">
                  <p className="font-poppins text-sm font-normal  ">
                    People friendly
                  </p>
                  {[1, 2, 3].map((data) => (
                    <img src={bone} alt="level" className="w-4 h-4" />
                  ))}{" "}
                </div>
                <div className="flex gap-4 justify-start items-start">
                  <p className="font-poppins text-sm font-normal  ">
                    Dog friendly
                  </p>{" "}
                  {[1, 2, 3, 4].map((data) => (
                    <img src={bone} alt="level" className="w-4 h-4" />
                  ))}{" "}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="w-[50%]">
          <div className="w-full flex flex-col gap-y-8 ">
            <div>
              <p className="text-black font-poppins text-6xl font-bold leading-100 capitalize">
                Hey
              </p>
              {emptyPetProfile ? (
                <span className="flex gap-4 justify-normal items-center">
                  <p className="text-black font-poppins text-6xl font-bold leading-100 capitalize">
                    I'm{" "}
                    <span className="text-borderColor">
                      {selectedpet.activepet?.name || "Tommy"}
                    </span>
                  </p>
                  <button
                    onClick={() => setOpen(true)}
                    className="flex w-[112px] text-borderColor bg-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-l-full rounded-r-full  "
                  >
                    <p className="font-poppins font-bold text-justify text-sm">
                      Switch Pet
                    </p>
                  </button>
                </span>
              ) : (
                <>
                  <p className="font-poppins text-lg font-normal">
                    {" "}
                    To unlock all the pawsome features, simply add your pet to
                    get started. It's quick, easy, and opens the door to a world
                    of tailored pet services
                  </p>

                  <p className="text-black mt-2 font-poppins text-6xl font-bold leading-100 capitalize">
                    Add Your Pet
                  </p>
                </>
              )}
            </div>

            {emptyPetProfile ? (
              <>
                <div className="flex gap-4 justify-evenly items-center">
                  <div className="w-4/12 flex flex-col justify-start items-start  gap-4 p-5 bg-white rounded-2xl shadow-PetProfileCardShadow ">
                    <p className="font-poppins text-base font-normal ">Age</p>{" "}
                    <p className="font-poppins text-2xl font-semibold   ">
                      {selectedpet.activepet?.age || 2} Years
                    </p>
                  </div>
                  <div className="w-4/12 flex flex-col justify-start items-start gap-4 p-5 bg-white rounded-2xl shadow-PetProfileCardShadow">
                    <p className="font-poppins text-base font-normal ">Breed</p>
                    <p className="font-poppins text-2xl font-semibold   ">
                      {selectedpet.activepet?.breed || "Pug"}
                    </p>
                  </div>
                  <div className="w-4/12 flex flex-col justify-start items-start gap-4 p-5 bg-white rounded-2xl shadow-PetProfileCardShadow">
                    <p className="font-poppins text-base font-normal ">Geder</p>
                    <p className="font-poppins text-2xl font-semibold   ">
                      {selectedpet.activepet?.gender || "Male"}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-start items-start  gap-4 p-5 bg-white rounded-2xl shadow-PetProfileCardShadow">
                  <p className="font-poppins text-2xl font-semibold   ">
                    About
                  </p>
                  <p className=" text-sm font-normal  font-Montserrat ">
                    {" "}
                    Tommy is a warmly welcomed member of our community, having
                    received full vaccination. His friendly demeanor is evident
                    in the positive interactions he has with everyone he meets,
                    creating a pleasant and inclusive atmosphere. On the other
                    hand, Charlie brings an extra layer of charm to our
                    environment. Not only is he affectionate, but his
                    well-behaved nature is also noteworthy.{" "}
                  </p>
                </div>
              </>
            ) : (
              <>
                <button className="flex w-[150px] h-[45px] bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md  ">
                  <p className="font-poppins font-medium text-justify text-xl">
                    + Add a Pet
                  </p>
                </button>
              </>
            )}
          </div>
        </div>
        <div className="w-[25%]">
          <div className="w-full overflow-hidden">
            <div className="px-8 xl:px-0 flex flex-col justify-start items-start">
              <div className="text-black font-poppins text-xl font-semibold  px-0 ">
                What Service Are you Looking for?
              </div>
              <div class="h-1 w-6/12 bg-borderColor mt-1"></div>{" "}
            </div>
            <div className="w-full  mt-5 gap-4 flex-wrap flex justify-evenly items-center ">
              {serviceData.map((data) => (
                <ServiceCardV2 data={data} />
              ))}
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="w-full flex gap-x-12 justify-center items-start xl:py-10 py-10 px-8">
        <div className="w-2/4 bg-cardBg  overflow-hidden">
          <div className="px-8 xl:px-0 flex justify-between items-center">
            <div className="text-black font-poppins text-4xl font-semibold  px-0 ">
              Appointments
            </div>

            {emptyPetProfile && (
              <button
                onClick={handleNavigation}
                className="flex w-[150px] h-[45px] bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md  "
              >
                <p className="font-poppins font-medium text-justify text-xl">
                  View All
                </p>
              </button>
            )}
          </div>
          <div class="h-[2px] w-4/12 bg-borderColor mt-1"></div>{" "}
          {emptyPetProfile ? (
            <div className="w-full  mt-5 bg-cardBg flex-wrap flex px-0 justify-between flex-col items-center gap-4">
              {appintmentdata?.data?.map((rec) =>
                rec?.service_appointment_bookings?.map((booking) => (
                  <AppointmentCard data={booking} />
                ))
              )}
              {/* {appintmentdata.map((i) => (
                <AppointmentCard key={i} />
              ))} */}
            </div>
          ) : (
            <>
              <div className="w-full flex flex-col gap-1 justify-center items-center">
                <img src={Appointement} alt="#" className="w-40 h-40" />
                <p className="text-2xl font-bold font-poppins ">
                  No Appointement
                </p>
                <p className="text-[15px] font-medium font-poppins ">
                  Add a pet to schedule appointments
                </p>
                <button className="flex w-[150px] h-[45px] bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md  ">
                  <p className="font-poppins font-medium text-justify text-xl">
                    + Add a Pet
                  </p>
                </button>
              </div>
            </>
          )}
        </div>

        <div className="w-2/4 bg-cardBg  overflow-hidden">
          <div className="px-8 xl:px-0">
            <div className="text-black font-poppins text-4xl font-semibold  px-0 ">
              Explore More
            </div>
          </div>
          <div class="h-[2px] w-4/12 bg-borderColor mt-1"></div>
          {emptyPetProfile ? (
            <div className="w-full mt-5 flex-wrap overflow-y-auto gap-x-4 gap-y-8 flex overflow-hidden">
              {option.map((data, i) => (
                <ExploreCards key={i} data={data} />
              ))}
            </div>
          ) : (
            <div className="w-full flex flex-col gap-1 justify-center items-center">
              <img src={Explore} alt="#" className="w-40 h-40" />
              <p className="text-2xl font-bold font-poppins ">Add a pet</p>
              <p className="text-[15px] font-medium font-poppins ">
                Add a pet to explore more services
              </p>
              <button className="flex w-[150px] h-[45px] bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md  ">
                <p className="font-poppins font-medium text-justify text-xl">
                  + Add a Pet
                </p>
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="w-full bg-cardBg xl:py-10 pb-2 px-8 overflow-hidden">
        <div className="px-8 xl:px-0">
          <div className="text-black font-poppins text-4xl font-semibold  px-0 ">
            Blogs
          </div>
        </div>
        <div class="h-[2px] w-1/12 bg-borderColor mt-1"></div>{" "}
        <div className="w-full gap-x-8 mt-10 bg-cardBg flex px-0">
          {[1, 2, 3].map((i) => (
            <Tilt options={defaultOptions}>
              <BlogCard key={i} />
            </Tilt>
          ))}
        </div>
      </div>

      <ModalWrapper open={open} handleClose={() => setOpen(false)}>
        <SwitchPetModal closeModal={() => setOpen(false)} />
      </ModalWrapper>

      <ModalWrapper open={openEdit} handleClose={() => setopenEdit(false)}>
        <EditProfilePicModal closeModal={() => setopenEdit(false)} />
      </ModalWrapper>

      <ModalWrapper
        open={AddTagModal}
        handleClose={() => setAddTagModal(false)}
      >
        <AddTags closeModal={() => setAddTagModal(false)} />
      </ModalWrapper>
    </>
  );
};

export default Dashboard;
