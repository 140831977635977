import { CancelOutlined } from "@mui/icons-material";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import React from "react";
import Allergy from "assets/svg/Allergy.svg";
import Vomitus from "assets/svg/vomitus.svg";
import Feces from "assets/svg/Feces.svg";
import Heat from "assets/svg/Heat.svg";
import AllergyModal from "./AllergyModal";
import VomitusModal from "./VomitusModal";
import FecesModal from "./FecesModal";
import HeatModal from "./HeatModal";
import ModalWrapper from "./ModalWrapper";

const AddJournalModal = ({ closeModal, view }) => {
  const [AllergyOpen, setAllergyOpen] = React.useState(
    view && view?.journal_type == "Allergy" ? true : false
  );
  const [HeatOpen, setHeatOpen] = React.useState(
    view && view?.journal_type == "Heat" ? true : false
  );
  const [FecesOpen, setFecesOpen] = React.useState(
    view && view?.journal_type == "Feces" ? true : false
  );
  const [VomitusOpen, setVomitusOpen] = React.useState(
    view && view?.journal_type == "Vomitus" ? true : false
  );

  return (
    <>
      {!view && (
        <div className=" w-full">
          <div className="flex justify-between items-start gap-x-2 ">
            <span className="flex justify-normal items-center gap-x-2 ">
              <div className="flex justify-end p-2 items-center gap-x-2 ">
                <div className="bg-faintBg cursor-pointer  text-borderColor rounded-full">
                  <ChatBubbleIcon style={{ width: "20px", height: "20px" }} />
                </div>
              </div>

              <div className="font-bold font-poppins text-lg">
                What do you want to journal today?
              </div>
            </span>
            <div className="flex justify-end items-center gap-x-2">
              <button
                onClick={closeModal}
                className="bg-faintBg cursor-pointer  text-borderColor rounded-full"
              >
                <CancelOutlined />
              </button>
            </div>
          </div>
          <div class="h-[1px] w-full bg-borderColor mt-1"></div>{" "}
          <div className="flex justify-around py-5 items-center gap-5 flex-wrap">
            <div
              onClick={() => setAllergyOpen(true)}
              className="w-5/12 flex justify-center cursor-pointer flex-col items-center"
            >
              <img
                src={Allergy}
                alt="img"
                className="w-20 h-20 border-[2px] rounded-full p-2 border-borderColor"
              />
              <p className="font-bold font-poppins text-lg">Allergy Details</p>
            </div>

            <div
              onClick={() => setVomitusOpen(true)}
              className="w-5/12 flex justify-center cursor-pointer flex-col items-center"
            >
              <img
                src={Vomitus}
                alt="img"
                className="w-20 h-20 border-[2px] rounded-full p-2 border-borderColor"
              />
              <p className="font-bold font-poppins text-lg">Vomitus Details</p>
            </div>

            <div
              onClick={() => setFecesOpen(true)}
              className="w-5/12 flex justify-center cursor-pointer flex-col items-center"
            >
              <img
                src={Feces}
                alt="img"
                className="w-20 h-20 border-[2px] rounded-full p-2 border-borderColor"
              />
              <p className="font-bold font-poppins text-lg">Feces Details</p>
            </div>

            <div
              onClick={() => setHeatOpen(true)}
              className="w-5/12 flex justify-center cursor-pointer flex-col items-center"
            >
              <img
                src={Heat}
                alt="img"
                className="w-20 h-20 border-[2px] rounded-full p-2 border-borderColor"
              />
              <p className="font-bold font-poppins text-lg">Heat Details</p>
            </div>
          </div>
          <div className="flex mt-5  gap-5 sm:flex-row justify-center items-center">
            <button className="flex  bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md  ">
              <p className="font-poppins font-medium text-justify text-xs">
                Submit Journal
              </p>
            </button>

            <button
              onClick={closeModal}
              className="flex  bg-faintBg text-black p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md  "
            >
              <p className="font-poppins font-medium text-justify text-xs">
                Do it Later
              </p>
            </button>
          </div>
        </div>
      )}

      <ModalWrapper
        open={AllergyOpen}
        handleClose={() => {
          setAllergyOpen(false);
          view && closeModal();
        }}
      >
        <AllergyModal
          data={view}
          closeModal={() => {
            setAllergyOpen(false);
            view && closeModal();
          }}
        />
      </ModalWrapper>

      <ModalWrapper
        open={VomitusOpen}
        handleClose={() => {
          setVomitusOpen(false);
          view && closeModal();
        }}
      >
        <VomitusModal
          data={view}
          closeModal={() => {
            setVomitusOpen(false);
            view && closeModal();
          }}
        />
      </ModalWrapper>

      <ModalWrapper
        open={FecesOpen}
        handleClose={() => {
          setFecesOpen(false);
          view && closeModal();
        }}
      >
        <FecesModal
          data={view}
          closeModal={() => {
            setFecesOpen(false);
            view && closeModal();
          }}
        />
      </ModalWrapper>

      <ModalWrapper
        open={HeatOpen}
        handleClose={() => {
          setHeatOpen(false);
          view && closeModal();
        }}
      >
        <HeatModal
          data={view}
          closeModal={() => {
            setHeatOpen(false);
            view && closeModal();
          }}
        />
      </ModalWrapper>
    </>
  );
};

export default AddJournalModal;
