import React, { useEffect, useState } from "react";

import { AttachFile } from "@mui/icons-material";
import toast from "react-hot-toast";
import Input from "components/FormComponents/Input";
import {
  useGetMedicalRecordDetailsQuery,
  useUpdateMedicalRecordMutation,
  useDeleteMedicalRecordMutation,
} from "store/HealthHub/healthHubSlice";
import Confirm from "components/Common/Modal/Confirm";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { MdOutlineDelete } from "react-icons/md";

const IndividualMedicalRecord = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { data: details } = useGetMedicalRecordDetailsQuery(id);
  const [photo, setphoto] = useState();
  const [updaterecord, updatestate] = useUpdateMedicalRecordMutation();
  const [deleterecrod, deletestate] = useDeleteMedicalRecordMutation();
  const [confirm, setconfirm] = useState(false);
  const [initial, setinitial] = useState({
    medicine_name: "",
    medicine_type: "",
    administration_type: "",
    administered_date: "",
    next_dosage_due_date: null,
    consult_doctor: "",
    administration_site: "",
    additional_notes: "",
  });
  const formik = useFormik({
    initialValues: initial,
    validationSchema: Yup.object().shape({
      medicine_name: Yup.string().required("Medicine Name is required"),
      medicine_type: Yup.string(),
      administration_type: Yup.string(),
      administered_date: Yup.date()
        .nullable()
        .required("Administered Date is required"),
      next_dosage_due_date: Yup.date().nullable(),
      consult_doctor: Yup.string(),
    }),
    onSubmit: (values) => {
      try {
        const formDataPayload = new FormData();
        for (const key in values) {
          if (values[key]) formDataPayload.append(key, values[key]);
        }
        updaterecord({ id, data: formDataPayload });
        toast.success("Medical record updated successfully");
      } catch (error) {
        toast.error("Failed to update medical record");
      }
    },
  });
  useEffect(() => {
    if (details) {
      formik.setValues({
        medicine_name: details?.medicalRecord?.medicine_name || "",
        medicine_type: details?.medicalRecord?.medicine_type || "",
        administration_type: details?.medicalRecord?.administration_type || "",
        administered_date: details?.medicalRecord?.administered_date
          ? moment(details?.medicalRecord?.administered_date).format(
              "YYYY-MM-DD"
            )
          : null,
        next_dosage_due_date: details?.medicalRecord?.next_dosage_due_date
          ? moment(details?.medicalRecord?.next_dosage_due_date).format(
              "YYYY-MM-DD"
            )
          : null,
        consult_doctor: details?.medicalRecord?.consult_doctor || "",
        administration_site: details?.medicalRecord?.administration_site || "",
        additional_notes: details?.medicalRecord?.additional_notes || "",
      });
      setphoto(details?.medicalRecord?.file?.url);
    }
  }, [details]);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("file", file);
    setphoto(URL.createObjectURL(file));
  };

  const handleDelete = () => {
    deleterecrod(id);
    navigate("/home/health-hub/medical-records");
  };
  return (
    <div className="p-8 flex flex-col w-full justify-normal items-start gap-4 space-y-5">
      <Confirm show={confirm} width={40} onClose={() => setconfirm(false)}>
        <div className="flex flex-col w-full gap-4 items-center justify-center">
          <h2 className="w-full text-center">
            {" "}
            Are you Sure You want to delete?
          </h2>
          <div className="flex flex-row gap-5">
            <button
              onClick={() => setconfirm(false)}
              className="bg-white text-black p-2 pl-5 pr-5 rounded-2xl border border-borderColor"
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="bg-borderColor text-white  p-2 pl-5 pr-5 rounded-2xl"
            >
              Confirm
            </button>
          </div>
        </div>
      </Confirm>
      <div className="w-full flex justify-between items-center">
        <div className="text-black font-poppins text-4xl font-semibold px-0">
          Individual Medical Records
          <div className="h-1 w-8/12 bg-borderColor mt-1"></div>
        </div>
      </div>

      <div className="w-full flex justify-center items-center flex-col gap-8">
        <div className="w-full justify-normal items-start gap-y-4 gap-x-8 flex flex-wrap">
          <span className="w-3/12">
            <Input
              label="Medicine Name"
              placeholder="Medicine Name"
              type="text"
              name="medicine_name"
              formik={formik}
            />
          </span>
          <span className="w-3/12">
            <Input
              label="Medicine Type"
              type="option"
              name="medicine_type"
              formik={formik}
              options={[
                { id: 1, val: "Option 1" },
                { id: 2, val: "Option 2" },
                { id: 3, val: "Option 3" },
              ]}
              placeholder="Select an option"
            />
          </span>
          <span className="w-3/12">
            <Input
              label="Administration Type"
              type="option"
              name="administration_type"
              formik={formik}
              options={[
                { id: 1, val: "Option 1" },
                { id: 2, val: "Option 2" },
                { id: 3, val: "Option 3" },
              ]}
              placeholder="Select an option"
            />
          </span>
          <span className="w-3/12">
            <Input
              label={"Administration Date"}
              name={"administered_date"}
              type={"date"}
              formik={formik}
              placeholder="date"
            />
          </span>
          <span className="w-3/12">
            <Input
              label="Next Dosage Due"
              type="date"
              name="next_dosage_due_date"
              formik={formik}
            />
          </span>
          <span className="w-3/12">
            <Input
              label="Consult Doctor"
              placeholder="Consult Doctor"
              type="text"
              name="consult_doctor"
              formik={formik}
            />
          </span>
        </div>

        <div className="w-full justify-normal items-start gap-y-4 gap-x-8 flex flex-wrap">
          <div className="text-black font-poppins text-lg font-semibold px-0">
            Optional fields
            <div className="h-[1px] w-8/12 bg-borderColor mt-1"></div>
          </div>

          <span className="w-3/12">
            <Input
              label="Administration Site"
              placeholder="Administration Site"
              type="text"
              name="administration_site"
              formik={formik}
            />
            <p className="font-poppins font-bold text-xs mt-5">Add a photo</p>
            {photo && (
              <>
                {" "}
                <a
                  target="_blank"
                  href={photo}
                  className={`  rounded-lg h-[5rem] w-[5rem] bg-borderColor`}
                >
                  {photo}
                </a>
                <MdOutlineDelete
                  onClick={() => {
                    formik.setFieldValue("file", true);
                    setphoto(false);
                  }}
                  className="text-borderColor"
                />
              </>
            )}
            <div className="flex items-center border-[2px] border-borderColor bg-white rounded-[2px] p-1">
              <input
                type="file"
                id="photoInput"
                name="photo"
                className="hidden"
                accept="image/*"
                onChange={handleFileChange}
              />
              <label
                htmlFor="photoInput"
                className="font-poppins p-1 text-xs font-bold bg-[#fdeedc] text-black rounded-[2px] cursor-pointer"
              >
                <AttachFile style={{ height: "15px", width: "15px" }} /> Select
                file
              </label>
              <span className="ml-2 text-xs font-poppins text-[#b0b0b0]">
                {formik.values.photo
                  ? formik.values.photo.name
                  : "No file selected"}
              </span>
            </div>
          </span>
          <span className="w-6/12">
            <Input
              label="Additional Note"
              placeholder="Write Something!"
              type="textarea"
              name="additional_notes"
              formik={formik}
            />
          </span>
        </div>
      </div>

      <div className="flex w-full justify-start gap-x-2 items-center">
        <button
          className="flex w-fit mt-auto rounded-[8px] justify-center items-center bg-borderColor"
          onClick={formik.handleSubmit}
        >
          <p className="text-base font-poppins p-2 font-normal text-white">
            {updatestate?.isLoading ? "Save.." : "Save"}
          </p>
        </button>

        <button
          className="flex w-fit mt-auto rounded-[8px] justify-center items-center bg-[#CF3030] bg-opacity-[0.2]"
          onClick={() => setconfirm(true)}
        >
          <p className="text-base font-poppins p-2 font-normal text-[#CF3030]">
            Delete Record
          </p>
        </button>
      </div>
    </div>
  );
};

export default IndividualMedicalRecord;
