import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DashboardFilter from "components/Common/DashboardFilter";
import MedicalReportTable from "components/PetManagement/HealthHub/MedicalReportTable";
import AddMedicalRecord from "components/Common/Modal/AddMedicalRecord";
import ModalWrapper from "components/Common/Modal/ModalWrapper";
import { useGetAllMedicalRecordsQuery } from "store/HealthHub/healthHubSlice";
const MedicalRecords = () => {
  const medicalData = useSelector((state) => state.HealthHub.MedicalRecords);
  const [open, setOpen] = useState(false);
  const selectedpet = useSelector((state) => state?.petsetting?.activepet);
  const { data: medicalrec } = useGetAllMedicalRecordsQuery(selectedpet?.id);
  const [filteredData, setFilteredData] = useState();
  useEffect(() => {
    setFilteredData(medicalrec?.medicalRecords);
  }, [medicalrec]);
  const medicalFilterFields = [
    {
      name: "admin_date",
      label: "Administered Date",
      options: Array.from(
        new Set(medicalData.map((record) => record.admin_date))
      ),
    },
    {
      name: "med_type",
      label: "Medicine Type",
      options: Array.from(
        new Set(medicalData.map((record) => record.med_type))
      ),
    },
  ];

  const handleFilteredData = (data) => {
    setFilteredData(data);
  };

  const [filters, setFilters] = useState({
    admin_date: "",
    medicine_type: "",
    due_date: "",
  });


  return (
    <>
      <div className="p-8 flex flex-col w-full justify-normal items-start gap-4">
        <div className="w-full flex justify-between items-center">
          <div className="text-black font-poppins text-4xl font-semibold px-0">
            Medical Records
            <div className="h-1 w-8/12 bg-borderColor mt-1"></div>
          </div>
          <button
            onClick={() => setOpen(true)}
            className="flex bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md"
          >
            <p className="font-poppins font-medium text-justify text-xl">
              + Add Medical Record
            </p>
          </button>
        </div>

        <div className="flex flex-row justify-start items-center w-full gap-3">
          <p className="font-poppins w-20 text-start text-xs underline font-semibold cursor-pointer">
            Filter by
          </p>
          <div className="w-full flex justify-start items-end flex-row gap-5">
            <div className=" flex flex-col">
              <label htmlFor="admin_date" className="text-xs font-bold">
                Administered Date
              </label>
              <input
                label="Date"
                id="admin_date"
                type="date"
                name="allergy_date"
                onChange={(e) => {
                  setFilters({ ...filters, admin_date: e.target.value });
                }}
                value={filters.admin_date}
                required={true}
                className={`block w-max rounded-md border-0 py-2.5 p-2 text-gray-900 
                  shadow-sm ring-0 ring-inset  placeholder:text-gray-400 focus:outline-none
                  focus:ring-1 focus:ring-inset focus:ring-borderColor sm:text-sm sm:leading-6
                  `}
              />
            </div>
            <div className="flex justify-evenly items-center py-2 font-lato text-xs lg:text-base font-normal text-black bg-white rounded-xl text-bold">
              <select
                className="bg-white w-full border-none outline-none rounded-lg pl-5 pr-5"
                value={filters.medicine_type}
                onChange={(e) => {
                  setFilters({ ...filters, medicine_type: e.target.value });
                }}
              >
                <option value="">Select Medicine Type</option>
                {["Option 1", "Option 2", "Option 3", "Option 4"].map((item) => (
                  <option className="text-black" value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="30"
                viewBox="0 0 2 56"
                fill="none"
              >
                <path
                  opacity="0.686151"
                  d="M1.02587 55V1"
                  stroke="#979797"
                  strokeWidth="0.3"
                  strokeLinecap="square"
                />
              </svg>
            </div>


            <div className=" flex flex-col">
              <label htmlFor="due_date" className="text-xs font-bold">
                Due Date
              </label>
              <input
                label="Date"
                id="due_date"
                type="date"
                name="allergy_date"
                onChange={(e) => {
                  setFilters({ ...filters, due_date: e.target.value });
                }}
                value={filters.due_date}
                required={true}
                className={`block w-max rounded-md border-0 py-2.5 p-2 text-gray-900 
                  shadow-sm ring-0 ring-inset  placeholder:text-gray-400 focus:outline-none
                  focus:ring-1 focus:ring-inset focus:ring-borderColor sm:text-sm sm:leading-6
                  `}
              />
            </div>
            <div
              onClick={() => {
                // reset all the filters
                const allFields = Object.keys(filters);
                //reset all the fields
                const resetFields = allFields.reduce((acc, field) => {
                  acc[field] = "";
                  return acc;
                }, {});

                setFilters(resetFields);

              }}
              className="flex cursor-pointer justify-evenly items-center p-4 font-lato text-sm lg:text-base font-normal text-black bg-white rounded-xl text-bold">

              <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.07963 2.61109V0.158691L2.01457 3.22419L5.07963 6.28969V3.83729C7.1087 3.83729 8.75771 5.48653 8.75771 7.51589C8.75771 9.54525 7.1087 11.1945 5.07963 11.1945C3.05056 11.1945 1.40156 9.54525 1.40156 7.51589H0.175537C0.175537 10.2258 2.37012 12.4207 5.07963 12.4207C7.78915 12.4207 9.98373 10.2258 9.98373 7.51589C9.98373 4.80599 7.78915 2.61109 5.07963 2.61109Z" fill="#EA0234" />
              </svg>

            </div>
          </div>
        </div>

        {filteredData && <MedicalReportTable data={filteredData
          ?.filter((item) => {
            if (filters.admin_date) {
              return item.administered_date === filters.admin_date + "T00:00:00.000Z";
            }
            return item;
          })
          ?.filter((item) => {
            if (filters.medicine_type) {
              return item.medicine_type?.toLowerCase() === filters.medicine_type?.toLowerCase();
            }
            return item;
          })
          ?.filter((item) => {
            if (filters.due_date) {
              return item.next_dosage_due_date === filters.due_date + "T00:00:00.000Z";
            }
            return item;
          })
        } />}
      </div>

      <ModalWrapper open={open} handleClose={() => setOpen(false)}>
        <AddMedicalRecord closeModal={() => setOpen(false)} />
      </ModalWrapper>
    </>
  );
};

export default MedicalRecords;
