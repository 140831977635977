import { createSlice } from "@reduxjs/toolkit";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("pets");
    return serializedState ? JSON.parse(serializedState) : [];
  } catch (err) {
    return [];
  }
};
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("pets", serializedState);
  } catch (err) {
    console.log(err);
  }
};

const pets = loadState();

const PetSettingSlice = createSlice({
  name: "petsetting",
  initialState: {
    activepet: pets.length > 0 ? pets[0] : null,
    allpets: pets,
  },
  reducers: {
    setpets: (state, action) => {
      const allpets = action.payload;
      saveState(allpets);
      state.allpets = allpets;
    },
    setactive: (state, action) => {
      state.activepet = action?.payload;
    },
    emptypet: (state, action) => {
      state.activepet = null;
      state.pets = [];
      saveState([]);
    },
  },
});

export const { setpets, setactive, emptypet } = PetSettingSlice.actions;

const settingreducer = {
  petsetting: PetSettingSlice.reducer,
};

export default settingreducer;
