import { StarIcon } from "lucide-react";
import React, { useState, useEffect } from "react";
import happyPetImg from "assets/img/happy.png";
import pawsome_1_PetImg from "assets/img/pawsome_1.png";
import pawsome_2_PetImg from "assets/img/pawsome_2.png";
import sadPetImg from "assets/img/sad.png";
import satisfiedPetImg from "assets/img/satisfied.png";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const ratings = [
  { value: 1, label: "Pawsome!", img: pawsome_2_PetImg },
  { value: 2, label: "Dissatisfied", img: satisfiedPetImg },
  { value: 3, label: "Happy", img: happyPetImg },
  { value: 4, label: "Satisfied", img: sadPetImg },
  { value: 5, label: "Pawsome!", img: pawsome_1_PetImg },
];

const ViewParticularAppointement = ({ initialData }) => {
  const [formData, setFormData] = useState({
    serviceName: "",
    serviceProviderName: "",
    petName: "",
    administeredDate: null,
    time: null,
    location: "",
    status: "",
    groomingServices: "",
    roomType: "",
    rating: null,
    title: "",
    description: "",
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRatingChange = (rating) => {
    setFormData({ ...formData, rating });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, administeredDate: date });
  };

  const handleTimeChange = (time) => {
    setFormData({ ...formData, time });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="p-8 flex flex-col justify-normal items-start gap-4">
      <div className="text-black font-poppins text-4xl font-semibold px-0">
        Appointment
      </div>
      <div className="h-[2px] w-3/12 bg-borderColor"></div>
      <form onSubmit={handleSubmit} className="mx-auto p-4 space-y-4">
        <div className="container mx-auto">
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-1">
              <label className="font-poppins font-bold text-[14px]">
                Service
              </label>
              <input
                onChange={handleChange}
                name="serviceName"
                value={formData.serviceName}
                type="text"
                className="w-full font-poppins rounded-[4px] text-[#9A9A9A] font-medium bg-white h-[40px] px-3 text-xs focus:outline-none"
                placeholder="Service"
                required
              />
            </div>

            <div className="col-span-1">
              <label className="font-poppins font-bold text-[14px]">
                Service Provider
              </label>
              <input
                onChange={handleChange}
                name="serviceProviderName"
                value={formData.serviceProviderName}
                type="text"
                className="w-full font-poppins rounded-[4px] text-[#9A9A9A] font-medium bg-white h-[40px] px-3 text-xs focus:outline-none"
                placeholder="Service Provider"
                required
              />
            </div>

            <div className="col-span-1">
              <label className="font-poppins font-bold text-[14px]">
                Pet Name
              </label>
              <input
                onChange={handleChange}
                name="petName"
                value={formData.petName}
                type="text"
                className="w-full font-poppins rounded-[4px] text-[#9A9A9A] font-medium bg-white h-[40px] px-3 text-xs focus:outline-none"
                placeholder="Pet Name"
                required
              />
            </div>

            <div className="col-span-1">
              <label className="font-poppins font-bold text-[14px]">
                Administered Date
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={formData.administeredDate}
                  onChange={handleDateChange}
                  className="font-poppins px-4 py-0 text-xs text-[#9A9A9A] font-medium w-full bg-white rounded-[2px] focus:outline-none"
                />
              </LocalizationProvider>
            </div>

            <div className="col-span-1">
              <label className="font-poppins font-bold text-[14px]">Time</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  value={formData.time}
                  onChange={handleTimeChange}
                  className="font-poppins px-4 py-0 text-xs text-[#9A9A9A] font-medium w-full bg-white rounded-[2px] focus:outline-none"
                />
              </LocalizationProvider>
            </div>

            <div className="col-span-1">
              <label className="font-poppins font-bold text-[14px]">
                Location
              </label>
              <input
                onChange={handleChange}
                name="location"
                value={formData.location}
                type="text"
                className="w-full font-poppins rounded-[4px] text-[#9A9A9A] font-medium bg-white h-[40px] px-3 text-xs focus:outline-none"
                placeholder="Location"
                required
              />
            </div>

            <div className="col-span-1">
              <label className="font-poppins font-bold text-[14px]">
                Status
              </label>
              <input
                onChange={handleChange}
                name="status"
                value={formData.status}
                type="text"
                className="w-full font-poppins rounded-[4px] text-[#9A9A9A] font-medium bg-white h-[40px] px-3 text-xs focus:outline-none"
                placeholder="Status"
                required
              />
            </div>

            <div className="col-span-1">
              <label className="font-poppins font-bold text-[14px]">
                Grooming Service
              </label>
              <input
                onChange={handleChange}
                name="groomingServices"
                value={formData.groomingServices}
                type="text"
                className="w-full font-poppins rounded-[4px] text-[#9A9A9A] font-medium bg-white h-[40px] px-3 text-xs focus:outline-none"
                placeholder="Service"
                required
              />
            </div>

            <div className="col-span-1">
              <label className="font-poppins font-bold text-[14px]">
                Room Type
              </label>
              <input
                onChange={handleChange}
                name="roomType"
                value={formData.roomType}
                type="text"
                className="w-full font-poppins rounded-[4px] text-[#9A9A9A] font-medium bg-white h-[40px] px-3 text-xs focus:outline-none"
                placeholder="Room Type"
                required
              />
            </div>
          </div>
        </div>

        <div className="w-full flex gap-x-5">
          <div className="w-2/4">
            <label className="font-poppins font-bold text-[14px]">
              Rating & Feedback
            </label>
            <div className="flex space-x-2 bg-white p-4 justify-evenly items-center">
              {ratings.map((rating) => (
                <span
                  key={rating.value}
                  className="flex flex-col justify-normal items-center space-y-4"
                >
                  <img
                    src={rating.img}
                    className="w-16 h-16 border-2 border-borderColor border-opacity-1 p-1 rounded-full"
                    alt="reaction"
                  />
                  <span>
                    <button
                      type="button"
                      className={`px-4 border-borderColor border-[1px] flex gap-1 justify-normal items-center font-bold text-[10px] rounded ${
                        formData.rating === rating.value
                          ? "bg-borderColor text-white"
                          : "bg-white"
                      }`}
                      onClick={() => handleRatingChange(rating.value)}
                    >
                      <StarIcon
                        size={14}
                        className={`${
                          formData.rating === rating.value
                            ? "text-white"
                            : "text-borderColor"
                        }`}
                      />{" "}
                      {rating.value}
                    </button>
                    <p className="font-poppins text-[10px] text-center font-semibold">
                      {rating.label}
                    </p>
                  </span>
                </span>
              ))}
            </div>
          </div>

          <div className="w-2/4">
            <textarea
              className="flex-shrink-0 rounded-lg text-[#9A9A9A] font-medium w-full p-4 border-[1px] text-xs border-[#BFBFBF] focus:outline-none bg-white"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Write Something!"
            />

            <div className="flex justify-center gap-x-5 mt-2 items-center">
              <button
                type="submit"
                className="flex w-full mt-auto rounded-[6px] justify-center items-center bg-borderColor px-4"
              >
                <p className="text-[10px] font-poppins font-normal text-white">
                  Submit Review
                </p>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ViewParticularAppointement;
