import React from "react";
import { IoClose } from "react-icons/io5";

const Rating = ({ formik, name, label }) => {
  const handleClick = (index) => {
    formik.setFieldValue(name, index + 1);
  };

  return (
    <div className="flex p-2 gap-x-4 items-center">
      <span className="mr-2 font-bold">{label}</span>
      <div className="flex flex-row items-center justify-center gap-3">
        <span className="bg-white rounded-3xl flex justify-center p-2 items-center w-fit">
          {[...Array(5)].map((_, index) => (
            <button
              key={index}
              onClick={(e) => {
                e.preventDefault();
                handleClick(index);
              }}
              className={`w-5 h-5 rounded-full mx-1 ${
                index < formik.values[name] ? "bg-borderColor" : "bg-gray-300"
              }`}
            />
          ))}
        </span>
        {formik.values[name] > 0 && (
          <IoClose
            size={25}
            className="text-red-500 cursor-pointer"
            onClick={() => formik.setFieldValue(name, 0)}
          />
        )}
      </div>
      {formik.touched[name] && formik.errors[name] ? (
        <div className="text-red-500 text-sm text-end ">
          {formik.errors[name]}
        </div>
      ) : null}
    </div>
  );
};

export default Rating;
