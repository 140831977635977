import { apiSlice } from "../apiSlice";

export const petParentApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["services", "bookings"],

  endpoints: (builder) => ({
    getAllServices: builder.query({
      query: (params) => {
        const queryParams = new URLSearchParams(params).toString();
        return {
          url: `/api/v1/pet-service/service-management/filter/services?${queryParams}`,
          method: "GET",
        };
      },
      providesTags: ["services"],
    }),
    getpetAppointments: builder.query({
      query: () => ({
        url: `/api/v1/pet-service/appointment/my`,
        method: "GET",
      }),
      providesTags: ["bookings"],
    }),
    registerPet: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/pets",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["petsetting"],
    }),
    createBooking: builder.mutation({
      query: (data) => ({
        url: "/api/v1/pet-service/appointment/book",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["bookings"],
    }),
    getServiceDetails: builder.query({
      query: (id) => ({
        url: `/api/v1/pet-service/auth/fetch-info/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "services", id }],
    }),
    updatePet: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/petparent/pets/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["petsetting"],
    }),
    deletePet: builder.mutation({
      query: (id) => ({
        url: `/api/v1/petparent/pets/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["petsetting"],
    }),
    addParentToPet: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/pets/addparenttopet",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Pets"],
    }),
    removePetFromParent: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/pets/removeparentfrompet",
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Pets"],
    }),
  }),
});

export const {
  useRegisterPetMutation,
  useGetpetAppointmentsQuery,
  useGetServiceDetailsQuery,
  useGetAllServicesQuery,
  useCreateBookingMutation,
  useGetPetDetailsQuery,
  useUpdatePetMutation,
  useDeletePetMutation,
  useAddParentToPetMutation,
  useRemovePetFromParentMutation,
} = petParentApiSlice;
