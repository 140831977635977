import React from "react";
import Pet from "assets/img/Pet.png";
import { CancelOutlined } from "@mui/icons-material";

const EditProfilePicModal = ({ closeModal }) => {
  return (
    <>
      <div className="w-full ">
        <div className="text-black inline-flex flex-col justify-center items-center min-w-full  font-poppins text-4xl font-semibold">
          Edit Profile Picture
          <div class="h-[1px] w-1/4 bg-borderColor mt-1 text-center"></div>{" "}
        </div>
        <div className="flex justify-end items-center gap-x-2 absolute top-6 right-6 w-full">
          <button
            onClick={closeModal}
            className="bg-faintBg  cursor-pointer  text-borderColor rounded-full"
          >
            <CancelOutlined />
          </button>
        </div>
        <div className="w-full inline-flex justify-center">
          <div className="flex mt-5 w-fit justify-center overflow-hidden items-center  bg-borderColor px-5 pt-8 rounded-t-full rounded-b-full ">
            <img src={Pet} className="w-44" alt="#" />
          </div>
        </div>
        <div className="flex mt-5  gap-5 sm:flex-row justify-center items-center">
          <button className="hover:bg-borderColor min-w-[30%] hover:text-white text-center border-2 hover:cursor-pointer border-borderColor flex justify-center items-cente rounded-xl text-black font-normal text-xl leading-40 tracking-wider px-4 py-2">
            + Add New
          </button>

          <button className="hover:bg-borderColor min-w-[30%] hover:text-white text-center border-2 hover:cursor-pointer border-borderColor flex justify-center items-center  rounded-xl text-black font-normal text-xl leading-40 tracking-wider px-4 py-2">
            Edit
          </button>
        </div>
      </div>
    </>
  );
};

export default EditProfilePicModal;
