import { apiSlice } from "../apiSlice";

export const petParentApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["MedicalRecords", "journals", "vaccine", "deworming"],

  endpoints: (builder) => ({
    createMedicalRecord: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/medicalrecords/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["MedicalRecords"],
    }),
    createVaccineRecord: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/vaccinationrecords/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["vaccine"],
    }),
    createDewormingRecord: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/dewormingrecords/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["deworming"],
    }),
    createJournal: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/journals",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["journals"],
    }),
    getAllJournalRecords: builder.query({
      query: (petId) => ({
        url: `/api/v1/petparent/journals?pet_id=${petId}`,
        method: "GET",
      }),
      providesTags: ["journals"],
    }),
    getAllVaccineRecords: builder.query({
      query: (petId) => ({
        url: `/api/v1/petparent/vaccinationrecords?pet_id=${petId}`,
        method: "GET",
      }),
      providesTags: ["vaccine"],
    }),
    getAllDewormingRecords: builder.query({
      query: (petId) => ({
        url: `/api/v1/petparent/dewormingrecords?pet_id=${petId}`,
        method: "GET",
      }),
      providesTags: ["deworming"],
    }),
    getAllMedicalRecords: builder.query({
      query: (petId) => ({
        url: `/api/v1/petparent/medicalrecords?pet_id=${petId}`,
        method: "GET",
      }),
      providesTags: ["MedicalRecords"],
    }),
    getVaccineDetails: builder.query({
      query: (id) => ({
        url: `/api/v1/petparent/vaccinationrecords/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "vaccine", id }],
    }),
    geDewormingDetails: builder.query({
      query: (id) => ({
        url: `/api/v1/petparent/dewormingrecords/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "deworming", id }],
    }),
    getMedicalRecordDetails: builder.query({
      query: (id) => ({
        url: `/api/v1/petparent/medicalrecords/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "MedicalRecords", id }],
    }),
    updateMedicalRecord: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/petparent/medicalrecords/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        "MedicalRecords",
        { type: "MedicalRecords", id },
      ],
    }),
    updateVaccine: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/petparent/vaccinationrecords/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        "vaccine",
        { type: "vaccine", id },
      ],
    }),
    updateDeworming: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/petparent/dewormingrecords/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        "deworming",
        { type: "deworming", id },
      ],
    }),
    updateJournalRecord: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/petparent/journals/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        "journals",
        { type: "journals", id },
      ],
    }),
    deleteJournal: builder.mutation({
      query: (id) => ({
        url: `/api/v1/petparent/journals/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["journals"],
    }),
    deleteMedicalRecord: builder.mutation({
      query: (id) => ({
        url: `/api/v1/petparent/medicalrecords/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MedicalRecords"],
    }),
    deleteVaccine: builder.mutation({
      query: (id) => ({
        url: `/api/v1/petparent/vaccinationrecords/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["vaccine"],
    }),
    deleteDeworming: builder.mutation({
      query: (id) => ({
        url: `/api/v1/petparent/dewormingrecords/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["deworming"],
    }),
  }),
});

export const {
  useCreateMedicalRecordMutation,
  useCreateDewormingRecordMutation,
  useCreateVaccineRecordMutation,
  useCreateJournalMutation,
  useGetAllMedicalRecordsQuery,
  useGetAllVaccineRecordsQuery,
  useGetAllDewormingRecordsQuery,
  useGetVaccineDetailsQuery,
  useGeDewormingDetailsQuery,

  useGetAllJournalRecordsQuery,
  useUpdateJournalRecordMutation,
  useGetMedicalRecordDetailsQuery,
  useUpdateMedicalRecordMutation,
  useDeleteMedicalRecordMutation,
  useUpdateVaccineMutation,
  useUpdateDewormingMutation,

  useDeleteVaccineMutation,
  useDeleteDewormingMutation,

  useDeleteJournalMutation,
} = petParentApiSlice;
