import React from 'react';
import TableCell from "@mui/material/TableCell";

const TableHeadCellComponent = ({ children, align = "center" }) => {
  return (
    <TableCell
      align={align}
      style={{
        fontFamily: "inherit",
        fontWeight: "bold",
        fontSize: "13px",
      }}
    >
      {children}
    </TableCell>
  );
};

export default TableHeadCellComponent;
