import React, { useState } from "react";
import { Search } from "@mui/icons-material";
import BlogImg from "assets/img/BlogImg1.png";
import { serviceData } from "helper/constants/ServiceData";
import ServiceViewModal from "components/Common/Modal/ServiceViewModal";
import { useNavigate } from "react-router-dom";
import ModalWrapper from "components/Common/Modal/ModalWrapper";
import { useSelector } from "react-redux";
import { MdGpsFixed } from "react-icons/md";

function MapModel({ show, children, width, onClose }) {
  return (
    show && (
      <div
        className="fixed  top-0 left-0 w-screen h-screen z-10 
      "
      >
        <div
          onClick={onClose}
          className="absolute w-full h-full bg-black opacity-50 z-10 "
        ></div>
        <div
          style={{
            width: `${width}%`,
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          className="bg-white p-5  z-20 absolute rounded-2xl  max-h-[90%] overflow-y-scroll no-scrollbar"
        >
          {children}
        </div>
      </div>
    )
  );
}

const Services = () => {
  const serviceDummyData = {
    petName: "Rocky",
    serviceName: "Dog Walking",
    dateOfService: "2024-07-01",
    durationOfService: "2",
    serviceProvider: "John Doe",
    location: "Central Park",
    additionalNotes: "Please make sure Rocky is walked in the shaded areas.",
    costOfService: "499",
  };
  const user = JSON.parse(localStorage.getItem("boop_customer_user"));
  console.log({ user });
  const selectedpet = useSelector((state) => state?.petsetting?.activepet);

  const [open, setOpen] = React.useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);

  const navigate = useNavigate();
  const [locmodel, setlocmodel] = useState(false);
  const handleNavigation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentPosition({ lat: latitude, lng: longitude });
          navigate(`/home/services/all?lat=${latitude}&lng=${longitude}`);

          // const response = await fetch(
          //   `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAQ3UQ8J8cu9LNJ4kvwO5J5nbCFKJmET3Q`
          // );
          // const data = await response.json();

          // if (data.results.length > 0) {
          //   const locationInfo = {
          //     latitude: position.lat,
          //     longitude: position.lng,
          //     address: data.results[0].formatted_address,
          //     city: "",
          //     state: "",
          //     country: "",
          //   };

          //   // Extract city, state, and country from address components
          //   data.results[0].address_components.forEach((component) => {
          //     if (component.types.includes("locality")) {
          //       locationInfo.city = component.long_name;
          //     }
          //     if (component.types.includes("administrative_area_level_1")) {
          //       locationInfo.state = component.long_name;
          //     }
          //     if (component.types.includes("country")) {
          //       locationInfo.country = component.long_name;
          //     }
          //   });
          //   // setFieldValue(
          //   //   "longitude_latitude",
          //   //   JSON.stringify({
          //   //     longitude: locationInfo.longitude,
          //   //     latitude: locationInfo.latitude,
          //   //   }),
          //   // );
          //   // setFieldValue("city", locationInfo?.city);

          //   // setFieldValue("location_address", locationInfo?.address);
          //   // setFieldValue("state", locationInfo?.state);
          //   // setLocationDetails(locationInfo);
          // }
        },
        (error) => {
          console.error("Error getting location", error);
          setCurrentPosition({ lat: 37.7749, lng: -122.4194 });
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setCurrentPosition({ lat: 37.7749, lng: -122.4194 });
    }
    // navigate("/home/services/all");
  };
  const [query, setQuery] = useState("");
  return (
    <>
      <MapModel show={locmodel} width={30} onClose={() => setlocmodel(false)}>
        <div className="w-full flex flex-col gap-3">
          <button
            onClick={handleNavigation}
            className="border border-orange-500 rounded-full p-2 flex flex-row items-center justify-center gap-5"
          >
            <MdGpsFixed className="text-orange-500" size={30} />
            <span>Current Location</span>
          </button>
          <button className="border border-orange-500 rounded-full p-2">
            Select on Map
          </button>
        </div>
      </MapModel>
      <div className="w-full bg-cardBg xl:py-10 py-10 px-8 overflow-hidden">
        <div className="px-8 xl:px-0 flex flex-col justify-center items-center">
          <div className="text-black font-poppins text-4xl font-semibold  px-0 w-full flex justify-center">
            Services for you?
          </div>
          <div class="h-1 w-1/12 bg-borderColor mt-1"></div>{" "}
        </div>
        {/* <div className="flex justify-between items-start mt-5">
          <div className="inline-flex justify-start items-center gap-4">
            <div>
              <input
                type="text"
                className="flex py-2 px-4  items-start rounded-lg bg-white shadow-base border-none outline-none"
                placeholder="Enter location..."
              />
            </div>
            <div className="relative">
              <input
                type="text"
                className="flex py-2 px-4  items-start rounded-lg bg-white shadow-base border-none outline-none"
                placeholder="Search..."
              />
              <Search className="absolute right-3 top-3 text-gray-500 pointer-events-none" />
            </div>
          </div>
          <button
            onClick={handleNavigation}
            className="flex w-[150px] h-[45px] bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md  "
          >
            <p className="font-poppins font-medium text-justify text-xl">
              Search
            </p>
          </button>
        </div> */}

        <div className="w-full relative  mt-10 bg-cardBg flex-wrap flex  justify-evenly items-center gap-x-8">
          <div
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
            className="absolute p-4  flex flex-col items-center justify-center gap-5 w-[80%]"
          >
            <div className="flex flex-col backdrop-blur-3xl rounded-xl p-3">
              <p className="font-poppins text-white text-4xl font-semibold text-center ">
                Hey{" "}
                <span className="text-borderColor">
                  {user?.user?.first_name || "Tommy"}
                </span>
              </p>

              <p className="font-poppins text-white text-2xl font-semibold text-center ">
                Which services are you looking for?
              </p>
            </div>
            <div className="flex flex-row items-center justify-center gap-3 w-[60%]">
              <button onClick={() => setlocmodel(true)}>
                {" "}
                <MdGpsFixed className="text-orange-500" size={30} />
              </button>

              <input
                className="w-full  p-2 rounded-lg"
                placeholder="Search for service"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <button
                onClick={() => navigate(`/home/services/all?query=${query}`)}
                className="flex w-[150px] h-[45px] bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md  "
              >
                <p className="font-poppins font-medium text-justify text-xl">
                  Explore
                </p>
              </button>
            </div>
          </div>

          <img
            src={BlogImg}
            alt="#"
            className="h-96 w-full rounded-2xl object-cover overflow-hidden"
          />

          <div className="w-full  mt-10 absolute bottom-[-75px] flex-wrap flex px-10 justify-evenly items-center gap-x-">
            <div className="w-full gap-2 flex-wrap flex justify-evenly items-center ">
              {serviceData.map((data) => (
                <div
                  onClick={() => navigate(`/home/services/all?cat=${data.title}`)}
                  className="bg-white cursor-pointer  shadow-PetProfileCardShadow gap-3 rounded-xl flex flex-col justify-center items-center p-4 w-fit min-h-[108px]">
                  <span className="bg-white rounded-full p-5 border-4 hover:border-borderColor ">
                    <img className=" w-8 h-7 " src={data.img} alt="#" />
                  </span>
                  <p className="font-poppins text-xs text-[#95908B] hover:text-borderColor font-bold">
                    {data.title}
                  </p>
                </div>
              ))}
            </div>{" "}
          </div>
        </div>

        <div className="flex gap-8 mt-40 flex-col justify-normal items-center">
          <div className="px-8 xl:px-0  flex flex-col justify-center items-center">
            <div className="text-black font-poppins text-4xl font-semibold  px-0 ">
              Recently Completed Service
            </div>
            <div class="h-[2px] w-4/12 bg-borderColor mt-1"></div>{" "}
          </div>

          <div className="w-full flex gap-x-12 justify-evenly items-center ">
            {[1, 2, 3].map(() => (
              <div className="w-4/12">
                <img
                  className="w-full rounded-t-xl "
                  src={BlogImg}
                  alt="#img"
                />
                <div className="flex justify-between items-baseline p-4 bg-white rounded-b-xl">
                  <span className="inline-flex justify-normal items-start gap-2 flex-col">
                    <p className="text-left font-poppins text-2xl font-semibold text-black">
                      Dog Walking
                      <div class="h-[3px] w-6/12 bg-borderColor mt-1"></div>{" "}
                    </p>
                    <p className="text-left font-poppins text-2xl font-semibold text-black">
                      ₹ 500
                    </p>
                  </span>
                  <div>
                    <button
                      onClick={() => setOpen(true)}
                      className="inline-flex w-20 justify-center items-center rounded-[27px] bg-[#D99149] px-2 py-1 cursor-pointer hover:bg-[#d9a067]"
                    >
                      <p className="font-poppins text-lg font-medium text-white">
                        View
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <ModalWrapper open={open} handleClose={() => setOpen(false)}>
        <ServiceViewModal
          closeModal={() => setOpen(false)}
          serviceData={serviceDummyData}
        />
      </ModalWrapper>
    </>
  );
};

export default Services;