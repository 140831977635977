import { CancelOutlined } from "@mui/icons-material";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const ServiceViewModal = ({ closeModal, serviceData }) => {
  const {
    petName,
    serviceName,
    dateOfService,
    durationOfService,
    serviceProvider,
    location,
    additionalNotes,
    costOfService,
  } = serviceData;

  return (
    <div className="w-full">
      <div className="text-black inline-flex flex-col justify-center items-center min-w-full font-poppins text-4xl font-semibold">
        Dog Walking
        <div className="h-[1px] w-1/4 bg-borderColor mt-1 text-center"></div>
      </div>
      <div className="flex justify-end items-center gap-x-2 absolute top-6 right-6 w-full">
        <button
          onClick={closeModal}
          className="bg-faintBg cursor-pointer text-borderColor rounded-full"
        >
          <CancelOutlined />
        </button>
      </div>
      <div className="flex justify-between items-start my-5">
        <div className="space-y-2 w-[50%]">
          <div className="w-10/12">
            <p className="font-poppins font-bold text-xs">Pet Name</p>

            <div className="font-poppins px-4 text-xs font-normal w-full bg-[#F3F4F6] h-7 rounded-[2px] flex items-center">
              {petName}
            </div>
          </div>
          <div className="w-10/12">
            <p className="font-poppins font-bold text-xs">Service Name</p>
            <div className="font-poppins px-4 text-xs font-normal w-full bg-[#F3F4F6] h-7 rounded-[2px] flex items-center">
              {serviceName}
            </div>
          </div>
          <div className="w-10/12">
            <p className="font-poppins font-bold text-xs">Date Of Service</p>
            <div className="font-poppins px-4 text-xs font-normal w-full bg-[#F3F4F6] h-7 rounded-[2px] flex items-center">
              {dateOfService}
            </div>
          </div>
          <div className="w-10/12">
            <p className="font-poppins font-bold text-xs">
              Duration Of Service (in hours)
            </p>
            <div className="font-poppins px-4 text-xs font-normal w-full bg-[#F3F4F6] h-7 rounded-[2px] flex items-center">
              {durationOfService}
            </div>
          </div>
          <div className="w-10/12">
            <p className="font-poppins font-bold text-xs">Service Provider</p>
            <div className="font-poppins px-4 text-xs font-normal w-full bg-[#F3F4F6] h-7 rounded-[2px] flex items-center">
              {serviceProvider}
            </div>
          </div>
          <div className="w-10/12">
            <p className="font-poppins font-bold text-xs">Location</p>
            <div className="font-poppins px-4 text-xs font-normal w-full bg-[#F3F4F6] h-7 rounded-[2px] flex items-center">
              {location}
            </div>
          </div>
        </div>
        <div className="flex justify-normal items-start gap-2 w-[50%] flex-col">
          <p className="font-poppins font-bold text-xs">Additional notes</p>
          <div className="w-full p-4 h-[195px] font-normal flex-shrink-0 bg-[#F3F4F6]">
            {additionalNotes}
          </div>
        </div>
      </div>
      <div className="h-[1px] w-full bg-borderColor mt-1 text-center"></div>
      <div className="text-black inline-flex justify-between items-center min-w-full my-1">
        <p className="font-poppins text-2xl font-bold">Cost Of Service :</p>
        <p className="font-poppins text-3xl font-bold text-borderColor">
          ₹{costOfService}
        </p>
      </div>
    </div>
  );
};

export default ServiceViewModal;
