import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendOtp: builder.mutation({
      query: (data) => ({
        url: "api/v1/petparent/auth/send-otp",
        method: "POST",
        body: data,
      }),
    }),
    signUp: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/auth/signup",
        method: "POST",
        body: data,
      }),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/auth/login",
        method: "POST",
        body: data,
      }),
    }),
    sendLoginOtp: builder.mutation({
      query: (data) => ({
        url: "api/v1/petparent/auth/send-login-otp",
        method: "POST",
        body: data,
      }),
    }),
    checkUser: builder.mutation({
      query: (query) => ({
        url: `api/v1/petparent/auth/check-user?${query}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useSendOtpMutation,
  useLoginMutation,
  useSignUpMutation,
  useSendLoginOtpMutation,
  useCheckUserMutation,
} = authApiSlice;
