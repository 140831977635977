import { apiSlice } from "../apiSlice";

export const petParentApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["user"],

  endpoints: (builder) => ({
    getParentProfile: builder.query({
      query: () => ({
        url: "/api/v1/petparent/profile",
        method: "GET",
      }),
      providesTags: ["user"],
    }),
    updateParentProfile: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/profile",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    deleteParentProfile: builder.mutation({
      query: (id) => ({
        url: `/api/v1/petparent/profile/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["user"],
    }),
  }),
});

export const {
  useGetParentProfileQuery,
  useUpdateParentProfileMutation,
  useDeleteParentProfileMutation,
} = petParentApiSlice;
