/* eslint-disable no-undef */
const config = {
  environment: process.env.REACT_APP_ENV || "production",
  endpoints: {
    prod: "https://api-dev.booppetcare.com",
    dev: "https://boop-dev-xbpsu3dq4q-el.a.run.app",
    local: "http://localhost:8001",
  },

  getEndpoint() {
    switch (this.environment) {
      case "local":
        return this.endpoints.local;
      case "development":
        return this.endpoints.dev;
      case "production":
        return this.endpoints.prod;
      default:
        return "https://api-dev.booppetcare.com";
    }
  },
};

export default config;
