import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  MedicalRecords: [
    {
      id: "1",
      admin_date: "2024-04-15",
      med_name: "Rabbies",
      due_date: "2024-04-15",
      consult_doc: "Dr. Smith",
      med_type: "Syrup",
    },
    {
      id: "2",
      admin_date: "2024-04-16",
      med_name: "Parvo",
      due_date: "2024-05-16",
      consult_doc: "Dr. Adams",
      med_type: "Injection",
    },
    {
      id: "3",
      admin_date: "2024-04-17",
      med_name: "Distemper",
      due_date: "2024-06-17",
      consult_doc: "Dr. Clark",
      med_type: "Tablet",
    },
  ],
  DewormingRecord: [
    {
      id: "1",
      admin_date: "2024-04-15",
      med_name: "Rabbies",
      due_date: "2024-04-15",
      consult_doc: "Dr. Smith",
      doc: "Doc1.pdf",
    },
    {
      id: "2",
      admin_date: "2024-04-16",
      med_name: "Parvo",
      due_date: "2024-04-20",
      consult_doc: "Dr. Brown",
      doc: "Doc2.pdf",
    },
    {
      id: "3",
      admin_date: "2024-05-10",
      med_name: "Distemper",
      due_date: "2024-05-15",
      consult_doc: "Dr. Taylor",
      doc: "Doc3.pdf",
    },
  ],
  JournalRecord: [
    {
      id: "1",
      date: "2024-04-15",
      type: "Allergy",
      additionalInfo: "Vaccination",
      photos: "Photo1.jpg",
    },
    {
      id: "2",
      date: "2024-04-16",
      type: "Vomitus",
      additionalInfo: "Changed Diet Plan",
      photos: "Photo2.jpg",
    },
    {
      id: "3",
      date: "2024-04-17",
      type: "Feces",
      additionalInfo: "Feces",
      photos: "Photo3.jpg",
    },
    {
      id: "3",
      date: "2024-04-17",
      type: "Heat",
      additionalInfo: "Heat",
      photos: "Photo3.jpg",
    },
  ],
  VaccinationRecord: [
    {
      id: "1",
      vaccine_name: "COVID-19",
      admin_type: "Intramuscular",
      admin_date: "2024-01-10",
      due_date: "2025-01-10",
      consult_doc: "Dr. Johnson",
      admin_site: "Right arm",
      note: "Mild fever post-vaccination",
      photo: "",
    },

    {
      id: "2",
      vaccine_name: "Influenza",
      admin_type: "Intramuscular",
      admin_date: "2024-06-15",
      due_date: "2025-06-15",
      consult_doc: "Dr. Smith",
      admin_site: "Left arm",
      note: "No adverse reactions",
      photo: "",
    },

    {
      id: "3",
      vaccine_name: "Hepatitis B",
      admin_type: "Subcutaneous",
      admin_date: "2023-12-20",
      due_date: "2024-12-20",
      consult_doc: "Dr. Lee",
      admin_site: "Left thigh",
      note: "Slight swelling at injection site",
      photo: "",
    },
  ],
};

export const HealthHub = createSlice({
  name: "pet",
  initialState,
  reducers: {
    createMedicalRecord: (state, action) => {
      state.MedicalRecords.push(action.payload);
    },
    updateMedicalRecord: (state, action) => {
      const { id, updatedRecord } = action.payload;
      const index = state.MedicalRecords.findIndex(
        (record) => record.id === id
      );
      if (index !== -1) {
        state.MedicalRecords[index] = {
          ...state.MedicalRecords[index],
          ...updatedRecord,
        };
      }
    },
    deleteMedicalRecord: (state, action) => {
      const id = action.payload;
      state.MedicalRecords = state.MedicalRecords.filter(
        (record) => record.id !== id
      );
    },
    createJournalRecord: (state, action) => {
      const record = {
        ...action.payload,
        date: dayjs(action.payload.date).format("YYYY-MM-DD"),
      };
      state.JournalRecord.push(record);
    },
    updateJournalRecord: (state, action) => {
      const { id, updatedRecord } = action.payload;
      const index = state.JournalRecord.findIndex((record) => record.id === id);
      if (index !== -1) {
        state.JournalRecord[index] = {
          ...state.JournalRecord[index],
          ...updatedRecord,
        };
      }
    },
    deleteJournalRecord: (state, action) => {
      const date = action.payload;
      state.JournalRecord = state.JournalRecord.filter(
        (record) => record.date !== date
      );
    },
    createVaccinationRecord: (state, action) => {
      const record = {
        ...action.payload,
        admin_date: dayjs(action.payload.admin_date).format("YYYY-MM-DD"),
        due_date: dayjs(action.payload.due_date).format("YYYY-MM-DD"),
      };
      state.VaccinationRecord.push(record);
    },
    updateVaccinationRecord: (state, action) => {
      const { id, updatedRecord } = action.payload;
      const index = state.VaccinationRecord.findIndex(
        (record) => record.id === id
      );
      if (index !== -1) {
        state.VaccinationRecord[index] = {
          ...state.VaccinationRecord[index],
          ...updatedRecord,
        };
      }
    },
    deleteVaccinationRecord: (state, action) => {
      const id = action.payload;
      state.VaccinationRecord = state.VaccinationRecord.filter(
        (record) => record.id !== id
      );
    },
    createDewormingRecord: (state, action) => {
      state.DewormingRecord.push(action.payload);
    },
    updateDewormingRecord: (state, action) => {
      const { id, updatedRecord } = action.payload;
      const index = state.DewormingRecord.findIndex(
        (record) => record.id === id
      );
      if (index !== -1) {
        state.DewormingRecord[index] = {
          ...state.DewormingRecord[index],
          ...updatedRecord,
        };
      }
    },
    deleteDewormingRecord: (state, action) => {
      const id = action.payload;
      state.DewormingRecord = state.DewormingRecord.filter(
        (record) => record.id !== id
      );
    },
    updateAllRecords: (state, action) => {
      const {
        MedicalRecords,
        DewormingRecord,
        JournalRecord,
        VaccinationRecord,
      } = action.payload;
      state.MedicalRecords = MedicalRecords || state.MedicalRecords;
      state.DewormingRecord = DewormingRecord || state.DewormingRecord;
      state.JournalRecord = JournalRecord || state.JournalRecord;
      state.VaccinationRecord = VaccinationRecord || state.VaccinationRecord;
    },
  },
});

export const {
  createVaccinationRecord,
  updateVaccinationRecord,
  deleteVaccinationRecord,
  createDewormingRecord,
  updateDewormingRecord,
  deleteDewormingRecord,
  createJournalRecord,
  updateJournalRecord,
  deleteJournalRecord,
  createMedicalRecord,
  updateMedicalRecord,
  deleteMedicalRecord,
  updateAllRecords,
} = HealthHub.actions;

export const selectMedicalRecordById = (state, id) =>
  state.HealthHub.MedicalRecords.find((record) => record.id === id);

export const selectVaccinationRecordById = (state, id) =>
  state.HealthHub.VaccinationRecord.find((record) => record.id === id);

export default HealthHub.reducer;
