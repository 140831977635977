import React, { useEffect, useState } from "react";
import LoginPage from "pages/AuthPages/LoginPage";
import "./App.css";
import LaptopViewSignUp from "pages/AuthPages/LaptopViewSignUp";
import MobileViewSignUp from "pages/AuthPages/MobileViewSignUp";
import Dashboard from "components/PetManagement/PetProfile";
import Services from "components/PetManagement/Services";
import HealthHub from "components/PetManagement/HealthHub";
import Settings from "components/PetManagement/Settings";
import ProfileCreation from "components/Common/Modal/ProfileCreation";
import PetProfileCreation from "components/Common/Modal/ProfileCreation";
import LandingPage from "pages/LandingPage/LandingPage";
import IndividualDewormingRecord from "components/PetManagement/HealthHub/IndividualDewormingRecord";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import SideBarLayout from "layout/SideBarLayout";
import AddPet from "components/PetManagement/AddPet";
import VaccinationRecord from "components/PetManagement/HealthHub/VaccinationRecord";
import DewormingRecord from "components/PetManagement/HealthHub/DewormingRecord";
import JournalRecord from "components/PetManagement/HealthHub/JournalRecord";
import MedicalRecords from "components/PetManagement/HealthHub/MedicalRecords";
import IndividualMedicalRecord from "components/PetManagement/HealthHub/IndividualMedicalRecord";
import IndividualVaccinationRecord from "components/PetManagement/HealthHub/IndividualVaccinationRecord";
import AppointementList from "components/PetManagement/cards/AppointementList";
import ViewParticularAppointement from "components/PetManagement/cards/ViewParticularAppointenment";
import ExploreServiceList from "components/PetManagement/cards/ExploreServiceList";
import ServiceOverview from "components/PetManagement/Services/ServiceOverview";
import Cart from "components/PetManagement/Services/Cart";
import { useGetAllPetsQuery } from "store/settings/petprofileSlice";
import { useDispatch } from "react-redux";
import { setpets } from "store/StateSlice/settingSlice";
import AuthenticatedRedirects from "routes/AuthenticatedRedirects";
function App() {
  const [isMobile, setIsMobile] = useState(false);
  const { data } = useGetAllPetsQuery();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setpets(data?.pets?.rows));
  }, [data]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <React.Fragment>
      <Toaster
        toastOptions={{
          className: "font-poppins text-base rounded",
          success: {
            style: {
              background: "#EBEDF7",
              color: "#1C2135",
              padding: "6px 20px",
            },
          },
          error: {
            style: {
              background: "#EBEDF7",
              color: "#1C2135",
              padding: "6px 20px",
            },
          },
        }}
        position="top-center"
      />

      <div className="w-full h-full ">
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/sign-up"
            element={isMobile ? <MobileViewSignUp /> : <LaptopViewSignUp />}
          />

          <Route path="/profile-creation" element={<PetProfileCreation />} />
          <Route path="/" element={<AuthenticatedRedirects />}>
            <Route path="/profile-creation" element={<ProfileCreation />} />
            <Route
              path="/"
              element={<Navigate to="/dashboard" />}
            />
            <Route path="/" element={<SideBarLayout />}>
              <Route path="add-pet" element={<AddPet />} />
            </Route>
            <Route path="/home" element={<SideBarLayout />}>
              <Route path="cart" element={<Cart />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="appointements" element={<AppointementList />} />
              <Route
                path="appointements/individual"
                element={<ViewParticularAppointement />}
              />
              <Route path="services" element={<Services />} />
              <Route path="services/all" element={<ExploreServiceList />} />
              <Route path="services/all/:id" element={<ServiceOverview />} />
              <Route path="health-hub" element={<HealthHub />} />
              <Route path="settings" element={<Settings />} />
              <Route
                path="health-hub/medical-records"
                element={<MedicalRecords />}
              />
              <Route
                path="health-hub/medical-records/:id"
                element={<IndividualMedicalRecord />}
              />
              <Route
                path="health-hub/vaccination-records/:id"
                element={<IndividualVaccinationRecord />}
              />
              <Route
                path="health-hub/journal/individual"
                element={<IndividualVaccinationRecord />}
              />
              <Route
                path="health-hub/deworming/:id"
                element={<IndividualDewormingRecord />}
              />
              <Route
                path="health-hub/vaccination-records"
                element={<VaccinationRecord />}
              />
              <Route path="health-hub/deworming" element={<DewormingRecord />} />
              <Route path="health-hub/journal" element={<JournalRecord />} />

            </Route>
          </Route>


        </Routes>
      </div>
    </React.Fragment>
  );
}

export default App;
