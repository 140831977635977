import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';// Assuming you are using react-router for navigation
import checkImg from "assets/img/checkImg.png";
import LoginLogo from "assets/img/LoginLogo.png";
import LoadingScreen from "components/Common/Loader"; // Import the loading screen

const ProfileCreation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      // Simulate a network request
      await new Promise((resolve) => setTimeout(resolve, 5000)); // Simulate a delay of 5 seconds
      // If you're making a real API call, you would use something like:
      // const response = await fetch('/api/endpoint');
      // const data = await response.json();
      // Handle your data here
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleGoToHome = async () => {
    setIsLoading(true);
    await fetchData();
    setIsLoading(false);
    navigate("/home/dashboard"); // Navigate to home page
  };

  const handleAddPet = async () => {
    navigate("/add-pet"); // Navigate
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="flex flex-col mx-auto mt-[8%] justify-center items-center gap-[20px] bg-LoginCard backdrop-blur-LoginBlur max-w-[40%] p-8 rounded-3xl border-2 border-borderColor">
      <div className="justify-center flex">
        <img src={LoginLogo} className="w-[160px] h-[50px]" alt="Name Icon" />
      </div>
      <div>
        <img src={checkImg} alt="img" className="w-[90px] h-[90px]" />
      </div>
      <div className="flex-col w-12/12 gap-[20px] flex justify-center items-center text-center">
        <div className="font-poppins w-full font-bold leading-10 text-3xl text-center">
          Your Profile has been <br /> Sucessfully Created!
        </div>
        <div className="font-poppins w-[400px] text-justify font-normal text-sm text-wrap">
          Hooray, Your profile has been successfully created, Your next step is to create a pet profile!
        </div>
      </div>
      <div className="flex mt-5 w-full gap-5 sm:flex-row justify-center items-center">
        <button
          className="bg-borderColor min-w-[30%] text-white text-center cursor-pointer flex justify-center items-center rounded-[9px] font-normal text-xl leading-40 tracking-wider px-4 py-2"
          onClick={handleGoToHome}
        >

          Go to Home
        </button>
        <button onClick={handleAddPet} className="bg-[#FFEEDE] min-w-[30%] text-borderColor text-center cursor-pointer flex justify-center items-center rounded-[9px] font-normal text-xl leading-40 tracking-wider px-4 py-2">
          Add a pet +
        </button>
      </div>
    </div>
  );
};

export default ProfileCreation;
