import React, { useEffect, useState } from "react";
import BlogImg from "assets/img/BlogImg1.png";
import location from "assets/svg/location.svg";
import { StarIcon } from "lucide-react";
import ServiceCardV2 from "../cards/ServiceCardV2";
import ServiceCard from "../cards/ServiceCard";
import profileImg from "assets/img/PetImg.jpg";
import BookingModal from "components/Common/Modal/BookingModal";
import ModalWrapper from "components/Common/Modal/ModalWrapper";
import { BiSolidDog } from "react-icons/bi";
import { FaSchool } from "react-icons/fa";
import { GiComb } from "react-icons/gi";
import { PiBoneLight } from "react-icons/pi";
import { RiHeartsLine } from "react-icons/ri";
import { PiTaxi } from "react-icons/pi";
import { TbHomeHeart } from "react-icons/tb";

import { useParams } from "react-router-dom";
import { useGetServiceDetailsQuery } from "store/Services/serviceApiSlice";
import ReactSelect from "react-select";
const servicesData = [
  {
    id: 1,
    location: "Kukatpally, Hyderabad, Telangana",
    name: "Pet Cleaning Servicing",
    rating: 4.2,
    reviews: "5.4K Reviews",
    price: "₹399.00 - ₹699.00",
    duration: "30 minutes",
    image: BlogImg,
    services: [
      "Service 1",
      "Service 2",
      "Service 3",
      "Service 4",
      "Service 5",
      "Service 6",
    ],
    topPick: true,
  },
];

function Symbol({ service_type, size }) {
  if (service_type == "Boarding") return <FaSchool size={size} />;
  if (service_type == "Grooming") return <GiComb size={size} />;
  if (service_type == "Training") return <PiBoneLight size={size} />;
  if (service_type == "Day care") return <RiHeartsLine size={size} />;
  if (service_type == "Pet taxi") return <PiTaxi size={size} />;
  if (service_type == "Vets") return <TbHomeHeart size={size} />;
}
const ServiceOverview = () => {
  const [bookingService, setbookingService] = useState(true);
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const { data: servicedata } = useGetServiceDetailsQuery(id);
  const [servicemodel, setservice] = useState();
  const [servicetypes, setservicetypes] = useState();
  useEffect(() => {
    const services = servicedata?.data?.services || [];

    const uniqueServiceTypes = new Set();

    services.forEach((service) => {
      if (service.service_type) {
        uniqueServiceTypes.add(service.service_type);
      }
    });
    setservicetypes([...uniqueServiceTypes]);
  }, [servicedata]);

  const [filters, setFilters] = useState({
    serviceType: "",
    serviceCategory: "",
    acnonac: "",
    outdoor_inddor: "",
    singhle_group: "",
    standard_medical_special: "",
    centerType: [],
    sortBy: ""
  });

  return (
    <>
      <div className="space-y-12 flex flex-col p-8 justify-normal">
        <div className="flex justify-center max-h-60 rounded-xl gap-x-4">
          <div className="w-7/12 overflow-hidden rounded-xl">
            <img
              src={
                servicedata?.data?.services[0]?.services_photos[0]?.file?.url ||
                BlogImg
              }
              alt="#"
              className="w-[100%]"
            />
          </div>
          <div className="w-5/12 space-y-3 p-4 max-h-60 bg-white relative rounded-xl">
            <div className="justify-normal items-center gap-2 flex">
              <img src={location} alt="location" />
              <p className="text-[#888] font-poppins text-base font-normal">
                {servicedata?.data?.location_address}, {servicedata?.data?.city}
                , {servicedata?.data?.state}
              </p>
            </div>
            <p className="text-black text-2xl font-semibold font-poppins">
              {servicedata?.data?.service_name}
            </p>

            <div className="flex gap-2 w-fit justify-center items-center">
              <div className="bg-cardBg w-fit flex rounded-xl gap-2  justify-center items-center p-2">
                <p className="text-xl font-poppins font-semibold text-borderColor">
                  4.5
                </p>
                <StarIcon className="text-borderColor" />
              </div>
              <p className="text-[#717171] font-poppins text-lg font-semibold">
                (5.4K Reviews)
              </p>
            </div>
            <div
              onClick={() => setbookingService(false)}
              className="cursor-pointer flex max-w-[146px] absolute bottom-4 right-4 rounded-xl justify-center items-center bg-borderColor py-2 px-4"
            >
              <p className="text-base font-poppins font-normal text-white">
                Book Service
              </p>
            </div>
          </div>
        </div>

        {bookingService ? (
          <>
            <div className="flex justify-normal items-start gap-4">
              <div className="w-5/12 ">
                <div className="text-black font-poppins text-xl font-semibold  px-0 ">
                  Services Offered?
                  <div class="h-1 w-2/12 bg-borderColor mt-1"></div>
                </div>
                <div className="w-full mt-5 gap-6 flex-wrap flex justify-normal items-center ">
                  {servicetypes?.map((data) => (
                    <div className="bg-white w-fit  shadow-PetProfileCardShadow gap-3 rounded-xl flex flex-col justify-center items-center p-4  min-h-[108px]">
                      <span className="bg-white rounded-full p-5 border-4 hover:border-borderColor text-gray-500 ">
                        <Symbol service_type={data} size={30} />
                      </span>
                      <p className="font-poppins text-xs text-[#95908B] hover:text-borderColor font-bold">
                        {data}
                      </p>
                    </div>
                  ))}
                </div>{" "}
              </div>
              <div className="w-7/12  gap-4 flex-wrap flex justify-normal items-start ">
                <div className="text-black font-poppins text-xl font-semibold  px-0 ">
                  Description
                  <div class="h-1 w-full bg-borderColor mt-1"></div>
                </div>

                <p className="text-lg font-normal text-justify  font-Nunito ">
                  {" "}
                  Tommy is a warmly welcomed member of our community, having
                  received full vaccination. His friendly demeanor is evident in
                  the positive interactions he has with everyone he meets,
                  creating a pleasant and inclusive atmosphere. On the other
                  hand, Charlie brings an extra layer of charm to our
                  environment. Not only is he affectionate, but his well-behaved
                  nature is also noteworthy.{" "}
                </p>

                <div class="flex justify-center items-center">
                  <div class="flex items-center space-x-4">
                    <div class="flex items-center space-x-[-10px]">
                      <img
                        src={profileImg}
                        alt="Pet 1"
                        class="w-[60px] h-[60px] rounded-full border-borderColor border-[3px]  min-w-[60px]"
                      />
                      <img
                        src={profileImg}
                        class="w-[60px] h-[60px] rounded-full border-borderColor border-[3px] min-w-[60px]"
                      />
                      <img
                        src={profileImg}
                        class="w-[60px] h-[60px] rounded-full border-borderColor border-[3px] min-w-[60px]"
                      />
                    </div>
                    <div class="flex items-center space-x-2 text-lg font-semibold">
                      <span class="text-borderColor">+1K</span>
                      <span class="text-gray-700">Pets Visited</span>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="flex flex-col justify-normal items-start gap-4">
              <div className="text-black font-poppins text-xl font-semibold  px-0 ">
                Top Reviews
                <div class="h-1 w-6/12 bg-borderColor mt-1"></div>
              </div>

              <div className="max-w-full ">
                <div className="flex gap-4 ">
                  {[1, 2].map((i) => (
                    <div
                      key={i}
                      className="bg-white flex flex-col justify-normal items-start rounded-xl gap-2 p-4 min-w-[500px]"
                    >
                      <div className="flex justify-between items-center w-full">
                        <div className="flex flex-col justify-normal items-start">
                          <p className="font-poppins text-xl font-normal">
                            Kousik Roy
                          </p>
                          <p className="font-Nunito text-sm font-light">
                            Pet Parents of Rocky
                          </p>
                        </div>
                        <div className="bg-cardBg w-fit flex rounded-xl gap-2 justify-center items-center px-4 py-2">
                          <p className="text-2xl font-poppins font-semibold text-black">
                            4.2
                          </p>
                          <StarIcon className="text-black" />
                        </div>
                      </div>
                      <p className="text-sm bg-faintBg text-borderColor rounded-xl px-6 py-1 font-poppins font-normal">
                        Grooming
                      </p>
                      <div>
                        <p className="text-Nunito text-lg font-normal text-[#757575] text-justify">
                          Tommy is a warmly welcomed member of our community,
                          having received full vaccination. His friendly
                          demeanor is evident in the positive interactions he
                          has with everyone he meets, creating a pleasant and
                          inclusive atmosphere.
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex justify-center items-center w-full my-4">
                {" "}
                <p className="font-poppins text-xl hover:underline hover:cursor-pointer text-center font-semibold text-borderColor">
                  {" "}
                  View All 5.4K Reviews{" "}
                </p>
              </div>
            </div>
            <div className="flex justify-center flex-col items-center w-full gap-4 mt-4 overflow-hidden">
              <div className="text-black font-poppins text-2xl font-bold px-0">
                Similar Services For You
                <div className="h-1 w-full bg-borderColor mt-1"></div>
              </div>
              <div className="w-full flex flex-col gap-y-5 mt-10">
                {/* {servicesData.map((data,i) => (
                  <ServiceCard key={i} service={data} />
                ))} */}
              </div>
              <div>
                {" "}
                <p className="font-poppins text-xl hover:underline hover:cursor-pointer text-center font-semibold text-borderColor">
                  {" "}
                  View All{" "}
                </p>
              </div>
            </div>{" "}
          </>
        ) : (
          <>
            <div className="flex flex-row  justify-center items-center w-full gap-3 flex-wrap ">

              <div className="w-full flex justify-center items-center flex-row gap-5 flex-wrap">
                {/* <ReactSelect
                  options={[
                    { label: "Kennel", value: "Kennel" },
                    { label: "Homeboarding", value: "Homeboarding" },
                    { label: "Flat", value: "Flat" },
                    { label: "Independent Residency", value: "Independent Residency" },
                    { label: "Commercial Property", value: "Commercial Property" },
                    { label: "Purpose built kennel", value: "Purpose built kennel" },
                  ]}
                  placeholder="Select Center Type"
                  value={filters.centerType}
                  isMulti
                  onChange={(e) => {
                    setFilters({ ...filters, centerType: e });
                  }}
                /> */}
                <div className="flex justify-evenly items-center py-2 font-lato text-xs lg:text-base font-normal text-black bg-white rounded-xl text-bold">
                  <select
                    className="bg-white w-full border-none outline-none rounded-lg pl-5 pr-5"
                    value={filters.serviceType}
                    onChange={(e) => {
                      setFilters({ ...filters, serviceType: e.target.value });
                    }}
                  >
                    <option value="">Select Service Type</option>
                    <option className="text-black" value={"Inbound"}>
                      Inbound
                    </option>
                    <option className="text-black" value={"Outbound"}>
                      Outbound
                    </option>
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="30"
                    viewBox="0 0 2 56"
                    fill="none"
                  >
                    <path
                      opacity="0.686151"
                      d="M1.02587 55V1"
                      stroke="#979797"
                      strokeWidth="0.3"
                      strokeLinecap="square"
                    />
                  </svg>
                </div>
                <div className="flex justify-evenly items-center py-2 font-lato text-xs lg:text-base font-normal text-black bg-white rounded-xl text-bold">
                  <select
                    className="bg-white w-full border-none outline-none rounded-lg pl-5 pr-5"
                    value={filters.serviceCategory}
                    onChange={(e) => {
                      setFilters({ ...filters, serviceCategory: e.target.value });
                    }}
                  >
                    <option value="">Select Service Category</option>
                    <option className="text-black" value={"Boarding"}>
                      Boarding
                    </option>
                    <option className="text-black" value={"Grooming"}>
                      Grooming
                    </option>
                    <option className="text-black" value={"Training"}>
                      Training
                    </option>
                    <option className="text-black" value={"Day care"}>
                      Day care
                    </option>
                    <option className="text-black" value={"Pet taxi"}>
                      Pet taxi
                    </option>
                    <option className="text-black" value={"Vets"}>
                      Vets
                    </option>
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="30"
                    viewBox="0 0 2 56"
                    fill="none"
                  >
                    <path
                      opacity="0.686151"
                      d="M1.02587 55V1"
                      stroke="#979797"
                      strokeWidth="0.3"
                      strokeLinecap="square"
                    />
                  </svg>
                </div>
                <div className="flex justify-evenly items-center py-2 font-lato text-xs lg:text-base font-normal text-black bg-white rounded-xl text-bold">
                  <select
                    className="bg-white w-full border-none outline-none rounded-lg pl-5 pr-5"
                    value={filters.sortBy}
                    onChange={(e) => {
                      setFilters({ ...filters, sortBy: e.target.value });
                    }}
                  >
                    <option value="">Sort By</option>
                    {[
                      "Price Low to High",
                      "Price High to Low",
                      "Customer Rating",
                    ].map((sort) => (
                      <option key={sort} className="text-black" value={sort}>
                        {sort}
                      </option>
                    ))}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="30"
                    viewBox="0 0 2 56"
                    fill="none"
                  >
                    <path
                      opacity="0.686151"
                      d="M1.02587 55V1"
                      stroke="#979797"
                      strokeWidth="0.3"
                      strokeLinecap="square"
                    />
                  </svg>
                </div>

                <div
                  onClick={() => {
                    // reset all the filters
                    setFilters({
                      serviceType: "",
                      serviceCategory: "",
                      acnonac: "",
                      outdoor_inddor: "",
                      singhle_group: "",
                      standard_medical_special: "",
                      centerType: []
                    });
                  }}
                  className="flex cursor-pointer justify-evenly items-center p-4 font-lato text-sm lg:text-base font-normal text-black bg-white rounded-xl text-bold">

                  <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.07963 2.61109V0.158691L2.01457 3.22419L5.07963 6.28969V3.83729C7.1087 3.83729 8.75771 5.48653 8.75771 7.51589C8.75771 9.54525 7.1087 11.1945 5.07963 11.1945C3.05056 11.1945 1.40156 9.54525 1.40156 7.51589H0.175537C0.175537 10.2258 2.37012 12.4207 5.07963 12.4207C7.78915 12.4207 9.98373 10.2258 9.98373 7.51589C9.98373 4.80599 7.78915 2.61109 5.07963 2.61109Z" fill="#EA0234" />
                  </svg>

                </div>
              </div>
            </div>
            {servicedata?.data?.services
              ?.filter((service) => {
                if (filters.serviceType) {
                  return service.inbound_outbound?.toLowerCase() === filters.serviceType?.toLowerCase();
                }
                return true;
              })
              ?.filter((service) => {
                if (filters.serviceCategory) {
                  return service.service_type?.toLowerCase() === filters.serviceCategory?.toLowerCase();
                }
                return true;
              })
              ?.filter((service) => {
                if (filters.acnonac) {
                  return service.service_accomodation?.ac_or_nonAc?.toLowerCase() === filters.acnonac?.toLowerCase();
                }
                return true;
              })
              ?.filter((service) => {
                if (filters.outdoor_inddor) {
                  return service.service_accomodation?.outdoor_or_indoor?.toLowerCase() === filters.outdoor_inddor?.toLowerCase();
                }
                return true;
              })
              ?.filter((service) => {
                if (filters.singhle_group) {
                  return service.service_accomodation?.single_or_group?.toLowerCase() === filters.singhle_group?.toLowerCase();
                }
                return true;
              })
              ?.filter((service) => {
                if (filters.standard_medical_special) {
                  return service.service_accomodation?.special_medical_care?.toLowerCase() === filters.standard_medical_special?.toLowerCase();
                }
                return true;
              })
              // ?.filter((service) => {
              //   if (filters.centerType) {
              //     return filters.centerType?.some((center) => service.service_accomodation?.center_type?.toLowerCase() === center.value?.toLowerCase());
              //   }
              //   return true;
              // })
              ?.sort((a, b) => {
                if (filters.sortBy === "Price Low to High") {
                  return a?.service_pricings?.[0]?.price - b?.service_pricings?.[0]?.price
                } else if (filters.sortBy === "Price High to Low") {
                  return b?.service_pricings?.[0]?.price - a?.service_pricings?.[0]?.price
                }
              })
              .map((rec) => (
                <div className="bg-white flex justify-center  p-4 rounded-xl gap-x-4">
                  <div className="w-4/12 relative">
                    <img
                      src={rec?.services_photos?.[0]?.file?.url || BlogImg}
                      alt="#"
                      className="w-full object-cover h-48  rounded-xl"
                    />
                  </div>
                  <div className="w-8/12 space-y-3 relative">
                    <div>
                      <p className="text-black text-2xl font-semibold font-poppins">
                        {rec?.service_name}, {rec?.service_type}
                      </p>

                      <p className="text-[#717171] font-poppins font-medium text-[16px] text-justify ">
                        {rec?.service_description}
                      </p>
                    </div>

                    <div className="inline-flex justify-normal items-center gap-2 ">
                      <div className="text-black font-poppins font-semibold text-base flex flex-wrap gap-3">
                        {rec?.service_pricings?.map((price) => (
                          <div className="flex flex-row bg-orange-100 rounded-2xl">
                            <span className="p-2  text-black">
                              {price?.price_modal}
                            </span>
                            <span className="p-2  text-black">
                              • {price?.price}₹
                            </span>
                          </div>
                        ))}
                      </div>{" "}
                      {rec?.duration && (
                        <p className="text-black font-poppins font-medium text-sm">
                          • {rec?.duration}
                        </p>
                      )}
                    </div>

                    <button
                      onClick={() => {
                        setModalOpen(true);
                        setservice(rec);
                      }}
                      className="flex absolute bottom-0 max-w-[146px] mt-2 rounded-xl justify-center items-center bg-borderColor py-2 px-4"
                    >
                      <p className="text-base font-poppins font-normal text-white">
                        Book Service
                      </p>
                    </button>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>

      <ModalWrapper open={modalOpen} handleClose={() => setModalOpen(false)}>
        <BookingModal
          closeModal={() => setModalOpen(false)}
          services={servicedata?.data}
          servicedata={servicemodel}
        />
      </ModalWrapper>
    </>
  );
};

export default ServiceOverview;
