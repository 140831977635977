import React, { useState } from "react";
import DashboardFilter from "components/Common/DashboardFilter";
import AppointmentsTable from "../Tables/AppointementsTable";
import { useGetpetAppointmentsQuery } from "store/Services/serviceApiSlice";
const initialData = [
  {
    date: "30 April 2024",
    name: "Husky",
    service: "Boarding",
    provider: "Provider",
    time: "8:00 AM - 9:00 PM",
    location: "Hyderabad",
    status: "Completed",
  },
  {
    date: "30 April 2024",
    name: "Musky",
    service: "Boarding",
    provider: "Provider",
    time: "8:00 AM - 9:00 PM",
    location: "Australia",
    status: "Completed",
  },
  {
    date: "30 April 2024",
    name: "Husky",
    service: "OffBoarding",
    provider: "Provider",
    time: "8:00 AM - 9:00 PM",
    location: "America",
    status: "Processing",
  },
  {
    date: "30 April 2024",
    name: "Husky",
    service: "Boarding",
    provider: "Consumer",
    time: "8:00 AM - 9:00 PM",
    location: "Hyderabad",
    status: "Completed",
  },
  {
    date: "30 April 2024",
    name: "kucky",
    service: "OffBoarding",
    provider: "Provider",
    time: "8:00 AM - 9:00 PM",
    location: "Nagaland",
    status: "Rejected",
  },
];

const AppointementFilterFields = [
  { name: "name", label: "Pet Name", options: ["Husky", "Musky", "kucky"] },
  { name: "service", label: "Service", options: ["Boarding", "OffBoarding"] },
  {
    name: "location",
    label: "Location",
    options: ["Hyderabad", "America", "Australia", "Nagaland"],
  },
  {
    name: "status",
    label: "Status",
    options: ["Completed", "Processing", "Rejected"],
  },
];

const AppointmentList = () => {
  const [filteredData, setFilteredData] = useState(initialData);
  const { data: appintmentdata } = useGetpetAppointmentsQuery();

  const handleFilteredData = (data) => {
    setFilteredData(data);
  };

  return (
    <>
      <div className="p-4 flex flex-col justify-normal items-start gap-4">
        <div className="text-black font-poppins text-5xl font-bold">
          Your Appointments
          <div className="h-1 w-8/12 bg-borderColor mt-1"></div>
        </div>
        <div className="flex flex-col justify-between w-[50%] gap-3">
          <p className="font-poppins text-start text-xs underline font-semibold cursor-pointer">
            Filter by
          </p>
          <DashboardFilter
            data={initialData}
            filterFields={AppointementFilterFields}
            onFilter={handleFilteredData}
          />
        </div>
        <div className="w-full">
          {appintmentdata ? (
            <AppointmentsTable appointment={appintmentdata} />
          ) : (
            <h1>No Appointment Yet</h1>
          )}
        </div>
      </div>
    </>
  );
};

export default AppointmentList;
