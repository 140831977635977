import React from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
function convertTo12HourFormat(time) {
  // Split the input time into hours, minutes, and seconds
  if (!time) return;
  let [hours, minutes, seconds] = time?.split(":");

  // Convert hours to a number
  hours = parseInt(hours, 10);

  // Determine AM or PM
  let period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Return the formatted time
  return `${hours}:${minutes} ${period}`;
}
const AppointmentCard = (data) => {
  const user = JSON.parse(localStorage.getItem("boop_customer_user"));

  const status = data?.data?.status;
  let statusClass = "";
  let textColorClass = "";

  if (status == "UPCOMING") {
    statusClass = "bg-status-processing";
    textColorClass = "text-status-processing";
  } else if (status === "Completed") {
    statusClass = "bg-status-completed";
    textColorClass = "text-status-completed";
  } else if (status === "Rejected") {
    statusClass = "bg-status-rejected";
    textColorClass = "text-status-rejected";
  }

  return (
    <div className="flex p-5 flex-col gap-y-2 w-full justify-center items-center bg-white rounded-xl">
      <div className="flex justify-between w-full items-center ">
        <p className="font-semibold text-3xl  font-poppins">
          {data?.data?.service?.service_name}
        </p>
        <p className="font-bold text-xl  font-poppins">
          {data?.data?.service?.cost}
        </p>
      </div>
      <div className="flex gap-2 justify-start w-full items-center">
        {/* <p className="text-[#888] font-poppins text-base font-normal w-[33%] overflow-hidden text-ellipsis">
          {" "}
          Booking ID -{" "}
          <span className="font-bold text-nowrap ">{data?.data?.id}</span>
        </p> */}
        <p className="text-[#888] font-poppins text-base font-normal  w-[33%]">
          {" "}
          • Pet Parent -{" "}
          <span className="font-bold">{user?.user?.first_name}</span>
        </p>
        <p className="text-[#888] font-poppins text-base font-normal  w-[33%]">
          {" "}
          • Pet -{" "}
          <span className="font-bold">
            {" "}
            {data?.data?.service_appointment_pets?.map(
              (pet) => `${pet?.pet?.name},`
            )}
          </span>
        </p>
      </div>
      <div className="flex justify-start items-center gap-4 w-full">
        <p
          className={`font-sans w-70p rounded-  py-5px  text-xs font-bold text-center ${statusClass} ${textColorClass}`}
        >
          {status}
        </p>
        <p className="bg-faintBg w-70p rounded-5 py-5px  font-bold text-borderColor text-xs flex justify-evenly items-center">
          {convertTo12HourFormat(data?.data?.start_time)}-
          {convertTo12HourFormat(data?.data?.end_time)}
          {/* 8:00 AM - 9:00 PM */}
          <span className="bg-faintBg cursor-pointer  text-borderColor rounded-full flex items-center justify-center">
            <BorderColorOutlinedIcon style={{ fontSize: "1rem" }} />
          </span>
        </p>
        <p className="bg-faintBg w-70p rounded-5 py-5px  font-bold text-borderColor text-xs flex justify-evenly items-center">
          {new Date(data?.data?.start_date).toLocaleDateString("en-GB")}
          <span className="bg-faintBg cursor-pointer  text-borderColor rounded-full flex items-center justify-center">
            <BorderColorOutlinedIcon style={{ fontSize: "1rem" }} />
          </span>
        </p>
      </div>
    </div>
  );
};

export default AppointmentCard;
