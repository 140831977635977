export default function Input({
  label,
  type,
  placeholder = null,
  formik,
  name,
  label_size_rem,
  disabled,
  options = [],
  onChange,
  required,
}) {
  return (
    <div className="w-full relative">
      <label
        style={{
          fontSize: `${label_size_rem}rem`,
        }}
        htmlFor={label}
        className="block text-sm font-bold leading-6 text-gray-900 max-md:hidden"
      >
        {label} {required && <span className="text-red-500"> *</span>}
      </label>
      <div className="w-full flex flex-row">
        {type == "tel" && (
          <span
            className="bg-faintBg p-2 rounded-l-lg font-bold text-center pl-3 pr-3 text-black
            flex items-center justify-center"
          >
            +91
          </span>
        )}
        {type === "textarea" ? (
          <textarea
            name={name}
            onBlur={formik.handleBlur}
            value={formik.values[name]}
            onChange={formik.handleChange}
            disabled={disabled || false}
            className={`block w-full rounded-md border-0 py-1.5 p-2 text-gray-900 
              shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none
              focus:ring-1 focus:ring-inset focus:ring-borderColor sm:text-sm sm:leading-6
              ${
                formik.errors[name]
                  ? "focus:ring-red-500 ring-red-500"
                  : "focus:ring-borderColor"
              }`}
            placeholder={placeholder}
          />
        ) : type === "option" ? (
          <select
            name={name}
            onBlur={formik.handleBlur}
            value={formik.values[name]}
            onChange={formik.handleChange}
            disabled={disabled}
            className={`block w-full rounded-md border-0 py-1.5 p-2 text-gray-900 
              shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none
              focus:ring-1 focus:ring-inset focus:ring-borderColor sm:text-sm sm:leading-6
              ${
                formik.errors[name]
                  ? "focus:ring-red-500 ring-red-500"
                  : "focus:ring-borderColor"
              }`}
          >
            <option value="" disabled>
              {placeholder}
            </option>
            {options.map((option) => (
              <option key={option.id} value={option.val}>
                {option?.label || option?.val}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={type}
            name={name}
            onBlur={formik.handleBlur}
            value={formik.values[name]}
            onChange={onChange ? onChange : formik.handleChange}
            disabled={disabled}
            className={`block w-full rounded-md border-0 py-1.5 p-2 text-gray-900 
              shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none
              focus:ring-1 focus:ring-inset focus:ring-borderColor sm:text-sm sm:leading-6
              ${
                formik.errors[name]
                  ? "focus:ring-red-500 ring-red-500"
                  : "focus:ring-borderColor"
              }`}
            placeholder={placeholder}
          />
        )}
      </div>
      {formik.touched[name] && formik.errors[name] ? (
        <div className="text-red-500 text-sm w-full text-end absolute">
          {formik.errors[name]}
        </div>
      ) : null}
    </div>
  );
}
