import { AttachFile, CancelOutlined } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React, { useState, useEffect } from "react";
import Rating from "../Rating";
import { useDispatch } from "react-redux";
import { createJournalRecord } from "store/HealthHub";

import {
  useCreateJournalMutation,
  useUpdateJournalRecordMutation,
} from "store/HealthHub/healthHubSlice";

import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/FormComponents/Input";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import moment from "moment";
import { AddNote, AddMoreNote } from "../Notes";
const AllergyModal = ({ closeModal, data }) => {
  const dispatch = useDispatch();
  const [createjournal, createstate] = useCreateJournalMutation();
  const [updatejournal, updatestate] = useUpdateJournalRecordMutation();

  const selectedpet = useSelector((state) => state?.petsetting?.activepet);

  const formik = useFormik({
    initialValues: {
      allergy_date: moment(data?.allergy_date).format("YYYY-MM-DD") || "",
      skin_itch: data?.skin_itch || null,
      skin_redness: data?.skin_redness || null,
      ear_itch: data?.ear_itch || null,
      sneezing: data?.sneezing || null,
      water_eyes: data?.water_eyes || null,
      cough: data?.cough || null,
      allergy_info: data?.allergy_info || "",
      journal_type: data?.journal_type || "Allergy",
      pet_id: data?.pet_id || selectedpet.id,
      notes: data?.notes.map((sin) => {
        return { note_pic: sin?.id, content: sin?.content, link: sin?.link };
      }) || [{ note_pic: null, content: null }],
    },
    validationSchema: Yup.object({
      allergy_date: Yup.date().required("Date is required"),
      skin_itch: Yup.number().min(0).max(5),
      skin_redness: Yup.number().min(0).max(5),
      ear_itch: Yup.number().min(0),
      sneezing: Yup.number().min(0),
      water_eyes: Yup.number().min(0).max(5),
      cough: Yup.number().min(0).max(5),
      // allergy_info: Yup.string().required("Additional information is required"),
    }),
    onSubmit: async (values) => {
      if (data?.id) {
        await updatejournal({ id: data?.id, data: values });
      } else {
        await createjournal(values);
      }
      toast.success("Journal record created successfully!");
      closeModal();
    },
  });

  // const handlePhotoChange = (event) => {
  //   const file = event.target.files[0];
  //   formik.setFieldValue("file", file);
  //   const fileNameSpan = document.getElementById("fileName");
  //   if (file) {
  //     fileNameSpan.textContent = file.name;
  //     fileNameSpan.classList.remove("text-[#b0b0b0]");
  //   } else {
  //     fileNameSpan.textContent = "No file selected";
  //     fileNameSpan.classList.add("text-[#b0b0b0]");
  //   }
  // };
  // const [file, setfile] = useState();

  // useEffect(() => {
  //   setfile(data?.journal_pic?.url);
  // }, [data]);
  return (
    <>
      <div className="w-full space-y-3 ">
        <div className="w-full ">
          <div className="inline-flex gap-2 justify-start items-center min-w-full text-borderColor font-poppins text-base font-semibold">
            <div
              className="bg-faintBg cursor-pointer rounded-lg"
              style={{ width: "40px", height: "40px" }}
              onClick={closeModal}
            >
              <ChevronLeftIcon style={{ width: "40px", height: "40px" }} />
            </div>
            <span>
              Allergy Details
              <div className="h-[1px] w-full bg-borderColor mt-1 text-center"></div>
            </span>
          </div>
          <div className="flex justify-end items-center gap-x-2 absolute top-6 right-6 w-full">
            <button
              onClick={closeModal}
              className="bg-faintBg cursor-pointer text-borderColor rounded-full"
            >
              <CancelOutlined />
            </button>
          </div>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="w-full space-y-5 max-h-[80vh] overflow-y-scroll no-scrollbar"
        >
          <div className="w-4/12">
            <Input
              label="Date"
              type="date"
              name="allergy_date"
              formik={formik}
              required={true}
            />
          </div>

          <div className="w-full">
            <p className="font-poppins font-bold text-xs ">
              Add Additional Information
            </p>
            <div className="w-full bg-[#F3F4F6] rounded-xl p-2">
              <Rating label="Skin Itch" name="skin_itch" formik={formik} />
              <Rating label="Sneezing" name="sneezing" formik={formik} />
              <Rating label="Watery Eyes" name="water_eyes" formik={formik} />
              <Rating
                label="Skin Redness"
                name="skin_redness"
                formik={formik}
              />
              <Rating label="Ear Itch" name="ear_itch" formik={formik} />
              <Rating label="Cough" name="cough" formik={formik} />
            </div>
          </div>
          <div className="flex flex-col gap-5">
            {formik?.values?.notes?.map((rec, index) => (
              <AddNote key={index} rec={rec} index={index} formik={formik} />
            ))}
            <AddMoreNote formik={formik} />
          </div>

          <div className="flex gap-5 sm:flex-row justify-center items-center">
            <button
              type="submit"
              className="flex bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md"
            >
              <p className="font-poppins font-medium text-justify text-xs">
                {createstate.isLoading || updatestate.isLoading
                  ? "Saving..."
                  : "Submit Journal"}
              </p>
            </button>

            <button
              type="button"
              onClick={closeModal}
              className="flex bg-faintBg text-black p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md"
            >
              <p className="font-poppins font-medium text-justify text-xs">
                Cancel
              </p>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AllergyModal;
