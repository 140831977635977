import React, { useState, useRef } from "react";
import PetProfileCreationImg from "assets/img/PetProfileCreationImg.png";
import LoginLogo from "assets/img/LoginLogo.png";
import addPhoto from "assets/img/add_photo.png";

import { TextInput, SelectInput, FileInput } from "components/Common/formData";
import ProfileSettings from "./Settings/PetProfileSettings";
const PetProfile = () => {
  return (
    <div className="p-[3rem] w-[100%] h-full flex items-center justify-center min-h-screen">
      <ProfileSettings />
    </div>
  );
};

export default PetProfile;
