import React, { useState } from "react";

const HelpSupport = () => {
  const [formData, setFormData] = useState({
    email: "Rocky",
    query: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
   
    console.log(formData);

  };

  return (
    <form onSubmit={handleSubmit} className="w-full ">
      <div>
        <p className="text-black text-xl font-semibold font-poppins">
          Help & Support
        </p>
        <p className="text-[#717171] font-poppins font-medium text-[15px] text-justify ">
          Reach out to the team of boop for any support
          <div className="h-[1px] w-4/12 bg-borderColor"></div>
        </p>
      </div>
      <div className="w-full mt-5 flex flex-col justify-start items-start gap-4 ">
        <span className="w-2/4">
          <p className="font-poppins font-bold text-xs ">Email</p>
          <input
            name="email"
            type="text"
            value={formData.email}
            onChange={handleChange}
            className=" font-poppins border-[1px] border-borderColor p-4 text-xs font-bold  w-full bg-white  h-7 rounded-[10px] focus:outline-none"
            placeholder="Email*"
            required
          />
        </span>
        <span className="w-2/4">
          <p className="font-poppins font-bold text-xs ">Query</p>
          <textarea
            name="query"
            value={formData.query}
            onChange={handleChange}
            className="w-full p-4 h-[80px] text-xs flex-shrink-0 rounded-[10px]  border-[1px] border-borderColor focus:outline-none bg-white"
            placeholder="Write Something!"
          />
        </span>
      </div>
      <div className="flex mt-5  gap-5 justify-start items-center">
        <button
          type="submit"
          className="flex w-fit bg-borderColor   border-2 cursor-pointer border-borderColor px-8 text-white  p-2 justify-center items-center flex-shrink-0 rounded-md  "
        >
          <p className="font-poppins font-medium text-justify text-sm">Send</p>
        </button>
        <button
          type="button"
          className="flex w-fit hover:bg-borderColor  border-2 cursor-pointer border-borderColor px-8  hover:text-white  p-2 justify-center items-center flex-shrink-0 rounded-md  "
        >
          <p className="font-poppins font-medium text-justify text-sm">
            Cancel
          </p>
        </button>
      </div>
    </form>
  );
};

export default HelpSupport;
