import React, { useState } from "react";
import { CancelOutlined } from "@mui/icons-material";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const PasswordInput = ({
  label,
  name,
  value,
  onChange,
  showPassword,
  toggleVisibility,
  placeholder,
}) => (
  <div className="w-10/12">
    <p className="font-poppins font-bold text-base">{label}</p>
    <div className="relative">
      <input
        name={name}
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        className="font-poppins px-4 text-xs font-bold w-full bg-[#F3F4F6] h-7 rounded-[2px] focus:outline-none"
        placeholder={placeholder}
        required
      />
      <div
        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
        onClick={toggleVisibility}
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </div>
    </div>
  </div>
);

const ResetModal = ({ closeModal }) => {
  const [forgottenPassword, setForgottenPassword] = useState(false);

  const [state, setState] = useState({
    currentPassword: "Rocky",
    newPassword: "",
    confirmPassword: "",
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    hasStartedTypingConfirmPassword: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      ...(name === "confirmPassword" && {
        hasStartedTypingConfirmPassword: true,
      }),
    }));
  };

  const toggleVisibility = (field) => {
    setState((prevState) => ({ ...prevState, [field]: !prevState[field] }));
  };

  const passwordsMatch =
    state.newPassword === state.confirmPassword && state.newPassword !== "";

  return (
    <div className="w-full">
      <div className="text-black inline-flex flex-col justify-center items-center min-w-full font-poppins text-4xl font-semibold">
        Reset Password
        <div className="h-[1px] w-1/4 bg-borderColor mt-1 text-center"></div>
      </div>
      <div className="flex justify-end items-center gap-x-2 absolute top-6 right-6 w-full">
        <button
          onClick={closeModal}
          className="bg-faintBg cursor-pointer text-borderColor rounded-full"
        >
          <CancelOutlined />
        </button>
      </div>
      {!forgottenPassword && (
        <>
          <div className="flex justify-between items-start my-5">
            <div className="space-y-2 w-[50%]">
              <PasswordInput
                label="Current Password"
                name="currentPassword"
                value={state.currentPassword}
                onChange={handleChange}
                showPassword={state.showCurrentPassword}
                toggleVisibility={() => toggleVisibility("showCurrentPassword")}
                placeholder="Enter current password"
              />
              <p
                onClick={() => setForgottenPassword(true)}
                className="text-[#D99149] font-poppins text-xs font-medium leading-6 underline cursor-pointer"
              >
                Forget Current Password?
              </p>
              <PasswordInput
                label="New Password"
                name="newPassword"
                value={state.newPassword}
                onChange={handleChange}
                showPassword={state.showNewPassword}
                toggleVisibility={() => toggleVisibility("showNewPassword")}
                placeholder="Enter new password"
              />
              <div className="flex items-center space-x-2">
                <PasswordInput
                  label="Confirm Password"
                  name="confirmPassword"
                  value={state.confirmPassword}
                  onChange={handleChange}
                  showPassword={state.showConfirmPassword}
                  toggleVisibility={() =>
                    toggleVisibility("showConfirmPassword")
                  }
                  placeholder="Enter new password again"
                />
              </div>
            </div>
            {state.hasStartedTypingConfirmPassword && (
              <div
                className={`text-sm font-semibold p-2 absolute bottom-[22%] left-2/4 rounded bg-[#F5F7F9] border-t-4 ${passwordsMatch ? " border-[#28A138]" : "border-[#D21C1C]"}`}
              >
                {passwordsMatch ? (
                  <div className="flex items-center">
                    <FaEye
                      className={`mr-1 ${passwordsMatch ? " text-[#28A138]" : "text-[#D21C1C]"}`}
                    />
                    Password Match
                  </div>
                ) : (
                  <div className="flex  items-center">
                    <FaEyeSlash
                      className={`mr-1 ${passwordsMatch ? " text-[#28A138]" : "text-[#D21C1C]"}`}
                    />
                    Password Don't Match
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex mt-5 gap-5 sm:flex-row justify-start items-start">
            <button className="bg-borderColor text-white text-center cursor-pointer flex justify-center items-center rounded-[8px] font-normal text-[15px] leading-40 tracking-wider px-4 py-2">
              Reset Password
            </button>
            <button
              onClick={closeModal}
              className="bg-borderColor text-white text-center cursor-pointer flex justify-center items-center rounded-[8px] font-normal text-[15px] leading-40 tracking-wider px-4 py-2"
            >
              Cancel
            </button>
          </div>{" "}
        </>
      )}

      {forgottenPassword && (
        <>
          <div className="flex justify-between items-start my-5">
            <div className="space-y-2 w-[50%]">
              <p className="font-poppins font-bold text-[16px] ">
                Forgot Your Current Password
              </p>
              <p className="font-poppins font-bold text-xs ">
                Enter your email to get a reset link for your password
              </p>
              <input
                name="firstName"
                type="text"
                // value="Rocky"
                className=" font-poppins  p-4 text-xs font-bold  w-full bg-[#F3F4F6]  h-7 rounded-[6px] focus:outline-none"
                placeholder="Email"
                required
              />
            </div>
          </div>

          <div className="flex mt-5 gap-5 sm:flex-row justify-start items-start">
            <button className="bg-borderColor text-white text-center cursor-pointer flex justify-center items-center rounded-[8px] font-normal text-[15px] leading-40 tracking-wider px-4 py-2">
              Send Reset Link
            </button>
            <button
              onClick={closeModal}
              className="bg-borderColor text-white text-center cursor-pointer flex justify-center items-center rounded-[8px] font-normal text-[15px] leading-40 tracking-wider px-4 py-2"
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ResetModal;
