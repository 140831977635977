import React from "react";
import { useNavigate } from "react-router-dom";

const HealthHubCard = ({ openBloodDonarModal, openModal , data }) => {
  const navigate = useNavigate()
  const handleRedirection = () =>{
    navigate(data.link);
  }
  return (
    <>
      <div className="w-[30%] p-2 relative overflow-hidden bg-white gap-y-10 flex flex-col justify-normal items-start rounded-xl ">
        <div className="inline-flex gap-1 items-center justify-normal">
          <div className="bg-cardBg rounded-full absolute left-[-15px] top-[-15px] justify-center items-center inline-flex w-20 h-20">
            <img
              src={data.img}
              alt="#"
              className=" object-cover overflow-auto h-10 w-10 "
            />
          </div>
          <p className="relative left-16 font-poppins font-bold">
            {data.title}
          </p>
        </div>
        <div className="flex justify-evenly gap-2 items-center w-full">
          {data.title !== "Blood Donor" ? (
            <>
              {" "}
              <button onClick={openModal} className="flex w-7/12 bg-borderColor text-white p-2 justify-center items-center flex-shrink-0 rounded-md  ">
                <p className="font-poppins font-medium text-justify text-sm">
                  + Add Record
                </p>
              </button>
              <button onClick={handleRedirection} className="flex w-4/12 bg-[#FFEEDE] text-borderColor p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md  ">
                <p className="font-poppins font-medium text-justify text-sm">
                  View All
                </p>
              </button>{" "}
            </>
          ) : (
            <button
              onClick={openBloodDonarModal}
              className="flex w-full bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md  "
            >
              <p className="font-poppins font-medium text-justify text-sm">
                + Sign up as blood donor
              </p>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default HealthHubCard;
