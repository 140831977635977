import { CancelOutlined } from "@mui/icons-material";
import React, { useState } from "react";

const AddTags = ({ closeModal }) => {
  const [selectedTags, setSelectedTags] = useState({
    energy: "",
    petFriendly: "",
    lifestyle: "",
  });

  const handleSelectTag = (category, tag) => {
    setSelectedTags((prevTags) => ({
      ...prevTags,
      [category]: prevTags[category] === tag ? "" : tag,
    }));
  };

  const categories = [
    {
      name: "Energy Levels",
      options: [
        "Active/High Energy",
        "Moderate Energy",
        "Low Energy/Couch Potato",
      ],
      key: "energy",
    },
    {
      name: "Pet Friendly",
      options: [
        "Dog-friendly",
        "Cat-friendly",
        "Small animal-friendly",
        "Fish-friendly",
        "Reptile-friendly",
        "Bird-friendly",
        "All pet-friendly",
        "No pets allowed",
      ],
      key: "petFriendly",
    },
    {
      name: "Lifestyle",
      options: ["Outdoorsy", "Homebody", "Frequent traveler"],
      key: "lifestyle",
    },
  ];

  return (
    <>
      <div className="w-full ">
        <div className="text-black inline-flex flex-col justify-center items-center min-w-full  font-poppins text-4xl font-semibold">
          Add Tags
          <div class="h-[1px] w-1/4 bg-borderColor mt-1 text-center"></div>{" "}
        </div>
        <div className="flex justify-end items-center gap-x-2 absolute top-6 right-6 w-full">
          <button
            onClick={closeModal}
            className="bg-faintBg  cursor-pointer  text-borderColor rounded-full"
          >
            <CancelOutlined />
          </button>
        </div>
        <div className="p-2 max-w-lg mx-auto space-y-4">
          {categories.map((category) => (
            <div key={category.key}>
              <h3 className="font-semibold text-lg">{category.name}</h3>
              <div className="flex flex-wrap gap-2 mt-2">
                {category.options.map((option) => (
                  <button
                    key={option}
                    className={`px-4 py-2 rounded-[5px] text-[10px] font-poppins font-medium flex text-center  items-center cursor-pointer ${
                      selectedTags[category.key] === option
                        ? "bg-borderColor text-white"
                        : "bg-faintBg text-borderColor"
                    }`}
                    onClick={() => handleSelectTag(category.key, option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          ))}
          <div className="pt-4">
            <h4 className="font-semibold text-lg">Selected :</h4>
            <div className="flex flex-wrap gap-2 mt-2">
              {Object.values(selectedTags).map(
                (tag, index) =>
                  tag && (
                    <span
                      key={index}
                      className="px-4 py-2 rounded-[4px] bg-borderColor text-white text-[10px] font-medium  flex text-center  items-center "
                    >
                      {tag}
                    </span>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTags;
