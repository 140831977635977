import React from "react";
import { CancelOutlined } from "@mui/icons-material";

const AddNewAddress = ({ closeModal }) => {
  return (
    <>
      <div className="w-full">
        <div className="text-black inline-flex flex-col justify-center items-center min-w-full  font-poppins text-4xl font-semibold">
          Select Details
          <div class="h-[1px] w-1/4 bg-borderColor mt-1 text-center"></div>{" "}
        </div>
        <div className="flex justify-end items-center gap-x-2 absolute top-6 right-6 w-full">
          <button
            onClick={closeModal}
            className="bg-faintBg  cursor-pointer  text-borderColor rounded-full"
          >
            <CancelOutlined />
          </button>
        </div>

        <p className="font-poppins font-bold text-xs mt-5 ">Address</p>
        <textarea
          className="flex-shrink-0 rounded-lg w-full p-4 border-[1px] text-xs border-[#BFBFBF] focus:outline-none bg-white"
          placeholder="Write Something!"
        />

        <div className="flex justify-center gap-x-5 mt-5 items-center">
          <button className="flex w-[40%] mt-auto rounded-[6px] justify-center items-center bg-borderColor py-2 px-4">
            <p className="text-[14px] font-poppins font-normal text-white">
              Enter your address
            </p>
          </button>

          <button className="flex w-[30%] mt-auto rounded-[6px] justify-center items-center bg-faintBg py-2 px-4">
            <p className="text-[14px] font-poppins font-normal text-borderColor">
              Cancel
            </p>
          </button>
        </div>
      </div>
    </>
  );
};

export default AddNewAddress;
