import { AttachFile, CancelOutlined } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React, { useEffect, useState } from "react";
import Rating from "../Rating";
import { useDispatch } from "react-redux";
import { createJournalRecord } from "store/HealthHub";
import {
  useCreateJournalMutation,
  useUpdateJournalRecordMutation,
} from "store/HealthHub/healthHubSlice";
import { MdDeleteOutline } from "react-icons/md";

import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/FormComponents/Input";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { AddNote, AddMoreNote } from "../Notes";

const HeatModal = ({ closeModal, data }) => {
  const dispatch = useDispatch();
  const [createjournal, createstate] = useCreateJournalMutation();
  const [updatejournal, updatestate] = useUpdateJournalRecordMutation();
  const selectedpet = useSelector((state) => state?.petsetting?.activepet);

  const formik = useFormik({
    initialValues: {
      heat_start: moment(data?.heat_start).format("YYYY-MM-DD") || null,

      heat_end: moment(data?.heat_end).format("YYYY-MM-DD") || null,
      journal_type: "Heat",
      pet_id: selectedpet.id,
      mated: data?.mated || false,
      mate_date: moment(data?.mate_date).format("YYYY-MM-DD") || null,

      notes: data?.notes.map((sin) => {
        return { note_pic: sin?.id, content: sin?.content, link: sin?.link };
      }) || [{ note_pic: null, content: null }],
    },
    validationSchema: Yup.object({
      heat_start: Yup.date().required("Date is required"),
      heat_end: Yup.date().required("Date is required"),
      mate_date: Yup.date().when("mated", {
        is: (value) => value == true,
        then: () =>
          Yup.date().required("Mate date is required when mated is true"),
        otherwise: () => Yup.date().nullable(),
      }),
      mated: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      if (data?.id) {
        await updatejournal({ id: data?.id, data: values });
      } else {
        await createjournal(values);
      }
      toast.success("Journal record created successfully!");
      closeModal();
    },
  });

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("file", file);
    const fileNameSpan = document.getElementById("fileName");
    if (file) {
      fileNameSpan.textContent = file.name;
      fileNameSpan.classList.remove("text-[#b0b0b0]");
    } else {
      fileNameSpan.textContent = "No file selected";
      fileNameSpan.classList.add("text-[#b0b0b0]");
    }
  };
  const [file, setfile] = useState();

  useEffect(() => {
    setfile(data?.journal_pic?.url);
  }, [data]);

  useEffect(() => {
    formik.setFieldValue("mate_date", null);
  }, [formik?.values?.mated]);
  return (
    <>
      <div className="w-full space-y-3 ">
        <div className="w-full ">
          <div className="inline-flex gap-2 justify-start items-center min-w-full text-borderColor font-poppins text-base font-semibold">
            <div
              className="bg-faintBg cursor-pointer rounded-lg"
              style={{ width: "40px", height: "40px" }}
              onClick={closeModal}
            >
              <ChevronLeftIcon style={{ width: "40px", height: "40px" }} />
            </div>
            <span>
              Heat Details
              <div className="h-[1px] w-full bg-borderColor mt-1 text-center"></div>
            </span>
          </div>
          <div className="flex justify-end items-center gap-x-2 absolute top-6 right-6 w-full">
            <button
              onClick={closeModal}
              className="bg-faintBg cursor-pointer text-borderColor rounded-full"
            >
              <CancelOutlined />
            </button>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} className="w-full space-y-2">
          <div className="w-full flex justify-between items-center space-x-5">
            <div className="w-6/12">
              <Input
                required={true}
                label="Heat onset"
                type="date"
                name="heat_start"
                formik={formik}
              />
            </div>
            <div className="w-6/12">
              <Input
                required={true}
                label="End of Heat"
                type="date"
                name="heat_end"
                formik={formik}
              />
            </div>
          </div>
          <div className="flex flex-row">
            {file && (
              <div className="relative">
                <MdDeleteOutline
                  onClick={() => {
                    formik.setFieldValue("file", true);
                    setfile();
                  }}
                  className="absolute bg-white text-borderColor rounded-full right-0 m-1 cursor-pointer"
                  size={25}
                />

                <img
                  onClick={() => window.open(file, "_blank")}
                  style={{ objectFit: "cover" }}
                  src={data?.journal_pic?.url}
                  className={`  rounded-lg h-[5rem] w-[5rem] bg-borderColor`}
                />
              </div>
            )}
          </div>
          <div className="w-5/12 flex items-center">
            <input
              type="checkbox"
              id="mated"
              name="mated"
              checked={formik.values.mated}
              onChange={() =>
                formik.setFieldValue("mated", !formik.values.mated)
              }
              className="mr-2"
            />
            <label htmlFor="metated" className="text-sm">
              Mated
            </label>
          </div>
          <div style={{ marginBottom: "1.5rem" }} className="w-6/12">
            {formik.values?.mated && (
              <Input
                required={true}
                label="Mated Date"
                type="date"
                name="mate_date"
                formik={formik}
              />
            )}
          </div>
          <div className="flex flex-col gap-5">
            {formik?.values?.notes?.map((rec, index) => (
              <AddNote key={index} rec={rec} index={index} formik={formik} />
            ))}
            <AddMoreNote formik={formik} />
          </div>
          <div className="flex gap-5 sm:flex-row justify-center items-center">
            <button
              type="submit"
              className="flex bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md"
            >
              <p className="font-poppins font-medium text-justify text-xs">
                {createstate.isLoading || updatestate.isLoading
                  ? "Saving..."
                  : "Submit Journal"}
              </p>
            </button>

            <button
              type="button"
              onClick={closeModal}
              className="flex bg-faintBg text-black p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md"
            >
              <p className="font-poppins font-medium text-justify text-xs">
                Cancel
              </p>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default HeatModal;
