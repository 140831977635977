import { apiSlice } from "store/apiSlice";
export const filesUploadApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      query: (data) => ({
        url: "/api/v1/public/files/upload-multiple",
        method: "POST",
        body: data,
      }),
    }),
    deleteFile: builder.mutation({
      query: (param) => ({
        url: `/api/v1/public/files/delete-single?${param}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useUploadFileMutation, useDeleteFileMutation } =
  filesUploadApiSlice;
