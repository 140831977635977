import React, { useState, useRef, useEffect } from "react";
import Input from "components/FormComponents/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useGetAllPetsQuery,
  useRegisterPetMutation,
  useUpdatePetMutation,
  useDeletePetMutation,
} from "store/settings/petprofileSlice";
import { SiDatadog } from "react-icons/si";
import { MdDeleteOutline } from "react-icons/md";
import Confirm from "components/Common/Modal/Confirm";
import { MdPets } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { setactive } from "store/StateSlice/settingSlice";
import { setpets } from "store/StateSlice/settingSlice";
import { TextInput } from "components/Common/formData";
import { NumberInput } from "components/Common/formData";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export function ProfileCard({
  selected,
  name,
  profilepic,
  addstate,
  activepet,
}) {
  return (
    <button
      onClick={activepet}
      className={`w-[10rem] flex flex-col items-center justify-center p-3  rounded-2xl hover:bg-white
    ${selected && !addstate ? "bg-orange-100" : "border  border-borderColor"} transition ease-in-out relative`}
    >
      {profilepic ? (
        <>
          <img
            // onClick={() => fileInputRef.current.click()}
            style={{ objectFit: "cover" }}
            src={profilepic?.url}
            className={`  rounded-full h-[5rem] w-[5rem] bg-borderColor`}
          />
        </>
      ) : (
        <MdPets size={50} />
      )}
      <h1>{name}</h1>
      <h3 className="border border-borderColor text-[0.8rem] rounded-lg p-1 mt-2">
        {selected && !addstate ? "Selected" : "Select"}
      </h3>
    </button>
  );
}

const ProfileSettings = () => {
  const petsetting = useSelector((state) => state?.petsetting);
  const activepet = useSelector((state) => state?.petsetting?.activepet);
  const [register, registerstate] = useRegisterPetMutation();
  const [update, updatestate] = useUpdatePetMutation();
  const [deletepet, deletestate] = useDeletePetMutation();
  const [confirm, setconfirm] = useState(false);
  const parent_id = useSelector((state) => state.auth.user.id);
  const { data: allpets } = useGetAllPetsQuery();
  const location = useLocation();
  const dispatch = useDispatch();
  const [newaddpet, setnewaddpet] = useState(
    location.pathname.includes("add-pet") ? true : false
  );
  const navigate = useNavigate();

  const [addstate, setaddstate] = useState(false);
  const [initital, setinitial] = useState({
    name: "",
    petid: "",
    parent_id,
    type: "",
    breed: "",
    gender: "",
    weight: "",
    age_year: "",
    age_month: "",
    dob: "",
    bloodtype: "",
    govid: "",
    chipid: "",
    neutered_or_spayed: false,
  });
  const [dob, setDob] = useState({
    day: "",
    month: "",
    year: "",
  });
  const handleDobChange = (event) => {
    const { name, value } = event.target;
    const newDob = { ...dob, [name]: value };
    setDob(newDob);

    const { day, month, year } = newDob;
    if (month && year) {
      formik.setFieldValue("dob", `${day || 1}-${month}-${year}`);
    }
  };
  useEffect(() => {
    dispatch(setpets(allpets?.pets?.rows));
  }, [allpets]);
  const formik = useFormik({
    initialValues: initital,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      petid: Yup.string(),
      type: Yup.string().required("Required"),
      breed: Yup.string().required("Required"),
      gender: Yup.string().required("Required"),
      weight: Yup.number(),
      age_year: Yup.number().required("Required"),
      age_month: Yup.number(),
      dob: Yup.date(),
      bloodtype: Yup.string(),
      govid: Yup.string(),
      chipid: Yup.string(),
      neutered_or_spayed: Yup.boolean().default(false),
    }),
    onSubmit: async (values) => {
      try {
        const formDataPayload = new FormData();
        for (const key in values) {
          formDataPayload.append(key, values[key]);
        }

        addstate
          ? await register(formDataPayload)
          : await update({ data: formDataPayload, id: activepet.id });
        setaddstate(false);
        toast.success("Pet profile updated successfully");
      } catch (error) {
        toast.error("Failed to update pet profile");
      }
    },
  });
  useEffect(() => {
    if (activepet) {
      const [year, month, day] = activepet?.dob
        ? activepet.dob.split("-").map((part) => parseInt(part, 10))
        : ["", "", ""];
      setDob({ day, month, year });
      formik.setValues({
        name: activepet?.name || "",
        petid: activepet?.id || "",
        type: activepet?.type || "",
        breed: activepet?.breed || "",
        gender: activepet?.gender || "",
        weight: activepet?.weight ? parseInt(activepet?.weight) : "",
        age_year: activepet?.age_year || "",
        age_month: activepet?.age_month || "",

        dob: activepet?.dob || "",
        bloodtype: activepet?.bloodtype || "",
        govid: activepet?.govid || "",
        chipid: activepet?.chipid || "",
        neutered_or_spayed: activepet?.neutered_or_spayed || false,
      });
      setprofilepic(activepet?.file?.url);
    } else {
      setaddstate(true);
    }
  }, [activepet]);
  useEffect(() => {
    if (addstate) {
      formik.setValues(initital);
    } else {
      formik.setValues({
        name: activepet?.name || "",
        petid: activepet?.id || "",
        type: activepet?.type || "",
        breed: activepet?.breed || "",
        gender: activepet?.gender || "",
        weight: activepet?.weight ? parseInt(activepet?.weight) : "",
        age: activepet?.age || "",
        dob: activepet?.dob || "",
        bloodtype: activepet?.bloodtype || "",
        govid: activepet?.govid || "",
        chipid: activepet?.chipid || "",
        neutered_or_spayed: activepet?.neutered_or_spayed || false,
      });
      setprofilepic(activepet?.file?.url);
    }
  }, [addstate]);
  useEffect(() => {
    formik.setFieldValue("parent_id", parent_id);
  }, [parent_id]);
  const calculateAge = (dob) => {
    if (!dob) return { years: 0, months: 0 };

    const [day, month, year] = dob.split("-");
    const birthDate = new Date(`${year}-${month}-${day}`);
    const currentDate = new Date();

    let years = currentDate.getFullYear() - birthDate.getFullYear();
    let months = currentDate.getMonth() - birthDate.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }
    return { years: parseInt(years), months };
  };
  useEffect(() => {
    let { years, months } = calculateAge(formik.values.dob);
    formik.setFieldValue("age_year", years);
    formik.setFieldValue("age_month", months);
  }, [formik.values.dob]);
  const [profile, setprofilepic] = useState();

  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      formik.setFieldValue("file", file);
      setprofilepic(URL.createObjectURL(file));
    }
  };

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };
  const handleDelete = () => {
    deletepet(activepet.id);
    setconfirm(false);
  };
  return (
    <div className="flex flex-row gap-5 w-full">
      <Confirm show={confirm} width={40} onClose={() => setconfirm(false)}>
        <div className="flex flex-col w-full gap-4 items-center justify-center">
          <h2 className="w-full text-center">
            {" "}
            Are you Sure You want to delete?
          </h2>
          <div className="flex flex-row gap-5">
            <button
              onClick={() => setconfirm(false)}
              className="bg-white text-black p-2 pl-5 pr-5 rounded-2xl border border-borderColor"
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="bg-borderColor text-white  p-2 pl-5 pr-5 rounded-2xl"
            >
              Confirm
            </button>
          </div>
        </div>
      </Confirm>
      <div className="w-[40%] flex flex-col items-center justify-start  ">
        <div className="w-[80%] flex flex-col items-center justify-start gap-5">
          <div
            style={{
              position: "relative",
            }}
            className={`
             flex items-center justify-center relative  ${profile ? "" : "border-borderColor border-dashed  border-[0.3rem] p-10"}  rounded-2xl
            `}
          >
            {profile ? (
              <>
                {" "}
                <img
                  onClick={() => fileInputRef.current.click()}
                  style={{ objectFit: "cover" }}
                  src={profile}
                  className={`  rounded-3xl h-[15rem] w-[15rem] bg-white`}
                />{" "}
                <MdDeleteOutline
                  size={40}
                  onClick={() => {
                    formik.setFieldValue("file", true);
                    setprofilepic(false);
                  }}
                  className="z-10 absolute top-0 right-0 bg-white text-borderColor p-2 rounded-full m-2"
                />
              </>
            ) : (
              <SiDatadog size={150} />
            )}
          </div>

          <button
            className="p-2 rounded-lg border bg-borderColor text-white text-[1rem]"
            onClick={handleFileSelect}
          >
            Add New Picture
          </button>
        </div>
      </div>
      <div className="flex flex-col w-full ">
        <div className="flex flex-wrap gap-3 ">
          {petsetting?.allpets?.map((rec) => (
            <ProfileCard
              selected={rec?.id == petsetting?.activepet?.id}
              name={rec?.name}
              profilepic={rec?.file}
              addstate={addstate}
              activepet={() => {
                setaddstate(false);
                dispatch(setactive(rec));
              }}
            />
          ))}
          <button
            onClick={() => {
              setaddstate((prev) => !prev);
            }}
            className={`w-[10rem] flex flex-col items-center justify-center p-3  rounded-2xl
  border  border-borderColor border-dotted hover:bg-white ${addstate ? "bg-white" : ""} cursor-pointer transition ease-in-out`}
          >
            <div className="rounded-full border border-black border-dashed p-3">
              {addstate ? <MdOutlineCancel size={20} /> : <IoMdAdd size={20} />}
            </div>
            <h2 className="text-[0.8rem] font-bold">Add a new Pet</h2>

            <h3 className="border border-borderColor text-[0.8rem] rounded-lg p-1 mt-2 pl-3 pr-3">
              {addstate ? "Cancel" : "Add"}
            </h3>
          </button>
        </div>
        <div className="flex flex-col mt-1 mb-2 pb-2 border-b border-b-borderColor w-fit">
          <h1 className="font-bold text-[1.2rem]">Pet Profile Settings</h1>
          <h2 className="font-bold text-gray-400 text-[1rem]">
            Manage your pet profile settings
          </h2>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/png, image/jpeg"
          onChange={handleFileChange}
        />
        <div className="flex flex-col gap-3">
          <div className="flex flex-row gap-3">
            <Input
              label={"Name"}
              name={"name"}
              type={"text"}
              formik={formik}
              required={true}
              placeholder="Name"
            />
            <Input
              label={"Pet ID"}
              name={"petid"}
              type={"text"}
              formik={formik}
              disabled={true}
              placeholder="Pet Id"
            />
          </div>
          <div className="flex flex-row gap-3">
            <Input
              label={"Type"}
              name={"type"}
              type={"text"}
              formik={formik}
              required={true}
              placeholder="Type"
            />
            <Input
              label={"Breed"}
              name={"breed"}
              type={"text"}
              formik={formik}
              required={true}
              placeholder="Breed"
            />
          </div>
          <div className="flex flex-col">
            <label className="block text-sm font-bold leading-6 text-gray-900 max-md:hidden">
              Neutered Or Spayed
            </label>
            <div className="flex flex-row bg-white p-1 w-[50%] gap-3  text-[1rem] h-[3rem] rounded-lg">
              <button
                onClick={() => formik.setFieldValue("neutered_or_spayed", true)}
                className={`p-1 pl-10 pr-10 w-full ${!formik.values?.neutered_or_spayed ? " text-black" : "bg-borderColor text-white"}  rounded-lg
             `}
              >
                Yes
              </button>
              <button
                onClick={() =>
                  formik.setFieldValue("neutered_or_spayed", false)
                }
                className={`p-1 pl-10 pr-10 w-full ${!formik.values?.neutered_or_spayed ? " bg-borderColor text-white" : "text-black"}  rounded-lg
             `}
              >
                No
              </button>
            </div>
          </div>
          <div className="flex flex-row gap-3">
            <Input
              label="Gender"
              type="option"
              name="gender"
              formik={formik}
              options={[
                { id: 1, val: "Male" },
                { id: 2, val: "Female" },
              ]}
              placeholder="Select an Gender"
            />

            <Input
              label={"Weight"}
              name={"weight"}
              type={"number"}
              formik={formik}
              placeholder="Weight"
            />
          </div>{" "}
          <div className="flex w-full flex-col gap-5 items-end">
            <div className="flex flex-col w-full">
              <label>Age</label>
              <div className="flex flex-row gap-3">
                <Input
                  label={"Year"}
                  name={"age_year"}
                  type={"number"}
                  required={true}
                  formik={formik}
                  placeholder="Year"
                />{" "}
                <Input
                  label={"Month"}
                  name={"age_month"}
                  type={"number"}
                  formik={formik}
                  placeholder="Month"
                />
              </div>
            </div>
            <div className="flex flex-row gap-3">
              <NumberInput
                widthclass={"w-[30%]"}
                min={1}
                max={31}
                label="Day"
                placeholder="Day"
                name="day"
                type="number"
                value={dob.day}
                onChange={handleDobChange}
              />
              <NumberInput
                min={1}
                widthclass={"w-[30%]"}
                max={12}
                placeholder="Month"
                label="Month"
                name="month"
                type="number"
                value={dob.month}
                onChange={handleDobChange}
              />
              <NumberInput
                min={2000}
                widthclass={"w-[30%]"}
                max={2024}
                placeholder="Year"
                label="Year"
                name="year"
                type="number"
                value={dob.year}
                onChange={handleDobChange}
              />{" "}
            </div>
          </div>
          <div className="flex flex-row gap-3">
            <Input
              label="Blood Type"
              type="option"
              name="bloodtype"
              formik={formik}
              options={[
                { id: 1, val: "DEA 1.1" },
                { id: 2, val: "DEA 1.2" },
                { id: 3, val: "DEA 3" },
                { id: 4, val: "DEA 4" },
                { id: 5, val: "DEA 5" },
                { id: 6, val: "DEA 7" },
                { id: 7, val: "Type A" },
                { id: 8, val: "Type B" },
                { id: 9, val: "Type AB" },
                { id: 11, val: "C" },
                { id: 12, val: "D" },
                { id: 13, val: "K" },
                { id: 14, val: "P" },
                { id: 15, val: "Q" },
                { id: 16, val: "U" },
                { id: 20, val: "F" },
                { id: 21, val: "J" },
                { id: 22, val: "L" },
                { id: 23, val: "M" },
                { id: 24, val: "R" },
                { id: 25, val: "S" },
                { id: 26, val: "T" },
                { id: 27, val: "Z" },
              ]}
              placeholder="Select a Blood Type"
            />
            <Input
              label={"Government ID"}
              name={"govid"}
              type={"number"}
              formik={formik}
              placeholder="Government ID"
            />
            <Input
              label={"Chip ID"}
              name={"chipid"}
              type={"number"}
              formik={formik}
              placeholder="ChipId"
            />
          </div>
          <div className="flex mt-5 gap-5 justify-end items-center">
            <button
              onClick={() => formik.handleSubmit()}
              type="submit"
              className="flex w-fit bg-borderColor border-2 hover:cursor-pointer border-borderColor
               text-white p-2 justify-center items-center flex-shrink-0 rounded-md pl-5 pr-5"
            >
              <p className="font-poppins font-medium text-justify text-sm">
                {registerstate?.isLoading || updatestate?.isLoading
                  ? "Saving..."
                  : addstate
                    ? "Add"
                    : "Save Changes"}
              </p>
            </button>
            <button
              onClick={(e) => {
                if (newaddpet) {
                  navigate("/home/dashboard");
                }
                if (addstate) {
                  setaddstate(false);
                } else {
                  e.preventDefault();
                  setconfirm(true);
                }
              }}
              type="submit"
              className="flex w-fit  border-2 hover:cursor-pointer border-borderColor
               text-black p-2 justify-center items-center flex-shrink-0 rounded-md pl-5 pr-5"
            >
              <p className="font-poppins font-medium text-justify text-sm">
                {newaddpet ? "Dashboard" : addstate ? "Cancel" : "Delete"}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
