import { AttachFile, CancelOutlined } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React, { useEffect, useState } from "react";
import Rating from "../Rating";
import { useDispatch } from "react-redux";
import { createJournalRecord } from "store/HealthHub";
import {
  useCreateJournalMutation,
  useUpdateJournalRecordMutation,
} from "store/HealthHub/healthHubSlice";
import { MdDeleteOutline } from "react-icons/md";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/FormComponents/Input";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import FecesHard from "assets/svg/FecesHard.svg";
import FecesDiarrhea from "assets/svg/FecesDiarrhea.svg";
import FecesIdeal from "assets/svg/FecesIdeal.svg";
import moment from "moment";
import { AddNote, AddMoreNote } from "../Notes";

const FecesModal = ({ closeModal, data }) => {
  const dispatch = useDispatch();
  const [createjournal, createstate] = useCreateJournalMutation();
  const [updatejournal, updatestate] = useUpdateJournalRecordMutation();
  const [file, setfile] = useState();
  const selectedpet = useSelector((state) => state?.petsetting?.activepet);

  const formik = useFormik({
    initialValues: {
      feces_date: moment(data?.feces_date).format("YYYY-MM-DD") || null,
      feces_quality: data?.feces_quality || null,
      feces_info: data?.feces_info || null,
      journal_type: "Feces",
      pet_id: selectedpet.id,
      notes: data?.notes.map((sin) => {
        return { note_pic: sin?.id, content: sin?.content, link: sin?.link };
      }) || [{ note_pic: null, content: null }],
    },
    validationSchema: Yup.object({
      feces_date: Yup.date().required("Date is required"),
      feces_quality: Yup.number().required("Feces Quaity is required"),
    }),
    onSubmit: async (values) => {
      if (data?.id) {
        await updatejournal({ id: data?.id, data: values });
      } else {
        await createjournal(values);
      }
      toast.success("Journal record created successfully!");
      closeModal();
    },
  });

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("file", file);
    const fileNameSpan = document.getElementById("fileName");
    if (file) {
      fileNameSpan.textContent = file.name;
      fileNameSpan.classList.remove("text-[#b0b0b0]");
    } else {
      fileNameSpan.textContent = "No file selected";
      fileNameSpan.classList.add("text-[#b0b0b0]");
    }
  };
  const handleFecesQualityChange = (event, value) => {
    formik.setFieldValue("feces_quality", value);
  };
  useEffect(() => {
    setfile(data?.journal_pic?.url);
  }, [data]);
  return (
    <>
      <div className="w-full space-y-2 ">
        <div className="w-full ">
          <div className="inline-flex gap-2 justify-start items-center min-w-full text-borderColor font-poppins text-base font-semibold">
            <div
              className="bg-faintBg cursor-pointer rounded-lg"
              style={{ width: "40px", height: "40px" }}
              onClick={closeModal}
            >
              <ChevronLeftIcon style={{ width: "40px", height: "40px" }} />
            </div>
            <span>
              Feces Details
              <div className="h-[1px] w-full bg-borderColor mt-1 text-center"></div>
            </span>
          </div>
          <div className="flex justify-end items-center gap-x-2 absolute top-6 right-6 w-full">
            <button
              onClick={closeModal}
              className="bg-faintBg cursor-pointer text-borderColor rounded-full"
            >
              <CancelOutlined />
            </button>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} className="w-full space-y-2">
          <div className="w-4/12">
            <Input
              label="Date"
              type="date"
              name="feces_date"
              formik={formik}
              required={true}
            />
          </div>
          <div className="flex flex-row ">
            {file && (
              <div className="relative">
                <MdDeleteOutline
                  onClick={() => {
                    formik.setFieldValue("file", true);
                    setfile();
                  }}
                  className="absolute bg-white text-borderColor rounded-full right-0 m-1 cursor-pointer"
                  size={25}
                />

                <img
                  onClick={() => window.open(file, "_blank")}
                  style={{ objectFit: "cover" }}
                  src={data?.journal_pic?.url}
                  className={`  rounded-lg h-[5rem] w-[5rem] bg-borderColor`}
                />
              </div>
            )}
          </div>

          <div className="w-full">
            <p className="font-poppins font-bold text-xs">
              Choose Feces Quality
            </p>
            <div className="w-full bg-[#F3F4F6] flex flex-col justify-center items-center rounded-xl p-2">
              <Box sx={{ width: 300 }}>
                <Slider
                  aria-label="Feces Quality"
                  value={formik.values.feces_quality}
                  onChange={handleFecesQualityChange}
                  step={33}
                  marks={[
                    { value: 0, label: "" },
                    { value: 33, label: "" },
                    { value: 66, label: "" },
                    { value: 100, label: "" },
                  ]}
                  min={0}
                  max={100}
                  style={{ color: "#DA9145" }}
                />
              </Box>

              <div className="w-full flex justify-center items-center gap-10">
                <div className="flex flex-col justify-center items-center gap-2">
                  <img
                    src={FecesHard}
                    alt="feces-Quality"
                    className="bg-[#D9D9D9] cursor-pointer rounded-full"
                    style={{ width: "30px", height: "30px" }}
                  />
                  <p className="text-sm font-poppins font-bold">Hard</p>
                </div>
                <div className="flex flex-col justify-center items-center gap-2">
                  <img
                    src={FecesIdeal}
                    alt="feces-Quality"
                    className="bg-[#D9D9D9] cursor-pointer rounded-full"
                    style={{ width: "30px", height: "30px" }}
                  />
                  <p className="text-sm font-poppins font-bold">Ideal</p>
                </div>
                <div className="flex flex-col justify-center items-center gap-2">
                  <img
                    src={FecesDiarrhea}
                    alt="feces-Quality"
                    className="bg-[#D9D9D9] cursor-pointer rounded-full"
                    style={{ width: "30px", height: "30px" }}
                  />
                  <p className="text-sm font-poppins font-bold">Soft Serve</p>
                </div>
                <div className="flex flex-col justify-center items-center gap-2">
                  <img
                    src={FecesDiarrhea}
                    alt="feces-Quality"
                    className="bg-[#D9D9D9] cursor-pointer rounded-full"
                    style={{ width: "30px", height: "30px" }}
                  />
                  <p className="text-sm font-poppins font-bold">Diarrhea</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            {formik?.values?.notes?.map((rec, index) => (
              <AddNote key={index} rec={rec} index={index} formik={formik} />
            ))}
            <AddMoreNote formik={formik} />
          </div>
          <div className="flex gap-5 sm:flex-row justify-center items-center">
            <button
              type="submit"
              className="flex bg-borderColor text-white p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md"
            >
              <p className="font-poppins font-medium text-justify text-xs">
                {createstate.isLoading || updatestate.isLoading
                  ? "Saving..."
                  : "Submit Journal"}
              </p>
            </button>

            <button
              type="button"
              onClick={closeModal}
              className="flex bg-faintBg text-black p-2 justify-center items-center gap-[10px] flex-shrink-0 rounded-md"
            >
              <p className="font-poppins font-medium text-justify text-xs">
                Cancel
              </p>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default FecesModal;
