import React, { useRef } from "react";
import PetProfileCreationImg from "assets/img/PetProfileCreationImg.png"; // Add the appropriate path for your image
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import LoginLogo from "assets/img/LoginLogo.png";

const Loader = () => {
  const [progress, setProgress] = React.useState(0);
  const [quote, setQuote] = React.useState(
    "Just a moment while we tailor this page to your interests..."
  );
  const MAX_PROGRESS = 95;
  const quotes = [
    "Just a moment while we tailor this page to your interests...",
    "I believe life is an intelligent thing: that things aren't random...",
    "There's no such thing as a typical day because I lead a very randomly...",
  ];

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, MAX_PROGRESS);
      });

      // Update the quote randomly
      const randomIndex = Math.floor(Math.random() * quotes.length);
      setQuote(quotes[randomIndex]);
    }, 2000); // Change the quote every 2 seconds

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="flex flex-col justify-center items-center gap-[20px] bg-LoginCard backdrop-blur-LoginBlur w-screen h-screen">
      <div className="justify-center flex mb-5">
        <img
          src={LoginLogo}
          className="w-[160px] h-[40px] shrink-0"
          alt="Logo"
        />
      </div>
      <div className="justify-center flex">
        <img
          src={PetProfileCreationImg}
          className="w-[325px] h-[325px]"
          alt="Loading"
        />
      </div>
      <div className="flex-col w-[30%] gap-[20px] flex justify-center items-center text-center">
        <Box style={{ color: "#DA9145" }} sx={{ width: "100%" }}>
          <LinearProgress
            style={{ backgroundColor: "#E9E9E9" }}
            variant="determinate"
            value={progress}
          />
        </Box>
        <div className="font-poppins w-full font-normal leading-normal text-base text-center">
          {quote}
        </div>
      </div>
    </div>
  );
};

export default Loader;
