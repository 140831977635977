import { createSlice } from "@reduxjs/toolkit";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("cart");
    const parsedState = serializedState ? JSON.parse(serializedState) : [];
    return Array.isArray(parsedState.cartitems)
      ? parsedState
      : { cartitems: [] };
  } catch (err) {
    return { cartitems: [] };
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("cart", serializedState);
  } catch (err) {
    console.log(err);
  }
};

const initialState = loadState();

const CartSettingSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const cartservice = action.payload;
      state.cartitems.push(cartservice);
      saveState(state);
    },
    removeFromCart: (state, action) => {
      const itemid = action.payload;
      state.cartitems = state.cartitems.filter(
        (item) =>
          !(
            item.start_time === itemid?.start_time &&
            item.service_id === itemid?.service_id &&
            item.pet_id === itemid?.pet_id &&
            item.booking_date === itemid?.booking_date
          )
      );
      saveState(state);
    },
    emptycart: (state, action) => {
      state.cartitems = [];
      saveState(state);
    },
  },
});

export const { addToCart, removeFromCart, emptycart } =
  CartSettingSlice.actions;

const cartreducer = {
  cart: CartSettingSlice.reducer,
};

export default cartreducer;
